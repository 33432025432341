import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { getLongName } from '../../../../utils'
import {
  CANCEL_BTN,
  DAYS,
  DEFAULT,
  EMAIL,
  IMMEDIATELY,
  MINUTES,
  NIGHT,
  SMS,
  SUBMIT_BTN,
} from '../../../../devOptions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultAddAutoReplyMessage from '../../../../components/MyDefaultAddAutoReplyMessage/MyDefaultAddAutoReplyMessage'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import MyDefaultBlockTitle from '../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import AutoReplyPanel from './AutoReplyPanel'

const DripSequenceEditTemplate = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setFooterButtons } = useLayout()
  const { isPageDrawerSize } = useDeviceCheck()

  const navigate = useNavigate()
  const location = useLocation()

  const sequenceData = location.state && location.state.sequenceData
  const languages = ['en', 'sp', 'fr']
  const type = location.state && location.state.type

  const [touchpointList, setTouchpointList] = useState([])
  const [selectedLangTab, setSelectedLangTab] = useState(
    organization?.defaultLanguage || languages[0]
  )
  const [selectedAiBotMessage, setSelectedAiBotMessage] = useState(null)
  const [triggeredOn] = useState('touchpoint')
  const [form] = Form.useForm()

  const getSelectedIndex = !selectedAiBotMessage
    ? 0
    : form
        .getFieldValue('auto_reply_messages')
        .findIndex((autoReply) => autoReply.id === selectedAiBotMessage.id)

  const getSequenceTempList = Form.useWatch('auto_reply_messages', form)

  const deviceCheck = useMemo(() => isPageDrawerSize, [isPageDrawerSize])

  const isSequenceActive = Form.useWatch('isActive', form)

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  useEffect(() => {
    const fill = async () => {
      let firstAutoMessage = { text: {} }
      let otherAutoMessages = []
      let result
      if (sequenceData && sequenceData._id) {
        if (sequenceData.type === NIGHT) {
          result = await organizationActions.getSequenceSourceList(
            organization?._id,
            false
          )
        } else {
          result = { success: true, data: [] }
        }
      } else {
        result = await organizationActions.getSequenceTemplateNewSourceList(type)
      }

      if (result.success) {
        const touchpointListWithLabel = result.data.map((touchpoint) => ({
          label: touchpoint.name,
          value: touchpoint.source,
          ...touchpoint,
        }))
        setTouchpointList(touchpointListWithLabel)
      }
      if (sequenceData) {
        firstAutoMessage.text = Object.assign(
          {},
          ...languages.map((lng) => ({
            [lng]: sequenceData.first_message[lng]
              ? sequenceData.first_message[lng]
              : '',
          }))
        )

        if (
          sequenceData.firstMessageDelay.value === 0 &&
          sequenceData.firstMessageDelay.unit === MINUTES
        ) {
          sequenceData.firstMessageDelay.unit = IMMEDIATELY
          sequenceData.firstMessageDelay.minutes = 0
        }

        if (sequenceData._video_upload_id) {
          firstAutoMessage._video_upload_id = sequenceData._video_upload_id
        }

        if (sequenceData._id) {
          firstAutoMessage._id = sequenceData._id
        }

        firstAutoMessage.time_unit = Object.assign(
          {},
          sequenceData.firstMessageDelay
        )

        firstAutoMessage.subject = Object.assign(
          {},
          sequenceData.firstMessageSubject
        )
        firstAutoMessage.send_via = sequenceData.firstMessageCommunicationChannel

        otherAutoMessages = sequenceData.auto_reply_messages.map((reply, index) => ({
          ...reply,
          text: Object.assign(
            {},
            ...languages.map((lng) => ({
              [lng]: reply?.text && reply?.text[lng] ? reply.text[lng] : '',
            }))
          ),
          time_unit: Object.assign(
            {},
            reply?.time_unit?.value
              ? reply.time_unit
              : {
                  value: 0,
                  unit: IMMEDIATELY,
                }
          ),
          isSecondMsg: Boolean(index === 0),
        }))
      } else {
        languages.forEach((lng) => (firstAutoMessage.text[lng] = ''))
      }

      const completeFirstAutoMessage = {
        ...firstAutoMessage,
        isFirstItem: true,
        minimumTimeBetweenMessages:
          triggeredOn === 'activity' &&
          sequenceData &&
          sequenceData.minimumTimeBetweenMessages,
        time_unit: firstAutoMessage.time_unit || {
          value: 0,
          unit: IMMEDIATELY,
          minutes: 0,
        },
      }
      form.setFieldsValue({
        auto_reply_messages: [completeFirstAutoMessage, ...otherAutoMessages],
      })
      setSelectedAiBotMessage(completeFirstAutoMessage)
    }
    if (location.pathname.includes('edit') && !sequenceData) {
      return navigate(
        `/organization/settings/${organization?._id}/dripsequence/template/list`
      )
    }
    if (organization && organization?._id) {
      fill()
    }
  }, [organization, organization?._id])

  const getConvertedObject = (existingList, item, index) => {
    for (const lang of languages) {
      if (item[lang]) {
        if (existingList[lang]) {
          existingList[lang].count++
        } else {
          existingList[lang] = {
            count: 1,
            missingIndexes: [],
          }
        }
      } else {
        if (existingList[lang]) {
          existingList[lang].missingIndexes.push(index)
        } else {
          existingList[lang] = {
            count: 0,
            missingIndexes: [index],
          }
        }
      }
    }
  }

  const isAnyEmptyField = (autoReplies) => {
    const getMessageNumberError = (index) => {
      const number = index + 1
      switch (number) {
        case 1:
          return 'first'
        case 2:
          return 'second'
        case 3:
          return 'third'
        default:
          return number + 'th'
      }
    }
    let isEmptyField = false
    let emptyListMessages = []
    let emptyListSubject = []
    let errorListMessages = []

    autoReplies.forEach((autoReply, index) => {
      getConvertedObject(emptyListMessages, autoReply.text, index)
      if (autoReply.send_via === EMAIL) {
        for (const lang in autoReply.subject) {
          if (!languages.includes(lang)) {
            delete autoReply.subject[lang]
          }
        }
        getConvertedObject(emptyListSubject, autoReply.subject, index)
      }
    })
    let keys = Object.keys(emptyListMessages)
    const missingInAllItems = Object.keys(emptyListMessages).every(
      (lang) => emptyListMessages[lang].count === 0
    )
    if (missingInAllItems) {
      notifyError(getText('INPUT_AT_LEAST_ONE_OF_THE_LANGUAGES'))
      errorListMessages.push(1)
      isEmptyField = true
    } else {
      for (const key of keys) {
        if (
          emptyListMessages[key].count !== 0 &&
          emptyListMessages[key].count !== autoReplies.length
        ) {
          for (
            let index = 0;
            index < emptyListMessages[key].missingIndexes.length;
            index++
          ) {
            const element = emptyListMessages[key].missingIndexes[index]
            errorListMessages.push(element)
            notifyError(
              `${getLongName(key)} field is required for ${getMessageNumberError(
                element
              )} auto-reply message.`
            )
            isEmptyField = true
          }
        }
        if (emptyListSubject.length) {
          if (
            emptyListSubject[key].count !== 0 &&
            emptyListSubject[key].count !== autoReplies.length
          ) {
            for (
              let index = 0;
              index < emptyListSubject[key].missingIndexes.length;
              index++
            ) {
              const element = emptyListSubject[key].missingIndexes[index]
              errorListMessages.push(element)
              notifyError(
                `${getLongName(key)} field is required for ${getMessageNumberError(
                  element
                )} auto-reply subject.`
              )
              isEmptyField = true
            }
          }
        }
      }
    }

    const allTimeUnits = autoReplies.map((autoReply) => {
      return (
        autoReply.time_unit.value + autoReply.time_unit.unit + autoReply.send_via
      )
    })
    const uniqueTimeUnits = [...new Set(allTimeUnits)]

    if (allTimeUnits.length !== uniqueTimeUnits.length) {
      notifyError(getText('ERR_SAME_TIME_AUTO_REPLY'))
      isEmptyField = true
    }

    return isEmptyField
  }

  const onFinish = async (values) => {
    let result
    if (isAnyEmptyField(values.auto_reply_messages)) return

    const [firstMessage = {}, ...otherMessages] = values.auto_reply_messages
    let minimumTimeBetweenMessages =
      values.auto_reply_messages[0].minimumTimeBetweenMessages

    if (triggeredOn === 'activity') {
      values.auto_reply_messages.filter((it) => {
        return delete it.minimumTimeBetweenMessages
      })
    }
    const { text, time_unit, send_via, subject } = firstMessage

    const getFirstMessageDelay = () => {
      if (time_unit.unit === IMMEDIATELY) {
        return { value: 0, unit: MINUTES }
      }
      return time_unit
    }

    const objSend = {
      name: values.name,
      isActive: values.isActive,
      sources: values.sources || [],
      triggeredOn,
      first_message: text,
      firstMessageDelay: getFirstMessageDelay(),
      firstMessageCommunicationChannel: send_via,
      ...(send_via === EMAIL && {
        firstMessageSubject: subject,
      }),
    }
    for (const lng in objSend.firstMessageSubject) {
      if (!languages.includes(lng)) {
        delete objSend.firstMessageSubject[lng]
      }
    }

    if (triggeredOn === 'activity') {
      objSend.minimumTimeBetweenMessages = {
        unit: 'days',
        value: minimumTimeBetweenMessages.value,
      }
    }

    const arMessages = otherMessages.map((message) => {
      delete message.isNewMessage
      delete message.id
      delete message._id
      delete message.isSecondMsg

      if (message.time_unit.unit === IMMEDIATELY) {
        message.time_unit.value = 0
        message.time_unit.unit = MINUTES
        message.time_unit.minutes = 0
      }

      return {
        ...message,
        ...(message.send_via === EMAIL && {
          subject: message.subject,
        }),
        send_via: message.send_via,
      }
    })

    objSend.auto_reply_messages = arMessages

    if (sequenceData && sequenceData._id) {
      result = await organizationActions.saveUpdateTemplateSequence(
        objSend,
        sequenceData._id
      )
    } else {
      objSend.type = type
      result = await organizationActions.saveNewTemplateSequence(objSend)
    }

    if (result && result.success) {
      notifySuccess(getText('TEXT_SEQUENCE_TEMPLATE_WAS_SAVED_SUCCESSFULLY'))
      navigate(
        `/organization/settings/${organization?._id}/dripsequence/template/list`
      )
    } else {
      notifyError(getTextServerError(result.errMsg, objSend.name))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if (errorInfo.errorFields[0].name[0] === 'name') {
        notifyError(getText('ERR_SEQUENCE_NAME_IS_REQUIRED'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  function renderSelectedAutoReplyPanel() {
    if (getSelectedIndex || getSelectedIndex === 0) {
      return !selectedAiBotMessage ? (
        <LoadingSpinner style={{ width: '100%' }} />
      ) : (
        <AutoReplyPanel
          form={form}
          field={getSelectedIndex}
          sequenceData={sequenceData}
          triggeredOn={triggeredOn}
          selectedAiBotMessage={selectedAiBotMessage}
          selectedIndex={getSelectedIndex}
          languages={languages}
          type={type}
          selectedLangTab={selectedLangTab}
          setSelectedLangTab={setSelectedLangTab}
        />
      )
    }
  }

  const handleAddAutoReplyItem = (e, add) => {
    e.preventDefault()
    const newAutoReply = {
      time_unit: {
        unit: sequenceData?.type === NIGHT || type === NIGHT ? MINUTES : DAYS,
        value: 1,
      },
      send_via:
        getSequenceTempList?.length < 2 &&
        (sequenceData?.type === NIGHT || type === NIGHT)
          ? getSequenceTempList[0]?.send_via === SMS
            ? EMAIL
            : SMS
          : SMS,
      text: Object.assign({}, ...languages.map((lng) => ({ [lng]: '' }))),
      isNewMessage: true,
      id: new Date().getTime(),
      isSecondMsg: Boolean(getSequenceTempList.length === 1),
    }
    add(newAutoReply)
    setSelectedAiBotMessage(newAutoReply)
  }

  const handleDeleteAutoReplyItem = (_, remove, index) => {
    remove(index)
    const autoMessagesList = form.getFieldValue('auto_reply_messages')
    setSelectedAiBotMessage(autoMessagesList[0])
  }

  const getAutoReplyItemTitle = (item) => {
    if (!item || !item?.time_unit) return null

    if (item.time_unit?.unit === IMMEDIATELY) return getText('WORD_IMMEDIATELY')

    const unit = getText(`WORD_${item.time_unit?.unit.toUpperCase()}`).toLowerCase()

    if (item.time_unit?.value) return item.time_unit?.value + ' ' + unit

    return unit
  }

  const getAutoReplyItemSubTitle = (item) => {
    if (!item) return null
    if (sequenceData?.type === NIGHT) return getText('WORD_AFTER_HOUR_MESSAGE')

    if (item?.isFirstItem) return getText('WORD_FIRST_AUTO_MESSAGE_WL')

    let title = ''
    const { text } = item
    if (!text) return title

    if (text?.[organization?.defaultLanguage]) {
      title = text[organization.defaultLanguage]
    }

    if (selectedLangTab) {
      title = item?.text[selectedLangTab]
    }

    if (!title) {
      if (text?.sp) title = text.sp
      if (text?.fr) title = text.fr
      if (text?.en) title = text.en
    }

    return title.replace(new RegExp('{{source}}', 'g'), '{{touchpoint}}')
  }

  const getAutoReplyItemIcon = (item) => {
    if (!item) return null

    if (item.send_via === SMS)
      return <SVGOrgSMSIcon color={'#A3A1B7'} width={18} height={18} />

    return <SVGOrgEmailIcon color={'#A3A1B7'} width={18} height={18} />
  }

  const getAutoReplyItemPercentageDetails = (item) => {
    let obj = { percentage: '0%', color: 'red' }
    const getColor = (percent) => {
      switch (true) {
        case percent < 20:
          return 'red'
        case percent < 50:
          return 'orange'
        default:
          return 'green'
      }
    }

    if (!item) return obj

    if (item.isFirstItem && sequenceData?.first_message_engagement_percentage) {
      obj = {
        percentage: `${Math.round(sequenceData.first_message_engagement_percentage)}%`,
        color: getColor(
          Math.round(sequenceData.first_message_engagement_percentage)
        ),
      }
    } else {
      if (item?.engagement_percentage)
        obj = {
          percentage: `${item.engagement_percentage.toFixed(0)}%`,
          color: getColor(item.engagement_percentage.toFixed(0)),
        }
    }

    return obj
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: sequenceData ? sequenceData.name : '',
          sources: sequenceData ? sequenceData.sources : [],
          isActive: sequenceData ? sequenceData.isActive : true,
          triggeredOn,
        }}
      >
        <Flex gap={22} vertical={deviceCheck}>
          <MyDefaultBlock
            minWidth={deviceCheck ? 'auto' : 325}
            maxWidth={deviceCheck ? 625 : 325}
          >
            <MyDefaultBlockSubtitle subtitle={getText('TEXT_TYPE_OF_SEQUENCE')} />
            <MyDefaultBlockTitle
              title={getText(
                Boolean(type === NIGHT || sequenceData?.type === NIGHT)
                  ? 'TEXT_AFTER_HOURS'
                  : 'TEXT_WORKING_HOURS'
              )}
            />
            <MyDefaultInputText
              isForm
              name='name'
              label={getText('TEXT_SEQUENCE_NAME')}
              placeholder={getText('TEXT_SEQUENCE_NAME')}
              required
              isFirst
              errorMessage={getText('ERR_VALIDATION_REQUIRED')}
            />
            <MyDefaultSwitch
              isForm
              name='isActive'
              text={getText('WORD_ACTIVE')}
              title={getText('TEXT_SEQUENCE_STATUS')}
              disabled={!organization.enable_auto_reply}
              noText
              noBorder
              isFirst
              titleStyle={{ fontSize: 14 }}
              textAfterSwitch={getText(
                isSequenceActive ? 'WORD_ACTIVE' : 'WORD_INACTIVE'
              )}
            />
            <MyDefaultSelect
              isForm
              name={'sources'}
              label={getText('WORD_TOUCHPOINTS')}
              showSearch={true}
              mode='tags'
              options={touchpointList}
              allowClear={false}
              required={false}
              message={getText('ERR_SELECT_TOUCHPOINT')}
              maxTagCount={5}
            />

            <MyDefaultAddAutoReplyMessage
              name='auto_reply_messages'
              form={form}
              isAddButtonVisible={
                sequenceData?.type === DEFAULT ||
                type === DEFAULT ||
                getSequenceTempList?.length < 2
              }
              onAdd={handleAddAutoReplyItem}
              onDelete={handleDeleteAutoReplyItem}
              onClick={(_, item) => setSelectedAiBotMessage(item)}
              itemTitle={getAutoReplyItemTitle}
              itemSubTitle={getAutoReplyItemSubTitle}
              itemIcon={getAutoReplyItemIcon}
              getSelectedIndex={getSelectedIndex}
              deleteTitle={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
              itemPercentageDetails={getAutoReplyItemPercentageDetails}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth>{renderSelectedAutoReplyPanel()}</MyDefaultBlock>
        </Flex>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default DripSequenceEditTemplate
