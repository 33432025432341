import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        fill='white'
        fillOpacity='0.05'
      />
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12914_442738)'
      />
      <path
        d='M27.68 18.2292C27.68 17.5142 27.6158 16.8267 27.4967 16.1667H18V20.0717H23.4267C23.1883 21.3275 22.4733 22.3908 21.4008 23.1058V25.645H24.6733C26.58 23.885 27.68 21.3 27.68 18.2292Z'
        fill='#4285F4'
      />
      <path
        d='M17.9997 28.0833C20.7222 28.0833 23.0047 27.185 24.673 25.645L21.4005 23.1058C20.5022 23.7108 19.3564 24.0775 17.9997 24.0775C15.378 24.0775 13.1505 22.3083 12.353 19.925H8.99805V22.5283C10.6572 25.8192 14.058 28.0833 17.9997 28.0833Z'
        fill='#34A853'
      />
      <path
        d='M12.3527 19.9158C12.151 19.3108 12.0318 18.6692 12.0318 18C12.0318 17.3308 12.151 16.6892 12.3527 16.0842V13.4808H8.99768C8.31018 14.8375 7.91602 16.3683 7.91602 18C7.91602 19.6317 8.31018 21.1625 8.99768 22.5192L11.6102 20.4842L12.3527 19.9158Z'
        fill='#FBBC05'
      />
      <path
        d='M17.9997 11.9317C19.4847 11.9317 20.8047 12.445 21.8589 13.435L24.7464 10.5475C22.9955 8.91583 20.7222 7.91666 17.9997 7.91666C14.058 7.91666 10.6572 10.1808 8.99805 13.4808L12.353 16.0842C13.1505 13.7008 15.378 11.9317 17.9997 11.9317Z'
        fill='#EA4335'
      />
      <defs>
        <linearGradient
          id='paint0_linear_12914_442738'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashReviewInvitesCompletedBySourceGoogleIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashReviewInvitesCompletedBySourceGoogleIcon
