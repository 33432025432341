import React from 'react'
import { Divider, Flex } from 'antd'
import { getText } from '../../../../lang'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultInputNumber from '../../../../components/MyDefaultInputNumber/MyDefaultInputNumber'

const SuperhumanAIAutoReply = ({ title, name, switchName, enabledSuperhuman }) => {
  return (
    <Flex
      align='center'
      justify='space-between'
      className='superhuman_ai_general_settings'
      gap={12}
    >
      <span className='title'>{title}</span>
      {title && <Divider type='vertical' />}
      {getText('TEXT_AUTO_REPLY_AFTER')}
      <MyDefaultInputNumber
        isForm
        name={name}
        min={1}
        placeholder={1}
        required={enabledSuperhuman}
        errorMessage={getText('ERR_MSG_PLEASE_PROVIDE_DELAY')}
        disabled={!enabledSuperhuman}
      />
      {getText('TEXT_MINUTES_OF_MESSAGE_BEING_UNREAD')}
      <MyDefaultSwitch isForm noBorder name={switchName} noText />
    </Flex>
  )
}

export default SuperhumanAIAutoReply
