import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        fill='white'
        fillOpacity='0.05'
      />
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12914_442748)'
      />
      <circle cx='18' cy='18' r='9.625' fill='url(#paint1_linear_12914_442748)' />
      <path
        d='M21.5844 20.9436L22.012 18.2269H19.3373V16.4648C19.3373 15.7214 19.7103 14.9964 20.9082 14.9964H22.125V12.6836C22.125 12.6836 21.0212 12.5 19.9664 12.5C17.7627 12.5 16.3236 13.8014 16.3236 16.1564V18.2269H13.875V20.9436H16.3236V27.5112C16.8152 27.5865 17.3182 27.625 17.8305 27.625C18.3428 27.625 18.8457 27.5865 19.3373 27.5112V20.9436H21.5844Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_12914_442748'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_12914_442748'
          x1='18'
          y1='8.375'
          x2='18'
          y2='27.5679'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#18ACFE' />
          <stop offset='1' stopColor='#0163E0' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashReviewInvitesCompletedBySourceFacebookIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashReviewInvitesCompletedBySourceFacebookIcon
