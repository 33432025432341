import React from 'react'
import { Flex, Tooltip } from 'antd'
import Div from '../../../../../components/Div/Div'
import './DashboardMarketingCircleChart.scss'

const DashboardMarketingCircleChart = ({
  number,
  color = 'mainColor',
  percentage = 100,
  showTooltip,
}) => {
  return (
    <Div className={`dashboard_marketing_circle_chart ${color}`}>
      <Div
        className={'dashboard_marketing_circle_chart--container'}
        style={{
          background: `conic-gradient(var(--${color}) ${percentage}%, rgba(0, 0, 0, 0.1) ${percentage}%)`,
        }}
      >
        {showTooltip ? (
          <Tooltip title={`${percentage}%`} overlayClassName='dashboard_tooltip'>
            <Div className={'dashboard_marketing_circle_chart--container--outer'}>
              <Flex
                align='center'
                justify='center'
                className={'dashboard_marketing_circle_chart--inner'}
              >
                {number || 0}
              </Flex>
            </Div>
          </Tooltip>
        ) : (
          <Div className={'dashboard_marketing_circle_chart--container--outer'}>
            <Flex
              align='center'
              justify='center'
              className={'dashboard_marketing_circle_chart--inner'}
            >
              {number || 0}
            </Flex>
          </Div>
        )}
      </Div>
    </Div>
  )
}

export default DashboardMarketingCircleChart
