import React, { memo } from 'react'
import MessageInvitation from '../MessageInvitation/MessageInvitation'
import MessageFooterActions from '../MessageFooterSomeActions/MessageFooterActions'

const MessageFooterDesktopSize = ({
  conversation,
  isLastMessageConsent,
  msgChangeEmailSMSType,
  isCustomerPhoneNumberValid,
  disabled,
  isCustomerRedStatus,
  isCustomerPhoneTemporaryBlocked,
  isCustomerYellowStatusAndRestricted,
  smsTemplatesList,
  emailTemplatesList,
  currentCursorPosition,
  setcurrentCursorPosition,
  currentCursorPositionSubject,
  setcurrentCursorPositionSubject,
  newMessageValue,
  setnewMessageValue,
  mentionItem,
  newEmailSubject,
  setnewEmailSubject,
  setIsTemplate,
  setuploadFiles,
  setFocusOverInput,
  visibleAppointment,
  setvisibleAppointment,
  unarchiveConversation,
  unAssignConversation,
  addToMessageList,
  refreshConversation,
  listItemsOfResponsiveMode,
  tabletActionsPopupVisible,
  setTabletActionsPopupVisible,
  hideTemplate,
}) => {
  return (
    <React.Fragment>
      <MessageInvitation
        conversation={conversation}
        isLastMessageConsent={isLastMessageConsent}
        msgChangeEmailSMSType={msgChangeEmailSMSType}
        isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
        disabled={disabled}
        isCustomerRedStatus={isCustomerRedStatus}
        isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
        isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
      />
      <MessageFooterActions
        newMessageValue={newMessageValue}
        msgChangeEmailSMSType={msgChangeEmailSMSType}
        setnewMessageValue={setnewMessageValue}
        conversation={conversation}
        newEmailSubject={newEmailSubject}
        setnewEmailSubject={setnewEmailSubject}
        isCustomerRedStatus={isCustomerRedStatus}
        isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
        isCustomerPhoneNumberValid={
          isCustomerPhoneNumberValid ||
          Boolean(isCustomerPhoneTemporaryBlocked) ||
          isCustomerRedStatus
        }
        isAwaitingConsent={
          isLastMessageConsent ||
          conversation?.receiver?.status?.isWaitingAnswerToConsent
        }
        visibleAppointment={visibleAppointment}
        setvisibleAppointment={setvisibleAppointment}
        smsTemplatesList={smsTemplatesList}
        emailTemplatesList={emailTemplatesList}
        currentCursorPosition={currentCursorPosition}
        setcurrentCursorPosition={setcurrentCursorPosition}
        currentCursorPositionSubject={currentCursorPositionSubject}
        setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
        mentionItem={mentionItem}
        setIsTemplate={setIsTemplate}
        setuploadFiles={setuploadFiles}
        setFocusOverInput={setFocusOverInput}
        unarchiveConversation={unarchiveConversation}
        unAssignConversation={unAssignConversation}
        addToMessageList={addToMessageList}
        isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
        isLastMessageConsent={isLastMessageConsent}
        refreshConversation={refreshConversation}
        listItemsOfResponsiveMode={listItemsOfResponsiveMode}
        tabletActionsPopupVisible={tabletActionsPopupVisible}
        setTabletActionsPopupVisible={setTabletActionsPopupVisible}
        hideTemplate={hideTemplate}
      />
    </React.Fragment>
  )
}

export default memo(MessageFooterDesktopSize)
