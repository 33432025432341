import React from 'react'
import { Collapse } from 'antd'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import MyDefaultBlock from '../MyDefaultBlock/MyDefaultBlock'
import './MyDefaultBlockCollapse.scss'

const MyDefaultBlockCollapse = ({
  items,
  onCollapseChange,
  defaultActiveKey,
  collapsible,
  enableMarginTop,
  firstItemSwitch,
}) => {
  return (
    <MyDefaultBlock enableMarginTop={enableMarginTop}>
      <Collapse
        className={`my_default_block_collapse ${firstItemSwitch ? 'first_item_switch' : ''}`}
        bordered={false}
        defaultActiveKey={defaultActiveKey}
        collapsible={collapsible}
        expandIconPosition='end'
        expandIcon={({ isActive }) => (
          <SVGSelectArrow
            style={{
              transform: isActive ? 'rotate(-180deg)' : 'rotate(0deg)',
            }}
          />
        )}
        ghost
        items={items}
        onChange={onCollapseChange}
      />
    </MyDefaultBlock>
  )
}

export default MyDefaultBlockCollapse
