import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Form, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../../lang'
import userActions from '../../../../store/modules/userActions'
import { GET_DATA_FETCH_SIZE, getInitialCountry } from '../../../../utils'
import MatButton from '../../../../components/MatButton'
import MatSwitch from '../../../../components/MatSwitch'
import MatRangePicker from '../../../../components/MatRangePicker/MatRangePicker'
import MatRadioButton from '../../../../components/MatRadioButton'
import MatRow from '../../../../components/MatRow'
import SelectChecked from '../../../../components/Form/SelectChecked'
import BroadcastCampaignPage from '../../BroadcastCampaignPage'
import MatForm from '../../../../components/Form/MatForm'
import {
  organizationSelector,
  userSelector,
} from '../../../../store/selectors/selectors'
import { notifyError } from '../../../../utils/Notify'
import MatInputNumber from '../../../../components/InputNumber'
import SVGInfoIcon from '../../../../icons/SVG/SVGInfoIcon'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import utilDate from '../../../../utils/utilsDate'
import SelectFromTagsList from '../../../../components/api/SelectFromTagsList'
import { getLanguageList, getSourceList, getUsersListIds } from './utils'
import './CreateSegment.scss'

const initialStat = {
  listSelectedLanguages: [],
  lastMessageDateGte: '',
  lastMessageDateLte: '',
  listRoleFirst: 'and',
  listRoleSecond: 'or',
  listTagsFirst: [],
  listTagsSecond: [],
  listSelectedSource: [],
  listAssignedUsers: [],
  hasAppointment: 'ANY',
  hasExcludeCustomers: false,
  days_to_skip: '',
  excludeArchived: false,
  excludeDNC: false,
}

const CreateSegment = () => {
  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)

  const RADIO_OPTION = useMemo(
    () => [
      { label: getText('WORD_YES'), value: 'YES' },
      { label: getText('WORD_NO'), value: 'NO' },
      { label: getText('WORD_ANY'), value: 'ANY' },
    ],
    []
  )

  const navigate = useNavigate()

  const [segment, setSegment] = useState(initialStat)
  const [languages, setLanguages] = useState([])
  const [touchPointList, setTouchPointList] = useState([])
  const [listUsers, setListUsers] = useState({})
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [page, setPage] = useState(0)
  const [showTagSecondSelect, setShowTagSecondSelect] = useState(
    Boolean(segment.listTagsSecond.length)
  )

  const [form] = Form.useForm()

  const isUsOrg = getInitialCountry(organization.twilio_number) === 'us'

  useEffect(() => {
    setLanguages(getLanguageList(organization.languages))
    ;(async () => {
      let data = await getSourceList(organization?._id)
      setTouchPointList(data)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (user.isAdmin || user.isManager) {
        await searchUsers()
      }
    })()
  }, [assignSearchKeyword, page])

  const searchUsers = async () => {
    const usersRes = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      organization?._id,
      assignSearchKeyword
    )
    let stateValue = {}
    if (usersRes.success) {
      const newListUsers =
        page > 0 ? [...listUsers.list, ...usersRes.data] : usersRes.data
      stateValue = {
        list: getUsersListIds(newListUsers),
        max: usersRes.max,
      }
    }
    setListUsers(stateValue)
  }

  return (
    <BroadcastCampaignPage
      title={getText('TEXT_CREATE_A_SEGMENT')}
      subTitle={getText('TEXT_CREATE_SEGMENT_DESCRIPTION')}
    >
      <div className='create-campaign-form-wrapper'>
        <MatForm
          form={form}
          name='formSaveCustomerCreateSegment'
          onFinish={() => {}}
          onFinishFailed={() => {}}
          initialValues={{
            tags_role_first: 'and',
            tags_role_second: 'or',
          }}
        >
          <MatRow flexStart>
            <SelectChecked
              name={'languages'}
              label={getText('WORD_LANGUAGE')}
              option={languages}
              placeholder={getText('WORD_SELECT_ITEM')}
              allowClear={false}
              onChange={(items) => {
                setSegment({
                  ...segment,
                  listSelectedLanguages: items,
                })
              }}
              mode='multiple'
              showSearch={false}
            />
            <MatRangePicker
              label={getText('WORD_LAST_MESSAGE_RECEIVED')}
              disabledDate={(current) => {
                return current && current > utilDate.endDateByDayJS()
              }}
              onChange={(date) => {
                let obj = {}
                date.map((item, index) => {
                  return (obj[index === 0 ? 'start_date' : 'end_date'] = item.format(
                    index === 0
                      ? 'YYYY-MM-DDT00:00:00.000[Z]'
                      : 'YYYY-MM-DDT00:00:00.000[Z]'
                  ))
                })
                setSegment({
                  ...segment,
                  lastMessageDateGte: obj.start_date,
                  lastMessageDateLte: obj.end_date,
                })
              }}
            />
          </MatRow>
          <MatBlockWithLabel
            label={
              <>
                {getText('WORD_TAGS')}
                <Tooltip title={getText('TEXT_BROADCAST_SEGMENT_TAGS_DETAILS')}>
                  <SVGInfoIcon />
                </Tooltip>
              </>
            }
          >
            <MatBlockWithLabel className='choose-tags-for-broadcast-wrapper'>
              <MatRow>
                <SelectChecked
                  allowClear={false}
                  name={'tags_role_first'}
                  label={''}
                  placeholder={getText('WORD_SELECT_ITEM')}
                  option={[
                    {
                      label: getText('WORD_AND'),
                      value: 'and',
                    },
                    {
                      label: getText('WORD_OR'),
                      value: 'or',
                    },
                  ]}
                  onChange={(items) => {
                    if (items === 'and') {
                      form.setFieldsValue({ tags_role_second: 'or' })
                    } else if (items === 'or') {
                      form.setFieldsValue({ tags_role_second: 'and' })
                    }
                    setSegment({
                      ...segment,
                      listRoleFirst: items,
                      listRoleSecond: items === 'and' ? 'or' : 'and',
                    })
                  }}
                />
                <SelectFromTagsList
                  name={'tags_list_first'}
                  label={''}
                  placeholder={getText('WORD_SELECT_ITEM')}
                  onChange={(items) => {
                    setSegment({
                      ...segment,
                      listTagsFirst: items,
                    })
                  }}
                  maxTagCount={'responsive'}
                  mode='multiple'
                />
              </MatRow>
              {showTagSecondSelect && (
                <MatRow>
                  <SelectChecked
                    popupMatchSelectWidth={72}
                    allowClear={false}
                    disabled={!segment.listRoleFirst}
                    name={'tags_role_second'}
                    placeholder={getText('WORD_SELECT_ITEM')}
                    label={''}
                    option={[
                      {
                        label: getText('WORD_AND'),
                        value: 'and',
                      },
                      {
                        label: getText('WORD_OR'),
                        value: 'or',
                      },
                    ]}
                    onChange={(items) => {
                      if (items === 'and') {
                        form.setFieldsValue({ tags_role_first: 'or' })
                      } else if (items === 'or') {
                        form.setFieldsValue({ tags_role_first: 'and' })
                      }
                      setSegment({
                        ...segment,
                        listRoleSecond: items,
                        listRoleFirst: items === 'and' ? 'or' : 'and',
                      })
                    }}
                  />
                  <SelectFromTagsList
                    disabled={!segment.listRoleFirst}
                    name={'tags_list_second'}
                    label={''}
                    placeholder={getText('WORD_SELECT_ITEM')}
                    onChange={(items) => {
                      setSegment({
                        ...segment,
                        listTagsSecond: items,
                      })
                    }}
                    maxTagCount={'responsive'}
                    mode='multiple'
                  />
                </MatRow>
              )}
              <MatButton
                style={{ width: '100%', marginTop: '10px' }}
                typeButton={'radius'}
                buttonText={
                  showTagSecondSelect
                    ? getText('WORD_HIDE_MORE_CONDITIONS')
                    : getText('WORD_ADD_MORE_CONDITIONS')
                }
                onClick={() => {
                  setSegment({
                    ...segment,
                    listTagsSecond: [],
                  })
                  form.setFieldsValue({ tags_list_second: [] })
                  setShowTagSecondSelect(!showTagSecondSelect)
                }}
              />
            </MatBlockWithLabel>
          </MatBlockWithLabel>
          <SelectChecked
            name={'touchpoints'}
            label={getText('WORD_TOUCHPOINTS')}
            placeholder={getText('WORD_SELECT_ITEM')}
            option={touchPointList}
            maxTagCount={'responsive'}
            onChange={(items) => {
              setSegment({
                ...segment,
                listSelectedSource: items,
              })
            }}
            mode='multiple'
          />
          <SelectChecked
            name={'assignedto'}
            label={getText('WORD_ASSIGNED_TO')}
            option={listUsers.list || []}
            placeholder={getText('WORD_SELECT_ITEM')}
            maxTagCount={'responsive'}
            onSearch={(search) => setAssignSearchKeyword(search)}
            onChangeItem={(val, items) => {
              setSegment({
                ...segment,
                listAssignedUsers: items.map((item) => {
                  return item._id
                }),
              })
            }}
            onLoadMore={() => {
              if (listUsers.list.length < listUsers.max) {
                setPage(page + 1)
              }
            }}
            mode='multiple'
            showSearch={true}
          />
          <MatRow className='radio-group-wrapper'>
            <label className='radio-label'>
              {' '}
              {getText('WORD_APPOINTMENT_TEXT')}{' '}
            </label>
            <MatRadioButton
              radioOptions={RADIO_OPTION}
              value={segment.hasAppointment}
              onChange={(e) => {
                setSegment({
                  ...segment,
                  hasAppointment: e.target.value,
                })
              }}
            />
          </MatRow>
          <MatBlockWithLabel className={'exclude-switch-with-input'}>
            <MatSwitch
              parentClassName={'exclude-switch-wrapper'}
              label={getText(
                'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
              )}
              value={segment.hasExcludeCustomers}
              onChange={(checked) => {
                setSegment({
                  ...segment,
                  hasExcludeCustomers: checked,
                })
              }}
            />
            <MatInputNumber
              value={segment.days_to_skip}
              disabled={!segment.hasExcludeCustomers}
              onChange={(e) => {
                if (String(e.target.value).length > 3) {
                  return false
                } else {
                  setSegment({
                    ...segment,
                    days_to_skip: e.target.value,
                  })
                }
              }}
            />
            <MatBlockWithLabel
              labelStyle={{ paddingBottom: '0', marginLeft: '10px' }}
              label={getText('WORD_DAY_S')}
            />
          </MatBlockWithLabel>
          <MatSwitch
            parentClassName={'exclude-switch-wrapper'}
            label={getText('TEXT_EXCLUDE_ARCHIVED_CUSTOMERS_FROM_BROADCAST')}
            value={segment.excludeArchived}
            onChange={(checked) => {
              setSegment({
                ...segment,
                excludeArchived: checked,
              })
            }}
          />
          {Boolean(isUsOrg) && (
            <MatSwitch
              parentClassName={'exclude-switch-wrapper'}
              label={getText('TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST')}
              value={segment.excludeDNC}
              onChange={(checked) => {
                setSegment({
                  ...segment,
                  excludeDNC: checked,
                })
              }}
              toolTipText={getText(
                'TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST_TOOLTIP'
              )}
            />
          )}
          <Flex style={{ marginTop: '32px' }} justify='center' gap={12}>
            <MyDefaultButton
              buttonText={getText('ACTION_CANCEL')}
              htmlType={''}
              onClick={() => {
                navigate('/broadcasting/create-campaign')
              }}
              typeButton={'cancel'}
              fullWidth
              maxWidth={200}
            />
            <MyDefaultButton
              buttonText={getText('ACTION_APPLY')}
              htmlType={''}
              onClick={() => {
                if (
                  segment.listSelectedLanguages.length > 0 ||
                  Boolean(segment.lastMessageDateGte) ||
                  segment.listAssignedUsers.length > 0 ||
                  segment.listSelectedSource.length > 0 ||
                  segment.listTagsFirst.length > 0 ||
                  segment.listTagsSecond.length > 0
                ) {
                  if (segment.hasExcludeCustomers) {
                    if (segment.days_to_skip) {
                      if (segment.days_to_skip < 0) {
                        notifyError(
                          getText('TEXT_PLEASE_WRITE_ONLY_POSITIVE_NUMBER')
                        )
                      } else if (segment.days_to_skip > 999) {
                        notifyError(getText('TEXT_THE_MAXIMUM_NUMBER_CAN_BE'))
                      } else {
                        navigate('/broadcasting/create-campaign/sub-pages', {
                          state: segment,
                        })
                      }
                    } else {
                      notifyError(getText('TEXT_PLEASE_WRITE_NUMBER_OF_DAYS'))
                    }
                  } else {
                    navigate('/broadcasting/create-campaign/sub-pages', {
                      state: segment,
                    })
                  }
                } else {
                  notifyError(getText('TEXT_SELECTS_AT_LEAST_ONE_FILTER'))
                }
              }}
              fullWidth
              maxWidth={200}
            />
          </Flex>
        </MatForm>
      </div>
    </BroadcastCampaignPage>
  )
}

export default CreateSegment
