import { fetchFromUrlGETAsync } from '../../utils/UrlHelper'

const URL_CHAT_SESSION = '/superhuman-chat/sessions'
const URL_CHAT_SETTINGS = '/triggers'

const fs = {
  getChatSessionById: async (id) => {
    return await fetchFromUrlGETAsync(`${URL_CHAT_SESSION}/${id}`)
  },
  getChatSettings: async (id) => {
    return await fetchFromUrlGETAsync(`${URL_CHAT_SETTINGS}/${id}?language=en`)
  },
}

const loc = {}

const aiChatActions = Object.assign(fs, loc)

export default aiChatActions
