import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import MessageAppointment from '../MessageAppointment/MessageAppointment'
import SelectMessageTemplate from '../MessageTemplateAction/SelectMessageTemplate'
import RemindMePicker from '../../centerMessageHeader/MessageReminderAction/RemindMePicker'
import MessageCallPhoneButton from '../../centerMessageHeader/MessagePhoneCallAction/MessageCallPhoneButton'
import MessageVideoCallButton from '../../centerMessageHeader/MessageVideoCallAction/MessageVideoCallButton'
import { EMAIL } from '../../../utils/constants'
import SVGCrossIcon from '../../../../../icons/SVG/SVGCrossIcon'
import MatPopover from '../../../../../components/MatPopover'
import SendPaymentRequestButton from '../MessagePaymentRequest/SendPaymentRequestButton'
import { organizationSelector } from '../../../../../store/selectors/selectors'

const FooterItemsList = ({
  showAppointmnet,
  showTemplate,
  showReminder,
  showPhoneCall,
  showVideoCall,
  showPayment,
  newMessageValue,
  msgChangeEmailSMSType,
  currentCursorPosition,
  currentCursorPositionSubject,
  setnewMessageValue,
  conversation,
  isCustomerRedStatus,
  isCustomerYellowStatusAndRestricted,
  isCustomerPhoneNumberValid,
  visibleAppointment,
  setvisibleAppointment,
  smsTemplatesList,
  emailTemplatesList,
  setcurrentCursorPosition,
  setcurrentCursorPositionSubject,
  mentionItem,
  setIsTemplate,
  setuploadFiles,
  setFocusOverInput,
  isAwaitingConsent,
  newEmailSubject,
  setnewEmailSubject,
  unarchiveConversation,
  unAssignConversation,
  addToMessageList,
  isCustomerPhoneTemporaryBlocked,
  isLastMessageConsent,
  refreshConversation,
  mobileDeskSize,
  onCloseMobileDesk,
}) => {
  const organization = useSelector(organizationSelector)
  return (
    <React.Fragment>
      {showAppointmnet && (
        <MessageAppointment
          visibleAppointment={visibleAppointment}
          conversation={conversation}
          msgChangeEmailSMSType={msgChangeEmailSMSType}
          setvisibleAppointment={setvisibleAppointment}
          isCustomerRedStatus={isCustomerRedStatus}
          isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
          isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
          isAwaitingConsent={isAwaitingConsent}
          mobileDeskSize={mobileDeskSize}
          onCloseMobileDesk={onCloseMobileDesk}
        />
      )}
      {showTemplate && (
        <SelectMessageTemplate
          id={'msg_templates'}
          smsTemplatesList={smsTemplatesList}
          emailTemplatesList={emailTemplatesList}
          msgChangeEmailSMSType={msgChangeEmailSMSType}
          setnewMessageValue={setnewMessageValue}
          newMessageValue={newMessageValue}
          currentCursorPosition={currentCursorPosition}
          setcurrentCursorPosition={setcurrentCursorPosition}
          currentCursorPositionSubject={currentCursorPositionSubject}
          setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
          mentionItem={mentionItem}
          newEmailSubject={newEmailSubject}
          setnewEmailSubject={setnewEmailSubject}
          setIsTemplate={setIsTemplate}
          setuploadFiles={setuploadFiles}
          isCustomerRedStatus={isCustomerRedStatus}
          conversation={conversation}
          isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
          isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
          setFocusOverInput={setFocusOverInput}
          mobileDeskSize={mobileDeskSize}
          onCloseMobileDesk={onCloseMobileDesk}
        />
      )}
      {organization?.enable_payments ? (
        <>
          {showPayment && (
            <SendPaymentRequestButton
              customer={conversation.receiver}
              conversation={conversation}
              disabled={
                msgChangeEmailSMSType === EMAIL ||
                isCustomerPhoneNumberValid ||
                Boolean(isCustomerPhoneTemporaryBlocked) ||
                isCustomerRedStatus ||
                isCustomerYellowStatusAndRestricted
              }
              isAwaitingConsent={
                isLastMessageConsent ||
                conversation?.receiver?.status?.isWaitingAnswerToConsent
              }
              mobileDeskSize={mobileDeskSize}
              onCloseMobileDesk={onCloseMobileDesk}
            />
          )}
        </>
      ) : null}
      {showReminder && (
        <RemindMePicker
          conversation={conversation}
          unarchiveConversation={unarchiveConversation}
          unAssignConversation={unAssignConversation}
          addToList={addToMessageList}
          mobileDeskSize={mobileDeskSize}
          onCloseMobileDesk={onCloseMobileDesk}
        />
      )}
      {showPhoneCall && (
        <MessageCallPhoneButton
          conversation={conversation}
          disabled={msgChangeEmailSMSType === EMAIL || isCustomerPhoneNumberValid}
          mobileDeskSize={mobileDeskSize}
          onCloseMobileDesk={onCloseMobileDesk}
        />
      )}
      {showVideoCall && (
        <MessageVideoCallButton
          conversation={conversation}
          disabled={
            conversation.isNew ||
            isCustomerPhoneNumberValid ||
            msgChangeEmailSMSType === EMAIL ||
            isCustomerRedStatus ||
            isCustomerYellowStatusAndRestricted ||
            conversation.is_archived ||
            isCustomerPhoneTemporaryBlocked ||
            isLastMessageConsent ||
            conversation?.receiver?.status?.isWaitingAnswerToConsent
          }
          isAwaitingConsent={
            isLastMessageConsent ||
            conversation?.receiver?.status?.isWaitingAnswerToConsent
          }
          refreshConversation={refreshConversation}
          mobileDeskSize={mobileDeskSize}
          onCloseMobileDesk={onCloseMobileDesk}
        />
      )}
    </React.Fragment>
  )
}

const MessageFooterActions = ({
  newMessageValue,
  msgChangeEmailSMSType,
  currentCursorPosition,
  currentCursorPositionSubject,
  setnewMessageValue,
  conversation,
  isCustomerRedStatus,
  isCustomerYellowStatusAndRestricted,
  isCustomerPhoneNumberValid,
  visibleAppointment,
  setvisibleAppointment,
  smsTemplatesList,
  emailTemplatesList,
  setcurrentCursorPosition,
  setcurrentCursorPositionSubject,
  mentionItem,
  setIsTemplate,
  setuploadFiles,
  setFocusOverInput,
  screenSize,
  isAwaitingConsent,
  newEmailSubject,
  setnewEmailSubject,
  unarchiveConversation,
  unAssignConversation,
  addToMessageList,
  isCustomerPhoneTemporaryBlocked,
  isLastMessageConsent,
  refreshConversation,
  mobileDeskSize,
  onCloseMobileDesk,
  listItemsOfResponsiveMode,
  tabletActionsPopupVisible,
  setTabletActionsPopupVisible,
  hideTemplate,
}) => {
  const footerListItemsProps = useMemo(() => {
    return {
      newMessageValue: newMessageValue,
      msgChangeEmailSMSType: msgChangeEmailSMSType,
      currentCursorPosition: currentCursorPosition,
      currentCursorPositionSubject: currentCursorPositionSubject,
      setnewMessageValue: setnewMessageValue,
      conversation: conversation,
      isCustomerRedStatus: isCustomerRedStatus,
      isCustomerYellowStatusAndRestricted: isCustomerYellowStatusAndRestricted,
      isCustomerPhoneNumberValid: isCustomerPhoneNumberValid,
      visibleAppointment: visibleAppointment,
      setvisibleAppointment: setvisibleAppointment,
      smsTemplatesList: smsTemplatesList,
      emailTemplatesList: emailTemplatesList,
      setcurrentCursorPosition: setcurrentCursorPosition,
      setcurrentCursorPositionSubject: setcurrentCursorPositionSubject,
      mentionItem: mentionItem,
      setIsTemplate: setIsTemplate,
      setuploadFiles: setuploadFiles,
      setFocusOverInput: setFocusOverInput,
      screenSize: screenSize,
      isAwaitingConsent: isAwaitingConsent,
      newEmailSubject: newEmailSubject,
      setnewEmailSubject: setnewEmailSubject,
      unarchiveConversation: unarchiveConversation,
      unAssignConversation: unAssignConversation,
      addToMessageList: addToMessageList,
      isCustomerPhoneTemporaryBlocked: isCustomerPhoneTemporaryBlocked,
      isLastMessageConsent: isLastMessageConsent,
      refreshConversation: refreshConversation,
      mobileDeskSize: mobileDeskSize,
      onCloseMobileDesk: onCloseMobileDesk,
    }
  }, [
    newMessageValue,
    msgChangeEmailSMSType,
    currentCursorPosition,
    currentCursorPositionSubject,
    setnewMessageValue,
    conversation,
    isCustomerRedStatus,
    isCustomerYellowStatusAndRestricted,
    isCustomerPhoneNumberValid,
    visibleAppointment,
    setvisibleAppointment,
    smsTemplatesList,
    emailTemplatesList,
    setcurrentCursorPosition,
    setcurrentCursorPositionSubject,
    mentionItem,
    setIsTemplate,
    setuploadFiles,
    setFocusOverInput,
    screenSize,
    isAwaitingConsent,
    newEmailSubject,
    setnewEmailSubject,
    unarchiveConversation,
    unAssignConversation,
    addToMessageList,
    isCustomerPhoneTemporaryBlocked,
    isLastMessageConsent,
    refreshConversation,
    mobileDeskSize,
    onCloseMobileDesk,
  ])
  return (
    <React.Fragment>
      <FooterItemsList
        showAppointmnet={!listItemsOfResponsiveMode.includes('appointment')}
        showTemplate={
          !listItemsOfResponsiveMode.includes('template') && !hideTemplate
        }
        showPayment={!listItemsOfResponsiveMode.includes('payment')}
        showReminder={!listItemsOfResponsiveMode.includes('reminder')}
        showPhoneCall={!listItemsOfResponsiveMode.includes('phone_call')}
        showVideoCall={!listItemsOfResponsiveMode.includes('video_call')}
        {...footerListItemsProps}
      />
      {listItemsOfResponsiveMode.length > 0 && (
        <MatPopover
          fresh={true}
          forceRender={true}
          visible={tabletActionsPopupVisible}
          triggerPopover={
            <div className='message_footer_small_ui_button'>
              <SVGCrossIcon color={'#3F8CFF'} width={10} height={10} />
            </div>
          }
          overlayClassName={'message-footer-small-ui'}
          placement={'topLeft'}
          onVisibleChange={(val) => {
            setTabletActionsPopupVisible(val)
          }}
          content={
            <FooterItemsList
              showAppointmnet={listItemsOfResponsiveMode.includes('appointment')}
              showTemplate={
                listItemsOfResponsiveMode.includes('template') && !hideTemplate
              }
              showPayment={listItemsOfResponsiveMode.includes('payment')}
              showReminder={listItemsOfResponsiveMode.includes('reminder')}
              showPhoneCall={listItemsOfResponsiveMode.includes('phone_call')}
              showVideoCall={listItemsOfResponsiveMode.includes('video_call')}
              {...footerListItemsProps}
            />
          }
        />
      )}
    </React.Fragment>
  )
}

export default memo(MessageFooterActions)
