import React, { useEffect, useState } from 'react'
import { Tabs, Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import { getLongName } from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import PageFull from '../../../../../components/PageFull'
import PageFix from '../../../../../components/PageFix'
import MatForm from '../../../../../components/Form/MatForm'
import storyActions from '../../../../../store/modules/storyActions'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import Dropzone from '../../fileUpload/DropzoneFile'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'

const AddEditStory = ({ isNew }) => {
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)
  const { _id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const item = location && location.state ? location.state : ''
  const storiesEditItem = !isNew || !item ? item : {}

  useEffect(() => {
    if (!item && location.pathname.includes('edit')) {
      navigate(`/location/settings/${_id}/stories/list`)
    }
  }, [location.pathname])

  const [videoUpload, setVideoUpload] = useState({
    fileUploadPage: true,
    file: storiesEditItem.videoUrl || '',
  })

  const [imageUpload, setImageUpload] = useState({
    fileUploadPage: true,
    file: storiesEditItem.imageUrl || '',
  })

  const [thumbnailUpload, setThumbnailUpload] = useState({
    fileUploadPage: false,
    file: storiesEditItem.thumbnailUrl || '',
  })

  const [form] = Form.useForm()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  const handleChange = (file, name) => {
    if (file.status !== 'done') return
    if (name === 'thumbnail') {
      setThumbnailUpload({
        fileUploadPage: false,
        file: file.mediaUrl,
      })
    } else if (name === 'video') {
      setVideoUpload({
        fileUploadPage: false,
        file: file.mediaUrl,
      })
    } else {
      setImageUpload({
        fileUploadPage: false,
        file: file.mediaUrl,
      })
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    let result

    if (!thumbnailUpload.file) {
      notifyError(getText('ERROR_THUMBNAIL_URL_IS_REQUIRED'))
      setLoading(false)
      return false
    }

    if (!imageUpload.file && !videoUpload.file) {
      notifyError(getText('ERROR_YOU_NEED_TO_PUT_AT_LEAST_IMAGE_OR_VIDEO_URL'))
      setLoading(false)
      return false
    }

    if (imageUpload.file && videoUpload.file) {
      notifyError(
        getText('ERROR_YOU_CANT_UPLOAD_BOTH_VIDEO_AND_IMAGE_REMOVE_ONE_THEM')
      )
      setLoading(false)
      return false
    }

    const obj = {
      locationId: _id,
      title: values.title,
      status: 'ACTIVE',
      thumbnailUrl: thumbnailUpload.file,
    }

    if (imageUpload.file) {
      obj.imageUrl = imageUpload.file
    }

    if (videoUpload.file) {
      obj.videoUrl = videoUpload.file
    }

    if (isNew) {
      result = await storyActions.insert(obj)
    } else {
      result = await storyActions.update(obj, item.id)
    }

    if (result.success) {
      notifySuccess(getText('MSG_STORY_ITEM_SAVED_SUCCESSFULLY'))
      navigate(`/location/settings/${_id}/stories/list`)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <PageFull className={'add-edit-container'}>
      <PageFix className={'add-edit-view'}>
        <div className='add-edit-header'>
          <p>{getText(isNew ? 'WORD_ADD_NEW_STORY' : 'WORD_EDIT_STORY')}</p>
        </div>
        <div className='stories-dprozone-container'>
          <MatForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              title: organization?.languages?.map((lng, index) => {
                return {
                  text: !item ? '' : storiesEditItem.title[index].text,
                  language: lng,
                }
              }),
            }}
          >
            <Tabs
              className='mat-tabs'
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <>
                      <InputFormTextSecondary
                        hidden
                        name={['title', index, 'language']}
                      />
                      <InputFormTextSecondary
                        placeholder={getText('TEXT_ENTER_STORY_TITLE')}
                        label={getText('WORD_TITLE')}
                        name={['title', index, 'text']}
                        required
                        errorMessage={getText(
                          'ERROR_PLEASE_INPUT_STORY_LANG'
                        ).replace('[language]', getLongName(lng))}
                      />
                      <div className='edit-area__inputs'>
                        <Dropzone
                          mediaState={thumbnailUpload}
                          mediaSetState={setThumbnailUpload}
                          title={getText('WORD_THUMBNAIL')}
                          note={getText('STORY_THUMBNAIL_NOTE')}
                          mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                          onChange={(file) => {
                            handleChange(file, 'thumbnail')
                          }}
                        />
                        <Dropzone
                          mediaState={imageUpload}
                          mediaSetState={setImageUpload}
                          title={getText('WORD_IMAGE')}
                          note={getText('STORY_IMAGE_NOTE')}
                          mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                          onChange={(file) => {
                            handleChange(file, 'image')
                          }}
                        />
                        <Dropzone
                          mediaState={videoUpload}
                          mediaSetState={setVideoUpload}
                          title={getText('WORD_VIDEO')}
                          mediaTypes={
                            'video/mp4, video/mov, video/avi, video/webm, video/quicktime'
                          }
                          onChange={(file) => {
                            handleChange(file, 'video')
                          }}
                          note={getText('STORY_VIDEO_NOTE')}
                        />
                      </div>
                    </>
                  ),
                }
              })}
            />
          </MatForm>
        </div>
      </PageFix>
    </PageFull>
  )
}

export default AddEditStory
