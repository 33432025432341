import React, {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import authActions from '../../../store/modules/authActions'
import statActions from '../../../store/modules/statActions'
import {
  locationSelector,
  organizationSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import { MONTH_ORDER } from '../../../utils'
import Div from '../../../components/Div/Div'
import MatTable from '../../../components/MatTable'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import { CUSTOM, ORG, TABLE_DEFAULT_ROW_HEIGHT } from '../../../devOptions'
import { notifyError } from '../../../utils/Notify'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ListLoadMore from '../../../components/ListLoadMore'
import { DashboardContext } from '../Dashboard'
import DashboardMarketingStatistic from './DashboardMarketingStatistic/DashboardMarketingStatistic'
import DashboardMarketingCircleChart from './DashboardMarketingTableItems/DashboardMarketingCircleChart/DashboardMarketingCircleChart'
import DashboardMarketingTouchpoint from './DashboardMarketingTableItems/DashboardMarketingTouchpoint/DashboardMarketingTouchpoint'
import './DashboardMarketing.scss'

const DashboardMarketing = forwardRef((_, ref) => {
  const {
    selectedRange,
    selectedDataType,
    selectCustomRange,
    dashboardScrollBarRef,
  } = useContext(DashboardContext)
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const userLang = authActions.getLanguage()

  const isFirstRender = useRef(true)

  const location = useSelector(locationSelector)
  const organization = useSelector(organizationSelector)

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [marketingSources, setMarketingSources] = useState({
    list: [],
    total: 0,
  })
  const [marketingChart, setMarketingChart] = useState({})
  const [sorting, setSorting] = useState({
    order: '',
    sort: '',
  })

  useImperativeHandle(ref, () => ({
    resetPage: () => {
      setPage(0)
    },
  }))

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location?._id
    const result = await statActions.getMarketingStatsDashboard(
      selectedRange,
      page,
      TABLE_DEFAULT_ROW_HEIGHT,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date,
      sorting
    )
    if (result.success) {
      const newData =
        page === 0
          ? result.data.touchpoints.data
          : [...marketingSources.list, ...result.data.touchpoints.data]
      setMarketingSources({
        list: newData.map((item, index) => {
          Object.assign(item, {
            key: index,
          })
          return item
        }),
        total: result.data.touchpoints.total_items,
      })
      setMarketingChart(result.data.marketingChart)
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [
    location?._id,
    selectedRange,
    selectedDataType,
    selectCustomRange,
    page,
    sorting,
  ])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (page === 0) {
      fill(location?._id)
    } else {
      setPage(0)
    }
  }, [location?._id, organization?._id])

  useEffect(() => {
    fill()
  }, [fill])

  const getSortedData = (sort) => {
    const fieldMap = {
      touchpoint: 'source',
      superhuman_handoffs: 'handoff_count',
      unique_leads: 'unique_leads_count',
      influenced_walkins: 'influenced_walkins_count',
      engaged_leads: 'unique_engaged_leads_count',
      appointments: 'appointments_count',
      superhuman_appointments: 'superhuman_appointments_count',
    }

    if (sort.order && sort.field && fieldMap[sort.field]) {
      setSorting({
        order: sort.order === 'ascend' ? 'asc' : 'desc',
        sort: fieldMap[sort.field],
      })
    } else {
      setSorting(null)
    }

    if (page > 0) {
      setPage(0)
    }
  }

  const convertMarketingChartData = (obj) => {
    if (!Boolean(obj)) return []
    let currentMonthIndex = new Date().getMonth()
    const result = [
      ...MONTH_ORDER.slice(currentMonthIndex - 5),
      ...MONTH_ORDER.slice(0, currentMonthIndex + 1),
    ].map((month) => ({
      name: isTabletPortraitOrMobile
        ? getText(capitalize(month.substring(0, 3)))
        : getText(capitalize(month)),
      leads: obj[month]?.unique_leads,
      influenced_visits: obj[month]?.appointments,
    }))
    return result
  }

  const convertedMarketingChart = convertMarketingChartData(marketingChart)

  return isFirstRender.current ? (
    <LoadingSpinner />
  ) : (
    <Div className={'dashboard_marketing_page'}>
      <DashboardMarketingStatistic
        responsiveChartHeight={450}
        data={convertedMarketingChart}
      />
      <ListLoadMore
        loading={loading}
        totalDataCount={marketingSources.total}
        onLoadMore={() => {
          if (!loading && marketingSources.list.length < marketingSources.total) {
            setLoading(true)
            setPage((page) => page + 1)
          }
        }}
        pageStart={page}
        getScrollParent={() => dashboardScrollBarRef?.current || window}
        data={marketingSources?.list || []}
        threshold={20}
        customList={
          <MatTable
            className={'dashboard_marketing_page--table'}
            loading={loading}
            columns={[
              {
                title: getText('WORD_TOUCHPOINT'),
                dataIndex: 'touchpoint',
                sorter: true,
                minWidth: 200,
                align: 'center',
                render: (_, { source }) => (
                  <DashboardMarketingTouchpoint source={source} />
                ),
              },
              {
                title: getText('WORD_LEADS'),
                dataIndex: 'unique_leads',
                sorter: true,
                minWidth: 100,
                align: 'center',
                render: (_, { unique_leads, unique_leads_percentage }) => (
                  <DashboardMarketingCircleChart
                    color={'red'}
                    number={unique_leads}
                    percentage={unique_leads_percentage}
                    showTooltip
                  />
                ),
              },
              {
                title: getText('WORD_ENGAGED_LEADS'),
                dataIndex: 'engaged_leads',
                sorter: true,
                minWidth: 140,
                align: 'center',
                render: (_, { engaged_leads, engaged_leads_percentage }) => (
                  <DashboardMarketingCircleChart
                    color={'blue'}
                    number={engaged_leads}
                    percentage={engaged_leads_percentage}
                    showTooltip
                  />
                ),
              },
              {
                title: getText('WORD_INFLUENCED_VISITS'),
                dataIndex: 'influenced_walkins',
                sorter: true,
                minWidth: 140,
                align: 'center',
                render: (_, { influenced_walkins }) => (
                  <DashboardMarketingCircleChart
                    color={'green'}
                    number={influenced_walkins}
                  />
                ),
              },
              {
                title: getText('WORD_USER_APPOINTMENTS'),
                dataIndex: 'appointments',
                sorter: true,
                minWidth: 170,
                align: 'center',
                render: (_, { appointments }) => (
                  <DashboardMarketingCircleChart
                    color={'circleGrey'}
                    number={appointments}
                  />
                ),
              },
              {
                title: (
                  <React.Fragment>
                    <span className='gradient_text'>{getText('WORD_AI')}</span>{' '}
                    {getText('WORD_AI_APPOINTMENTS')}
                  </React.Fragment>
                ),
                dataIndex: 'superhuman_appointments',
                sorter: true,
                minWidth: 140,
                align: 'center',
                render: (_, { superhuman_appointments }) => (
                  <DashboardMarketingCircleChart
                    color={'circleGrey'}
                    number={superhuman_appointments}
                  />
                ),
              },
              {
                title: (
                  <React.Fragment>
                    {userLang === 'en' && (
                      <span className='gradient_text'>{getText('WORD_AI')}</span>
                    )}{' '}
                    {getText('WORD_AI_HANDOFFS')}
                    {userLang === 'fr' && (
                      <span className='gradient_text'>{getText('WORD_AI')}</span>
                    )}
                  </React.Fragment>
                ),
                dataIndex: 'superhuman_handoffs',
                sorter: true,
                minWidth: 120,
                align: 'center',
                render: (_, { superhuman_handoffs }) => (
                  <DashboardMarketingCircleChart
                    color={'circleGrey'}
                    number={superhuman_handoffs}
                  />
                ),
              },
            ]}
            dataSource={marketingSources?.list || []}
            withoutPagination
            onChange={(_, __, sort) => {
              getSortedData(sort)
            }}
            sticky={{
              offsetHeader: isTabletPortraitOrMobile ? -10 : -34,
            }}
            tableLayout={'auto'}
            scroll={{
              x: 800,
            }}
          />
        }
      />
    </Div>
  )
})

export default memo(DashboardMarketing)
