import React, { useState } from 'react'
import { Flex } from 'antd'
import Div from '../../../../components/Div/Div'
import { getText, getTextServerError } from '../../../../lang'
import SVGInfoIconOrange from '../../../../icons/SVG/SVGInfoIconOrange'
import './MismatchBlock.scss'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import customerActions from '../../../../store/modules/customerActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'

const MismatchBlock = ({
  mismatchArray,
  onClear,
  conversation,
  query,
  onUpdateSuccess,
}) => {
  const [loading, setloading] = useState(false)

  const handleUpdateCustomer = async () => {
    setloading(true)

    const obj = {}

    for (const field of mismatchArray) {
      obj[field.type] = field.newValue
    }

    if (obj['fullName']) {
      obj['firstName'] = query?.first_name || ''
      obj['lastName'] = query?.last_name || ''
      delete obj['fullName']
    }

    obj['language'] = conversation.receiver.language || ''

    if (Object.keys(obj).length > 0) {
      const result = await customerActions.saveUpdate(obj, conversation._receiver_id)
      if (result.success) {
        notifySuccess(getText('NTF_SUCCESS_SAVE_CUSTOMER'))
        onUpdateSuccess && onUpdateSuccess(result.data)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
      setloading(false)
    }
  }

  if (mismatchArray.length === 0) {
    return null
  }

  return (
    <Div className={'mismatch_block_wrapper'}>
      <Div className={'mismatch_block'}>
        <Flex vertical gap={24}>
          {mismatchArray.map((item, index) => {
            return (
              <Flex key={index} vertical gap={16}>
                <Div className={'mismatch_block--title'}>{item.name}</Div>
                <Flex vertical gap={2}>
                  <span className='mismatch_block--old_value'>
                    {getText('WORD_OLD')} : {item.oldValue}
                  </span>
                  <span className='mismatch_block--new_value'>
                    {getText('WORD_NEW')} : {item.newValue}
                  </span>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
        <Div className={'mismatch_block--mismatch_warning'}>
          <SVGInfoIconOrange />
          {getText('TEXT_THE_DATA_DOESNT_MATCH')}
        </Div>
        <Div className={'mismatch_block--buttons_wrapper'}>
          <MyDefaultButton
            typeButton='white'
            buttonText={getText('TEXT_LEAVE_AS_IS')}
            fullWidth
            onClick={onClear}
          />
          <MyDefaultButton
            typeButton='primary'
            buttonText={getText('TEXT_UPDATE_INFO')}
            loading={loading}
            fullWidth
            onClick={() => {
              handleUpdateCustomer()
            }}
          />
        </Div>
      </Div>
    </Div>
  )
}

export default MismatchBlock
