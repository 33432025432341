import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { Flex } from 'antd'
import { getText } from '../../../../lang'
import Div from '../../../../components/Div/Div'

const ReputationPulseCSIScoreChart = ({ csi_score }) => {
  const data = [
    { name: getText('WORD_PROMOTERS'), value: csi_score?.promoter_count },
    { name: getText('WORD_PASSIVE'), value: csi_score?.passive_count },
    { name: getText('WORD_DETRACTORS'), value: csi_score?.detractor_count },
  ]
  const COLORS = ['var(--green)', 'var(--yellow)', 'var(--red)']

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <Div className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              {payload.map((item, index) => {
                return (
                  <Flex gap={4} align='center' key={index}>
                    <p className='desc label'>{item.name}:</p>
                    <p className='intro'>{item.value}</p>
                  </Flex>
                )
              })}
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  return (
    <ResponsiveContainer className={'reputation_pulse_pie_chart'} height={152}>
      <PieChart>
        <Tooltip content={<CustomTooltip />} />
        <Pie
          data={data}
          innerRadius={37}
          outerRadius={74}
          activeIndex={'eweewe'}
          fill='#8884d8'
          dataKey='value'
        >
          {data.map((_, index) => (
            <Cell
              stroke={'none'}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <text
          y={78}
          x={76}
          textAnchor='middle'
          dominantBaseline={'middle'}
          fill='var(--white)'
          fontSize={24}
          fontWeight={500}
        >
          {csi_score?.center_percentage}
        </text>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default ReputationPulseCSIScoreChart
