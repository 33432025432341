import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_24869_133197)'>
      <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
      <g opacity='0.2' filter='url(#filter0_f_24869_133197)'>
        <circle cx='18' cy='17' r='13' fill='#EE30B9' />
      </g>
      <g clipPath='url(#clip1_24869_133197)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.833 11.3333C11.833 11.0571 11.6092 10.8333 11.333 10.8333C11.0569 10.8333 10.833 11.0571 10.833 11.3333V21.9999C10.833 23.7488 12.2508 25.1666 13.9997 25.1666H24.6663C24.9425 25.1666 25.1663 24.9427 25.1663 24.6666C25.1663 24.3904 24.9425 24.1666 24.6663 24.1666H13.9997C12.8031 24.1666 11.833 23.1965 11.833 21.9999V11.3333ZM20.333 15.4999C20.0569 15.4999 19.833 15.7238 19.833 15.9999C19.833 16.2761 20.0569 16.4999 20.333 16.4999H20.9592L19.4245 18.841C19.3572 18.9436 19.206 18.9409 19.1425 18.836L17.8704 16.7344C17.404 15.9639 16.2776 15.99 15.8474 16.7812L14.2271 19.7611C14.0952 20.0037 14.1849 20.3073 14.4275 20.4392C14.6701 20.5711 14.9737 20.4814 15.1056 20.2388L16.7259 17.2589C16.7874 17.1459 16.9483 17.1421 17.0149 17.2522L18.287 19.3538C18.7315 20.0881 19.7902 20.1072 20.2608 19.3893L21.8916 16.9017L22.2191 17.5569C22.3426 17.8038 22.643 17.904 22.89 17.7805C23.137 17.657 23.2371 17.3566 23.1136 17.1096L22.6312 16.1448C22.4335 15.7496 22.0296 15.4999 21.5877 15.4999H20.333Z'
          fill='white'
        />
      </g>
    </g>
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='7.5'
      stroke='url(#paint0_linear_24869_133197)'
    />
    <defs>
      <filter
        id='filter0_f_24869_133197'
        x='-11'
        y='-12'
        width='58'
        height='58'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='BackgroundImageFix'
          result='shape'
        />
        <feGaussianBlur
          stdDeviation='8'
          result='effect1_foregroundBlur_24869_133197'
        />
      </filter>
      <linearGradient
        id='paint0_linear_24869_133197'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
      <clipPath id='clip0_24869_133197'>
        <rect width='36' height='36' rx='8' fill='white' />
      </clipPath>
      <clipPath id='clip1_24869_133197'>
        <rect width='16' height='16' fill='white' transform='translate(10 10)' />
      </clipPath>
    </defs>
  </svg>
)

const SVGDashCSIScore = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashCSIScore
