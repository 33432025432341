import React from 'react'
import { Flex } from 'antd'
import MyDefaultButton from '../../../../../../components/MyDefaultButton/MyDefaultButton'

const FormButtons = (props) => {
  const {
    prevAction,
    currentFormName,
    cancelFormBtn,
    submitFormBtn,
    disabled,
    onClickSubmit,
    className = '',
  } = props

  return (
    <Flex
      align='center'
      justify='center'
      className={`${className}`}
      style={{ marginTop: '32px' }}
      gap={12}
    >
      <MyDefaultButton
        buttonText={cancelFormBtn}
        htmlType={''}
        onClick={() => prevAction(-1)}
        typeButton={'cancel'}
        fullWidth
        maxWidth={200}
      />
      <MyDefaultButton
        onClick={onClickSubmit}
        formName={currentFormName || ''}
        buttonText={submitFormBtn}
        htmlType={'submit'}
        disabled={disabled}
        fullWidth
        maxWidth={200}
      />
    </Flex>
  )
}

export default FormButtons
