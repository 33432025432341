import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Collapse, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import statActions from '../../../store/modules/statActions'
import {
  locationSelector,
  menuOpenSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import {
  convertDashboardStatisticDataMonth,
  getStartDateForDashboard,
  MONTH_ORDER,
} from '../../../utils'
import SVGDashMessageTotalSentIcon from '../../../icons/SVG/SVGDashMessageTotalSentIcon'
import SVGDashSalesHandoffsIcon from '../../../icons/SVG/SVGDashSalesHandoffsIcon'
import SVGDashInfluencedWalkInsTreeIcon from '../../../icons/SVG/SVGDashInfluencedWalkInsTreeIcon'
import SVGDashTotalTimeSavedIcon from '../../../icons/SVG/SVGDashTotalTimeSavedIcon'
import SVGArrowDown from '../../../icons/SVG/SVGArrowDown'
import SVGDashCreatedAppointmentsIcon from '../../../icons/SVG/SVGDashCreatedAppointmentsIcon'
import SVGDashInfluencedWalkInsIcon from '../../../icons/SVG/SVGDashInfluencedWalkInsIcon'
import SVGDashUniqueLeadsIcon from '../../../icons/SVG/SVGDashUniqueLeadsIcon'
import DashboardInfoBar from '../DashboardComponents/DashboardInfoBar/DashboardInfoBar'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import Div from '../../../components/MatDiv/Div'
import { DashboardContext } from '../Dashboard'
import { notifyError } from '../../../utils/Notify'
import DashboardStatistics from '../DashboardComponents/DashboardStatistics/DashboardStatistics'
import { CUSTOM, ORG } from '../../../devOptions'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DashboardMetricBlock from '../DashboardComponents/DashboardMetricBlock/DashboardMetricBlock'
import utilNumber from '../../../utils/utilNumber'
import DashboardBlockPurple from '../DashboardComponents/DashboardBlockPurple/DashboardBlockPurple'
import DashboardHoursBlock from '../DashboardComponents/DashboardHoursBlock/DashboardHoursBlock'
import DashboardInfluencedSales from './items/DashboardInfluencedSales/DashboardInfluencedSales'
import DashboardLeadsStatistics from './items/DashboardLeadsStatistics/DashboardLeadsStatistics'
import DashboardSequence from './items/DashboardSequence/DashboardSequence'
import DashboardWalkBySuperhuman from './items/DashboardWalkBySuperhuman/DashboardWalkBySuperhuman'
import './OverviewAndSuperhuman.scss'

const OverviewAndSuperhuman = () => {
  const { isMobile, isTabletPortraitOrMobile } = useDeviceCheck()
  const { selectedRange, selectedDataType, selectCustomRange } =
    useContext(DashboardContext)

  const menuOpen = useSelector(menuOpenSelector)
  const location = useSelector(locationSelector)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [overviewData, setOverviewData] = useState({})
  const [overviewLeadsData, setOverviewLeadsData] = useState({})
  const [senderType, setSenderType] = useState('total_message_sent')

  const fillOverviewData = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }

    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location?._id

    if (location?._id) {
      const result = await statActions.getOverviewStatsDashboard(
        selectedRange,
        locationId,
        selectCustomRange.start_date,
        selectCustomRange.end_date
      )
      if (result.success) {
        setOverviewData(result.data)
      } else {
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
    }
    setLoading(false)
  }, [location?._id, selectedRange, selectedDataType, selectCustomRange])

  const fillOverviewLeadsData = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    let locationId = selectedDataType === ORG ? '' : location?._id

    if (location?._id) {
      const result = await statActions.getOverviewLeadsStatsDashboard(
        selectedRange,
        locationId,
        selectCustomRange.start_date,
        selectCustomRange.end_date,
        senderType
      )

      if (result.success) {
        setOverviewLeadsData(result.data)
      } else {
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
    }
  }, [location?._id, selectedRange, selectedDataType, selectCustomRange, senderType])

  useEffect(() => {
    fillOverviewData()
  }, [fillOverviewData])

  useEffect(() => {
    fillOverviewLeadsData()
  }, [fillOverviewLeadsData])

  const convertMessagesChartData = (data) => {
    if (!Boolean(data)) return []
    let currentMonthIndex = new Date().getMonth()
    const months = Object.keys(data.human)
    const result = [
      ...MONTH_ORDER.slice(currentMonthIndex - 5),
      ...MONTH_ORDER.slice(0, currentMonthIndex + 1),
    ]
      .filter((month) => months.includes(month))
      .map((month) => ({
        human: data.human[month],
        automation: data.automations[month],
        superhuman: data.superhuman[month],
        broadcast: data.broadcast[month],
        name: isTabletPortraitOrMobile
          ? getText(capitalize(month.substring(0, 3)))
          : getText(capitalize(month)),
      }))
    return result
  }

  const messagesChartData = convertMessagesChartData(overviewData?.messages_chart)
  const superhumanChartData = convertDashboardStatisticDataMonth(
    overviewData?.superhuman_stats?.superhuman_chart,
    isTabletPortraitOrMobile
  )

  const handleToMessagePage = (key, secondKey) => {
    const dashboardClickableStats = {
      [key]: true,
      dateFilter: [
        getStartDateForDashboard(selectedRange, selectCustomRange).start_date,
        getStartDateForDashboard(selectedRange, selectCustomRange).end_date,
      ],
      range: selectedRange === CUSTOM ? CUSTOM : selectedRange,
      locations: selectedDataType === ORG ? false : location?._id,
    }

    if (secondKey !== undefined) {
      if (typeof secondKey === 'object') {
        Object.entries(secondKey).forEach(([key, value]) => {
          dashboardClickableStats[key] = value
        })
      } else {
        dashboardClickableStats[secondKey] = secondKey
      }
    }

    navigate('/messages?type=all', {
      state: {
        dashboardClickableStats,
      },
    })
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Div className={`overview_and_superhuman ${menuOpen ? 'open' : ''}`}>
      <Div className={'overview_and_superhuman--overview'}>
        <DashboardLeadsStatistics
          chartData={messagesChartData}
          number={overviewLeadsData?.messages_stats?.count}
          leadsList={overviewLeadsData?.messages_stats?.perChannel}
          responsiveChartHeight={222}
          onSelectLeads={(value) => {
            setSenderType(value)
          }}
        />
        <Div className={'overview_and_superhuman--overview--half'}>
          <DashboardMetricBlock
            icon={<SVGDashUniqueLeadsIcon />}
            title={getText('WORD_UNIQUE_LEADS')}
            titleTooltip={getText('TOOLTIP_UNIQUE_LEADS')}
            secondaryLabel={getText('WORD_LEADS_ENGAGED')}
            secondaryText={getText('WORD_LEADS_ENGAGED_PERCENTAGE')}
            secondaryTooltipTitle={getText('TOOLTIP_LEADS_ENGAGED')}
            number={overviewData?.leads_stats?.unique_leads}
            content={overviewData?.leads_stats?.engaged_leads}
            percent={overviewData?.leads_stats?.engaged_leads_percentage}
          />
          <Div className={'overview_and_superhuman--overview--half-items'}>
            <DashboardBlockPurple
              count={
                overviewData?.influenced_walkins_stats?.influenced_walkins_count
              }
              tooltipTitle={getText('TOOLTIP_INFLUENCED_WALK_INS')}
              icon={<SVGDashInfluencedWalkInsIcon />}
              title={getText('WORD_INFLUENCED_VISITS')}
              backgroundIcon={
                <SVGDashInfluencedWalkInsTreeIcon className={'tree_icon'} />
              }
              onClick={() => handleToMessagePage('influenced_walkins')}
            />
            <DashboardInfluencedSales />
          </Div>
        </Div>
      </Div>
      <Collapse
        className='overview_and_superhuman--collapse'
        expandIcon={isMobile ? () => <SVGArrowDown color={'var(--white)'} /> : false}
        expandIconPosition={'end'}
        defaultActiveKey={isMobile ? '' : 'superhuman'}
        collapsible={isMobile ? 'header' : 'disabled'}
        bordered={false}
        items={[
          {
            key: 'superhuman',
            label: (
              <Typography
                className={'overview_and_superhuman--superhuman--title'}
                level={1}
              >
                {getText('AI_BOT_SUPERHUMAN')}
              </Typography>
            ),
            children: (
              <Div className='overview_and_superhuman--superhuman'>
                <Div className={'overview_and_superhuman--overview'}>
                  <DashboardStatistics
                    number={overviewData?.superhuman_stats?.messages?.message_count}
                    data={superhumanChartData}
                    icon={<SVGDashMessageTotalSentIcon color={'#65D0C3'} />}
                    title={getText('WORD_TOTAL_MESSAGES_SENT')}
                    tooltipTitle={getText(
                      'TOOLTIP_SUPERHUMAN_AI_TOTAL_MESSAGES_SENT'
                    )}
                    responsiveChartHeight={202}
                  />
                  <Div className={'overview_and_superhuman--overview--half'}>
                    <Div className={'overview_and_superhuman--overview--half-items'}>
                      <DashboardHoursBlock
                        number={
                          overviewData?.superhuman_stats?.unique_appointments
                            ?.appointment_count
                        }
                        afterHours={
                          overviewData?.superhuman_stats?.unique_appointments
                            ?.after_hours_count
                        }
                        workingHours={
                          overviewData?.superhuman_stats?.unique_appointments
                            ?.working_hours_count
                        }
                        icon={<SVGDashCreatedAppointmentsIcon />}
                        title={getText('WORD_TOTAL_CREATED_APPOINTMENTS')}
                        firstSubTitle={getText('TEXT_WORKING_HOURS')}
                        secondSubTitle={getText('TEXT_AFTER_HOURS')}
                        tooltipTitle={getText(
                          'TOOLTIP_APPOINTMENTS_SCHEDULED_BY_SUPERHUMAN'
                        )}
                        tooltipFirstSubTitle={getText(
                          'TOOLTIP_SUPERHUMAN_AI_TOTAL_CREATED_APPOINTMENTS_WORKING_HOURS'
                        )}
                        tooltipSecondSubTitle={getText(
                          'TOOLTIP_SUPERHUMAN_AI_TOTAL_CREATED_APPOINTMENTS_AFTER_HOURS'
                        )}
                        onClick={(value) => {
                          if (value === 'all')
                            handleToMessagePage('superhuman_appointments', {
                              superhuman_appointments_type: 'all',
                            })
                          if (value === 'day')
                            handleToMessagePage('superhuman_appointments', {
                              superhuman_appointments_type: 'day',
                            })
                          if (value === 'night')
                            handleToMessagePage('superhuman_appointments', {
                              superhuman_appointments_type: 'night',
                            })
                        }}
                      />
                      <DashboardInfoBar
                        icon={<SVGDashTotalTimeSavedIcon />}
                        title={getText('WORD_TOTAL_TIME_SAVED')}
                        customNumber={utilNumber.formatMinutesToHoursDays(
                          overviewData?.superhuman_stats?.messages
                            ?.total_time_saved_in_minutes
                        )}
                        color={'yellow'}
                        tooltipTitle={getText(
                          'TOOLTIP_SUPERHUMAN_AI_TOTAL_TIME_SAVED'
                        )}
                      />
                    </Div>
                    <Div className={'overview_and_superhuman--overview--half-items'}>
                      <DashboardWalkBySuperhuman
                        count={
                          overviewData?.superhuman_stats?.influenced_walkins?.count
                        }
                        onClick={() =>
                          handleToMessagePage('superhuman_influenced_walkins')
                        }
                      />
                      <DashboardInfoBar
                        icon={<SVGDashSalesHandoffsIcon />}
                        title={getText('WORD_SALES_HANDOFFS')}
                        number={Number(
                          overviewData?.superhuman_stats?.assisted_handoff?.count
                        )}
                        color={'pink'}
                        tooltipTitle={getText(
                          'TOOLTIP_SUPERHUMAN_AI_SALES_HANDOFFS'
                        )}
                        onClick={() =>
                          handleToMessagePage('superhuman_assisted_handoffs')
                        }
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
            ),
          },
        ]}
      />
      <Collapse
        className='overview_and_superhuman--collapse'
        expandIcon={isMobile ? () => <SVGArrowDown color={'var(--white)'} /> : false}
        expandIconPosition={'end'}
        defaultActiveKey={isMobile ? '' : 'sequence'}
        collapsible={isMobile ? 'header' : 'disabled'}
        bordered={false}
        items={[
          {
            key: 'sequence',
            label: (
              <Typography
                className={'overview_and_superhuman--superhuman--title'}
                level={1}
              >
                {getText('WORD_SEQUENCE')}
              </Typography>
            ),
            children: (
              <Div className='overview_and_superhuman--sequence'>
                <DashboardSequence
                  dashboardSequenceData={overviewData?.sequence_messages_stats}
                />
              </Div>
            ),
          },
        ]}
      />
    </Div>
  )
}

export default OverviewAndSuperhuman
