import React from 'react'

const texts = {
  WORD_YES: 'Si',
  WORD_NO: 'No',
  ACTION_BACK: 'Regresar',
  ACTION_SEND: 'Enviar',

  TITLE_FEEDBACK_POS: (name) => (
    <>
      Usa <span className='url-name'>{name}</span> <br />
      para dejar un comentario?
    </>
  ),

  TITLE_FB_MESSENGER: 'Facebook messenger',
  TITLE_IG_MESSENGER: 'Instagram messenger',
  TITLE_GOOGLE_BUSINESS: 'Google business',

  TITLE_FEEDBACK_POS_LIST: 'Deja tu comentario en',

  ACTION_FEEDBACK_SKIP: 'Usa un sitio diferente',
  TITLE_FEEDBACK_NEG: 'Gracias por tus comentarios',
  TITLE_FEEDBACK_NEG_CONFIRM: 'Gracias por tus comentarios',

  TEXT_FEEDBACK_NEG_HELP: 'Lo sentimos mucho!',
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: 'Cuéntanos qué pasó',
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    'Estamos trabajando continuamente para mejorar la experiencia de nuestros clientes. Su opinión nos importa.',

  WORD_SHOW_ALL: 'Show All',
  WORD_SHOW_LESS: 'Show Less',

  UPLOAD_EXCEL_FILE: 'Upload Excel File',
  TEXT_CREATE_CAMPING_TYPE_DESCRIPTION:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
  BUILD_CONTENT: 'Build the content',

  TEXT_CREATE_A_SEGMENT: 'Create a Segment ',
  TEXT_CREATE_SEGMENT_DESCRIPTION:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  WORD_LANGUAGES: 'Languages',
  WORD_ASSIGNED_TO: 'Assigned to',
  WORD_APPOINTMENT: 'Appointment',

  ACTION_CANCEL: 'Cancel',
  ACTION_APPLY: 'Apply',

  TEXT_UPLOAD_EXCEL_FILE: 'Upload Excel File',
  TEXT_GENERATE_ALTERNATIVE_CONTENT: 'Generate alternative content',

  WORD_NEXT: 'Siguiente',
  WORD_PREVIOUS: 'Previous',

  TEXT_FILE_UPLOAD_ERROR:
    'The file format is incorrect. Please upload a file in .xls format',
  WORD_REPLACE: 'Replace',

  TEXT_START_YOUR_CAMPAIGN: 'Start your campaign',
  TEXT_NAME_OF_BROADCAST: 'Name of broadcast',
  TEXT_LOCATION_DO_THESE_USERS_BELONG_TO: 'Which location do these users belong to?',
  TEXT_CONSENT_DESCRIPTION:
    'These clients have already been opted in to our messaging services',

  TEXT_LETS_USE_SOME_MACHINE_LEARNING: 'Lets use some machine learning',
  TEXT_DESCRIPTION_OF_USE_SOME_MACHINE_LEARNING:
    'Sentiment analysis is available only for VinSolutions CRM. If the sentiment analysis is inactive, please connect a VinSolutions account on at least one Location of this Organization.',
  TEXT_LABEL_OF_USE_SOME_MACHINE_LEARNING:
    'Would you want to utilize A.I to set rules based on replies?',

  WORD_SCHEDULER: 'Scheduler',
  TEXT_ASSIGN_CAMPAIGN_TO: 'Assign campaign to',
  WORD_DATE: 'Date',
  WORD_TIME: 'Time',
  TEXT_ADD_A_TAG_FOR_BROADCASTED_USERS: 'Add a tag for broadcasted users',

  TEXT_TERMS_AND_CONDITIONS: 'Terms and conditions',
  TEXT_TITLE_OF_TERMS_AND_CONDITIONS:
    'I accept that the client list I will be broadcasting too has given me authority to reach out to them through SMS for any promotional related communications.',

  TEXT_LABEL_CREATE_OPPORTUNITIES_IN_VIN_SOLUTIONS:
    'Create opportunities in VinSolutions ONLY when a client replies with positive sentiment.',

  TEXT_ADD_AN_APPOINTMENT_TO_THE_BROADCAST: 'Add an appointment to the broadcast',

  TOOLTIP_TEXT:
    'This action is not supported for [messanger] messenger conversations just yet',

  TITLE_DEFAULT_COMMUNICATION_METHOD: 'Default communication method',
  WORD_NOT_LIKELY: 'No es probable',
  WORD_VERY_LIKELY: 'Muy probable',
  TEXT_PROMOTION_TITLE: '¡Queremos tu vehículo!',
  TEXT_PROMOTION_TEXT:
    'Obtenga una evaluación gratuita hoy. Siempre pagaremos más por su vehículo.',
  TEXT_PROMOTION_BUTTON: 'vender mi auto',
  TEXT_PROMOTION_TEMPLATE: 'Hola estoy interesado en vender mi auto.',
  NTF_SUCCESS_SEND_FEEDBACK: 'Retroalimentación enviada con éxito.',
  ERR_NO_FEEDBACK: 'Por favor, introduzca el texto.',
  CONVERSATION_ARCHIVED_NOTE:
    'You can’t write a message in the archived folder. Please, unarchive conversation first.',
  TEXT_LOCATION_IS_ACTIVE: 'Location is active',
  TEXT_LOCATION_DISABLING_WARNING:
    'Turning off a location will make changing the location of messages which are assigned to and disable all Managers and Support Agents who belong to it. Are you sure you want to move ahead?',
  WORD_LEAD_GEN_WIZARD: 'Lead Gen Wizard',
  DISABLE_INPUT: 'No mostrar el campo de entrada «Escribe tu mensaje»',
  REDIRECT_URL: 'Redirect URL',
  REDIRECT_URL_TOOLTIP:
    'La URL de redirección está designada para navegar al cliente directamente a una URL especificada, omitiendo la visualización del formulario de contacto. Aunque esta opción generalmente no se recomienda, puede ser beneficiosa bajo ciertas circunstancias.',
  INVALID_URL: 'URL inválida',
  GA_TRACK_PAGE_VIEWS_TITLE:
    'Rastrear las vistas de página para cada widget y las presentaciones de leads',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_1:
    'Nuestra plataforma le permite monitorear fácilmente las vistas de página para cada widget. Además, cuando se envía un nuevo lead, enviaremos un evento personalizado con los siguientes detalles:',
  GA_CATEGORY: 'Categoría',
  GA_MATADOR: 'Matador',
  GA_ACTION: 'Acción',
  GA_ACTION_DETAILS:
    '"connect" (o mobile_coupon, desktop_coupon, mobile_cta, integrated_cta, exit_intent)',
  GA_LABEL: 'Etiqueta',
  GA_SUBMISSION: 'Soumission',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_2:
    'Al rastrear estos eventos, obtendrá información valiosa sobre el compromiso de los usuarios y las conversiones a través de los widgets de Matador.',
  GA_INFO_ABOUT_GA: 'Información sobre GA',
  GA_GOOGLE_ANALYTICS_4_TRACKING: 'Seguimiento de Google Analytics 4',
  POSITION_TEXT: 'Posición del texto',
  ENABLE_BOLD_TEXT: 'Habilitar texto en negrita',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_3:
    'Al rastrear estos eventos, obtendrás información valiosa sobre la participación de los usuarios y las conversiones a través de los widgets de Matador.',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_4:
    'Además, nuestra plataforma admite el uso de múltiples ID de Google Analytics 4 (GA4). Puedes agregar varios ID de GA en el formato G-YYYYYYYYYY, G-XXXXXXXXXX, separados por comas. Esto te permite rastrear los mismos eventos en diferentes propiedades de GA para un análisis más detallado.',
  INVALID_GA_FORMAT:
    'Formato de ID de GA4 inválido. Cada ID debe estar en el formato G-XXXXXXXXXX con letras mayúsculas y dígitos.',
  INVALID_GA_FORMAT_COMMA:
    'Parece que has terminado con una coma o punto y coma. Por favor, añade otro código GA o elimina el último carácter.',
  INVALID_GA_FORMAT_SPACE:
    'Por favor, asegúrate de que hay un espacio después de cada coma o punto y coma al ingresar múltiples ID de GA4.',
  FOREGROUND_COLOR: 'Color de primer plano',
  CONNECT_BACKGROUND_COLOR: 'Color de fondo',
  INTEGRATION_INSTRUCTIONS_TITLE: 'Instrucciones de Integración',
  INTEGRATION_INSTRUCTIONS_CONTENT:
    'Para integrar el widget CTA Integrado de Matador en su sitio web, siga estos pasos simples. Primero, localice la sección de su sitio web donde desea que se muestre el widget. Podría ser en su página de inicio, una página de inventario específica, u otra sección relevante.',
  STEP1_TITLE: 'Paso 1: Añadir el Código HTML',
  STEP1_CONTENT:
    'Añada el código HTML anterior en el lugar donde desea que se muestre el widget en su sitio web.',
  STEP2_TITLE: 'Paso 2: Añadir el Enlace de Estilo',
  STEP2_CONTENT:
    'Incluya el enlace de estilo en la sección <head> de su sitio web para asegurar el estilo adecuado del widget de Matador.',
  STEP3_TITLE: 'Paso 3: Añadir el Enlace del Script',
  STEP3_CONTENT:
    'Añada el enlace del script en la sección <head> de su sitio web para habilitar la funcionalidad del widget CTA integrado de Matador.',
  INTEGRATION_CONGRATULATIONS:
    '¡Felicidades! Ha integrado con éxito el widget CTA Integrado de Matador en su sitio web. Los visitantes ahora pueden disfrutar de la conveniencia de interactuar con los widgets de Matador.',
  INTEGRATION_SUPPORT:
    'Si tiene alguna pregunta o encuentra algún problema durante el proceso de integración, por favor contacte a nuestro equipo de soporte en support@matador.ai. ¡Estamos aquí para ayudar!',
  ADDITIONAL_INSTRUCTIONS: 'Instrucciones Adicionales',
  VEHICLE_PROPERTIES_EXPLANATION:
    'Puede pasar propiedades del vehículo al CTA integrado. Se creará un vehículo de interés en la conversación basado en estas propiedades.',
  MILEAGE_EXPLANATION: 'El kilometraje del vehículo.',
  STOCK_NUMBER_EXPLANATION: 'El número de stock del vehículo.',
  MODEL_EXPLANATION: 'El modelo del vehículo.',
  YEAR_EXPLANATION: 'El año del vehículo.',
  MAKE_EXPLANATION: 'La marca del vehículo.',
  VIN_EXPLANATION: 'El VIN (Número de Identificación del Vehículo) del vehículo.',
  EXAMPLE: 'Ejemplo:',
  VEHICLE_PROPERTIES_EXPLANATION_AUTOMATIC:
    'El widget CTA integrado de Matador puede detectar automáticamente los detalles del vehículo desde su sitio web. Lee atributos como VIN, marca, modelo, año, stock y kilometraje de los elementos principales de su página usando etiquetas de datos estándar. Esto permite que el widget pase la información del vehículo directamente a las conversaciones sin necesidad de entrada manual.',
  VEHICLE_DETAILS_INTEGRATE_VEHICLE_DETAILS_WITH_MATADOR_CONNECT:
    'Integrar detalles del vehículo con Matador Connect',
  VEHICLE_DETAILS_MATADOR_CONNECT_ENABLES_AUTOMATIC_INCLUSION_OF_KEY_VEHICLE_INFORMATION:
    'Matador Connect permite la inclusión automática de información clave del vehículo, como VIN, marca, modelo, año, número de stock y kilometraje, dentro de los mensajes enviados por los usuarios desde su sitio web. Esto significa que cada vez que un cliente muestre interés en un vehículo, Matador Connect incorporará estos detalles en el mensaje, haciéndolos accesibles directamente desde la bandeja de entrada para una referencia y seguimiento fáciles.',
  VEHICLE_DETAILS_USING_STRUCTURED_DATA_FOR_SEAMLESS_INTEGRATION:
    'Uso de datos estructurados para una integración perfecta',
  VEHICLE_DETAILS_TO_MAKE_THE_MOST_OF_MATADOR_CONNECT:
    'Para aprovechar al máximo Matador Connect, asegúrese de que los datos estructurados del vehículo estén presentes en sus páginas de detalles del vehículo. Siguiendo el',
  VEHICLE_DETAILS_SCHEMA_ORG_CAR_SCHEMA: 'esquema de coche de Schema.org',
  VEHICLE_DETAILS_MATADOR_CONNECT_WILL_AUTOMATICALLY_DETECT:
    ', Matador Connect detectará e incorporará automáticamente esta información de manera perfecta.',
  VEHICLE_DETAILS_FOR_FURTHER_ASSISTANCE:
    'Para obtener más ayuda, no dude en ponerse en contacto con nuestro equipo de soporte en',
  VEHICLE_DETAILS_SUPPORT_EMAIL: 'support@matador.ai',
  VEHICLE_DETAILS_INTEGRATING_MATADOR_CONNECT_WITH_VEHICLE_DETAILS:
    'Integración de Matador Connect con detalles del vehículo',
  LABEL_ENABLE_BOLD_TEXT: 'Habilitar texto en negrita',
  WORD_INPUT_PLACEHOLDER: 'Texto de marcador de posición',
  AI_CHAT: 'Chat IA',
  ENABLE_AI_CHAT: 'Activar el Chat IA',
}

export default texts
