import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import { userSelector } from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import SVGOrgTemplateIcon from '../../../icons/SVG/SVGOrgTemplateIcon'
import SVGOrgOpeningHoursIcon from '../../../icons/SVG/SVGOrgOpeningHoursIcon'
import SVGOrgRestricionsIcon from '../../../icons/SVG/SVGOrgRestricionsIcon'
import SVGOrgGeneralIcon from '../../../icons/SVG/SVGOrgGeneralIcon'
import SVGOrgTagsIcon from '../../../icons/SVG/SVGOrgTagsIcon'
import SVGOrgWorkflowIcon from '../../../icons/SVG/SVGOrgWorkflowIcon'
import SVGOrgTouchpointIcon from '../../../icons/SVG/SVGOrgTouchpointIcon'
import SVGOrgIPWhiteListIcon from '../../../icons/SVG/SVGOrgIPWhiteListIcon'
import SVGOrgConversationAIIcon from '../../../icons/SVG/SVGOrgConversationAIIcon'
import SVGSettingsTransferIcon from '../../../icons/SVG/SVGSettingsTransferIcon'
import SVGOrgSequenceIcon from '../../../icons/SVG/SVGOrgSequenceIcon'
import SVGVoiceAiIcon from '../../../icons/SVG/SVGVoiceAiIcon'
import { SUPER_ADMIN_FAVORITE_EMAILS } from '../../../devOptions'
import useDeviceCheck from '../../../utils/useDeviceCheck'

const copySettingAllowEmail = [
  ...SUPER_ADMIN_FAVORITE_EMAILS,
  'jack@delosdigital.com.au',
  'jeff.woolcock@gmail.com',
]

const OrganizationMenu = ({ id }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const user = useSelector(userSelector)

  const [selectedRoute, setSelectedRoute] = useState([])

  const dropMenuUrlPaths = useMemo(() => {
    return [
      `/organization/settings/${id}/templates/email/list`,
      `/organization/settings/${id}/templates/email/create`,
      `/organization/settings/${id}/templates/email/edit`,
      `/organization/settings/${id}/templates/sms/list`,
      `/organization/settings/${id}/templates/sms/create`,
      `/organization/settings/${id}/templates/sms/edit`,
    ]
  }, [id])

  const subMenuTemplates = [
    {
      key: `/organization/settings/${id}/templates/sms/list`,
      label: getText('WORD_SMS_TEMPLATES'),
    },
    {
      key: `/organization/settings/${id}/templates/email/list`,
      label: getText('WORD_EMAIL_TEMPLATES'),
    },
  ]

  const RespSubMenuTemplates = () => {
    const items = [
      {
        key: `/organization/settings/${id}/templates/sms/list`,
        label: (
          <div
            onClick={() => {
              navigate(`/organization/settings/${id}/templates/sms/list`)
              setSelectedRoute(`/organization/settings/${id}/templates/sms/list`)
            }}
          >
            {getText('WORD_SMS_TEMPLATES')}
          </div>
        ),
      },
      {
        key: `/organization/settings/${id}/templates/email/list`,
        label: (
          <div
            onClick={() => {
              navigate(`/organization/settings/${id}/templates/email/list`)
              setSelectedRoute(`/organization/settings/${id}/templates/email/list`)
            }}
          >
            {getText('WORD_EMAIL_TEMPLATES')}
          </div>
        ),
      },
    ]
    return (
      <Dropdown
        menu={{ items }}
        placement={'bottom'}
        overlayClassName='dropdown-matador-connect-overlay'
        trigger={['click']}
      >
        <SVGOrgTemplateIcon />
      </Dropdown>
    )
  }

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      let newSelectedMenu = location.pathname
      if (
        location.pathname.includes('/dripsequence/edit') ||
        location.pathname.includes('/dripsequence/create')
      ) {
        newSelectedMenu = `/organization/settings/${id}/dripsequence/list`
      } else if (
        location.pathname.includes('/dripsequence/template/edit') ||
        location.pathname.includes('/dripsequence/template/create')
      ) {
        newSelectedMenu = `/organization/settings/${id}/dripsequence/template/list`
      } else if (
        location.pathname.includes('/template/sms/edit') ||
        location.pathname.includes('/template/sms/create')
      ) {
        newSelectedMenu = `/organization/settings/${id}/templates/sms/list`
      } else if (
        location.pathname.includes('/template/email/edit') ||
        location.pathname.includes('/template/email/create')
      ) {
        newSelectedMenu = `/organization/settings/${id}/templates/email/list`
      } else if (
        location.pathname.includes('/workflow/create') ||
        location.pathname.includes('/workflow/edit')
      ) {
        newSelectedMenu = `/organization/settings/${id}/workflow/list`
      } else if (location.pathname.includes('voice_ai')) {
        newSelectedMenu = `/organization/settings/${id}/voice_ai`
      }
      setSelectedRoute(newSelectedMenu)
    }
  }, [location.pathname])

  const isSuperAdminAndAdmin = user.isSuperAdmin || user.isAdmin
  const onlySuperAdmin = user.isSuperAdmin && user.isAdmin

  const isCSTeamEmail = useMemo(
    () => SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email),
    [user?.email]
  )
  const canCopySettings = useMemo(
    () => copySettingAllowEmail.includes(user.email),
    [user?.email]
  )
  const canSeeVoiceAI = useMemo(
    () => SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email),
    [user?.email]
  )

  return (
    <div className='org-side-menu-layout-wrapper'>
      <Menu
        className='side-bar-layout'
        mode='inline'
        selectedKeys={[selectedRoute]}
        inlineCollapsed={isTabletPortraitOrMobile}
        onClick={(e) => {
          if (e.key === 'templates') {
            return
          }
          navigate(e.key)
          setSelectedRoute(e.key)
        }}
        items={[
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/general`,
            label: getText('WORD_GENERAL'),
            icon: <SVGOrgGeneralIcon />,
          },
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/tags`,
            label: getText('WORD_TAGS'),
            icon: <SVGOrgTagsIcon />,
          },
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/dripsequence/list`,
            label: getText('TITLE_DRIP_SEQUENCE'),
            icon: <SVGOrgSequenceIcon />,
          },
          isCSTeamEmail &&
            onlySuperAdmin && {
              key: `/organization/settings/${id}/dripsequence/template/list`,
              label: getText('WORD_DRIP_SEQUENCE_TEMPLATES'),
              icon: <SVGOrgTemplateIcon />,
            },
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/touchpoints`,
            label: getText('WORD_TOUCHPOINTS'),
            icon: <SVGOrgTouchpointIcon />,
          },
          {
            key: `/organization/settings/${id}/workflow/list`,
            label: getText('WORD_WORKFLOWS'),
            icon: <SVGOrgWorkflowIcon />,
          },
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/restrictions`,
            label: getText('WORD_SETTINGS_AND_PERMISSIONS'),
            icon: <SVGOrgRestricionsIcon />,
          },
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/superhuman`,
            label: getText('AI_BOT_SUPERHUMAN'),
            icon: <SVGOrgConversationAIIcon />,
          },
          isSuperAdminAndAdmin &&
            (isTabletPortraitOrMobile
              ? {
                  icon: <RespSubMenuTemplates />,
                  label: getText('WORD_USER_TEMPLATES'),
                  key: 'templates',
                  className: dropMenuUrlPaths.includes(selectedRoute)
                    ? 'active'
                    : '',
                }
              : {
                  key: `templates`,
                  label: getText('WORD_USER_TEMPLATES'),
                  icon: <SVGOrgTemplateIcon />,
                  children: subMenuTemplates,
                }),
          isSuperAdminAndAdmin && {
            key: `/organization/settings/${id}/opening_hours`,
            label: getText('WORD_OPENING_HOURS'),
            icon: <SVGOrgOpeningHoursIcon />,
          },
          onlySuperAdmin && {
            key: `/organization/settings/${id}/ip_whitelist`,
            label: getText('WORD_IP_WHITELIST'),
            icon: <SVGOrgIPWhiteListIcon />,
          },
          Boolean(onlySuperAdmin && canCopySettings) && {
            key: `/organization/settings/${id}/settings_transfer`,
            label: getText('WORD_SETTINGS_TRANSFER'),
            icon: <SVGSettingsTransferIcon />,
          },
          Boolean(isSuperAdminAndAdmin && canSeeVoiceAI) && {
            key: `/organization/settings/${id}/voice_ai`,
            label: getText('WORD_VOICE_AI'),
            icon: <SVGVoiceAiIcon />,
          },
        ]}
      />
    </div>
  )
}

export default OrganizationMenu
