import React from 'react'
import { getText } from '../../../../lang'
import InputFormNumber from '../../../../components/Form/InputFormNumber'
import InputFormText from '../../../../components/Form/InputFormText'
import { replaceWords } from '../../../../utils'

const WorkflowDetectIntentWaitForReply = ({ field, form, mainField }) => {
  return (
    <div className={`detect-intent-actions ${field ? 'select-and-input' : 'wsi'}`}>
      <InputFormText name={field ? [field.name, 'type'] : null} hidden />
      <div className='detect-intent-wait-for-reply'>
        <span className='title'>{getText('WORD_WAIT_FOR_REPLY')}</span>
        <InputFormNumber
          name={field ? [field.name, 'config', 'duration'] : null}
          formClassName={'wait-for-reply-input'}
          disabled={Boolean(field === undefined)}
          min={1}
          minMessage={getText('ERR_MSG_INPUT_NUMBER')}
          max={99}
          maxMessage={replaceWords(getText('ERR_MSG_INPUT_NUMBER_MAX'), {
            number: 99,
          })}
          onChange={(value) => {
            form.setFieldValue(
              ['actions', mainField.name, 'intents', 0, 'config', 'duration'],
              value
            )
            form.setFieldValue(
              ['actions', mainField.name, 'intents', 1, 'config', 'duration'],
              value
            )
            form.setFieldValue(
              ['actions', mainField.name, 'intents', 2, 'config', 'duration'],
              value
            )
          }}
        />
        <InputFormText
          name={field ? [field.name, 'config', 'unit'] : null}
          label={''}
          disabled={true}
          placeholder={getText('WORD_DAYS').toLowerCase()}
          formClassName={'wait-for-reply-input-text'}
          allowClear={false}
        />
      </div>
    </div>
  )
}

export default WorkflowDetectIntentWaitForReply
