import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12914_442735)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_12914_442735)'>
          <circle cx='18' cy='17' r='13' fill='#D1702A' />
        </g>
        <path
          d='M16.4336 19.2L17.5069 20.0333C17.6469 20.1733 17.9603 20.24 18.1669 20.24H19.4869C19.9003 20.24 20.3536 19.9267 20.4603 19.5133L21.2936 16.9866C21.4669 16.5 21.1536 16.0866 20.6336 16.0866H19.2469C19.0403 16.0866 18.8669 15.9133 18.9003 15.6733L19.0736 14.5666C19.1403 14.2533 18.9336 13.9066 18.6203 13.8066C18.3403 13.6999 17.9936 13.8399 17.8603 14.0466L16.4403 16.16'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M14.666 19.2V15.8066C14.666 15.32 14.8727 15.1467 15.3593 15.1467H15.706C16.1927 15.1467 16.3994 15.32 16.3994 15.8066V19.2C16.3994 19.6867 16.1927 19.86 15.706 19.86H15.3593C14.8727 19.86 14.666 19.6867 14.666 19.2Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22 22.5733H21.4933C20.96 22.5733 20.4533 22.78 20.08 23.1533L18.94 24.28C18.42 24.7934 17.5733 24.7934 17.0533 24.28L15.9133 23.1533C15.54 22.78 15.0267 22.5733 14.5 22.5733H14C12.8933 22.5733 12 21.6867 12 20.5933V13.32C12 12.2267 12.8933 11.34 14 11.34H22C23.1067 11.34 24 12.2267 24 13.32V20.5933C24 21.68 23.1067 22.5733 22 22.5733Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12914_442735)'
      />
      <defs>
        <filter
          id='filter0_f_12914_442735'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_12914_442735'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12914_442735'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_12914_442735'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashReviewInvitesCompletedBySourceIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashReviewInvitesCompletedBySourceIcon
