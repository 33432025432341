import React, { memo } from 'react'
import { Card, Col, Divider, Flex, Row, Typography } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'

const DashboardHoursBlock = ({
  number,
  workingHours,
  afterHours,
  icon,
  title,
  tooltipTitle,
  tooltipFirstSubTitle,
  tooltipSecondSubTitle,
  firstSubTitle,
  secondSubTitle,
  onClick,
  disableAnimation,
}) => {
  return (
    <Card
      className={`dashboard_block ${number && !disableAnimation ? 'underline_animation_parent' : ''}`}
    >
      <Div
        className='underline_animation--text-first'
        onClick={
          number
            ? () => {
                onClick && onClick('all')
              }
            : null
        }
      >
        <Flex gap={8} align={'center'} className='dashboard_block--header'>
          {icon}
          <Div className='dashboard_block--header--right'>
            <span>
              {title}
              {tooltipTitle && <DashboardInfoTooltip title={tooltipTitle} />}
            </span>
          </Div>
        </Flex>
        <Flex
          align='center'
          style={{ marginLeft: 0 }}
          className={'dashboard_sent_statistics--information'}
        >
          <Typography level={1}>{utilNumber.numberFractions(number)}</Typography>
        </Flex>
      </Div>
      <Row className={'dashboard_sent_statistics--information--hours'} wrap={false}>
        <Col
          onClick={
            workingHours
              ? () => {
                  onClick && onClick('day')
                }
              : null
          }
          className='underline_animation--text-second'
        >
          <span className='dashboard_sent_statistics--information--hours-text'>
            {firstSubTitle}
            {tooltipFirstSubTitle && (
              <DashboardInfoTooltip title={tooltipFirstSubTitle} />
            )}
          </span>
          <p>{utilNumber.numberFractions(workingHours || 0)}</p>
        </Col>
        <Divider
          type='vertical'
          style={{
            borderInlineStart: '1px solid #38334D',
            height: '48px',
          }}
        />
        <Col
          onClick={
            afterHours
              ? () => {
                  onClick && onClick('night')
                }
              : null
          }
          className='underline_animation--text-third'
        >
          <span className='dashboard_sent_statistics--information--hours-text'>
            {secondSubTitle}
            {tooltipSecondSubTitle && (
              <DashboardInfoTooltip title={tooltipSecondSubTitle} />
            )}
          </span>
          <p>{utilNumber.numberFractions(afterHours || 0)}</p>
        </Col>
      </Row>
    </Card>
  )
}

export default memo(DashboardHoursBlock)
