import React, { memo } from 'react'
import { Card, Divider, Flex, Progress, Tooltip, Typography } from 'antd'
import Div from '../../../../components/Div/Div'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'
import utilNumber from '../../../../utils/utilNumber'

const DashboardMetricBlock = ({
  number,
  percent,
  content,
  icon,
  title,
  titleTooltip,
  secondaryLabel,
  secondaryTooltipTitle,
  secondaryText,
  customContent,
  hideTooltip,
}) => {
  return (
    <Card className='dashboard_block dashboard_metric_block'>
      <Div className={`dashboard_block--blurred-part green`}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        {icon}
        <Div className='dashboard_block--header--right'>
          <span>
            {title}
            {titleTooltip && <DashboardInfoTooltip title={titleTooltip} />}
          </span>
        </Div>
      </Flex>
      <Flex
        align='center'
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography level={1}>{utilNumber.numberFractions(number || 0)}</Typography>
      </Flex>
      <Divider style={{ borderBlockStart: '1px solid #38334D' }} />
      <Flex
        gap={12}
        vertical={'horizontal'}
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography level={3} style={{ color: 'var(--white)' }}>
          {secondaryLabel}
        </Typography>
        <Typography level={1}>
          {customContent || utilNumber.numberFractions(content || 0)}
        </Typography>
        {secondaryText && (
          <Typography level={3} style={{ color: 'var(--white)', marginTop: 30 }}>
            {secondaryText}
            {secondaryTooltipTitle && (
              <DashboardInfoTooltip title={secondaryTooltipTitle} />
            )}
          </Typography>
        )}
      </Flex>
      <Flex align='center' justify='space-between'>
        <Div className='dashboard_block--tooltip-progress'>
          <Tooltip
            overlayClassName={'green'}
            getPopupContainer={(trigger) => trigger.parentNode}
            title={`${Math.round(percent || 0)}%`}
            align={{
              useCssRight: true,
              targetOffset: ['100%', '100%'],
            }}
            overlayStyle={{
              left: `calc(${Math.round(percent || 0)}% - 23px)`,
            }}
            destroyTooltipOnHide
            open={hideTooltip}
          >
            <Progress
              percent={Math.round(percent || 0)}
              percentPosition={{
                align: 'center',
                type: 'inner',
              }}
              showInfo={false}
              strokeColor={{
                '0%': '#D3C759',
                '100%': '#48AF81',
              }}
            />
          </Tooltip>
        </Div>
      </Flex>
    </Card>
  )
}

export default memo(DashboardMetricBlock)
