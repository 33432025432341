import React from 'react'
import { Divider, Form } from 'antd'
import { getText } from '../../../../lang'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MyDefaultRow from '../../../../components/MyDefaultRow/MyDefaultRow'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultTagBlock from '../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import Div from '../../../../components/Div/Div'

const SuperhumanIntentConfiguration = ({
  intentName,
  form,
  onRemove,
  field,
  loading,
  usersList,
  onSearch,
  onLoadMore,
  onBlur,
}) => {
  const excludeFollowingIntents = Form.useWatch(
    ['customized_intents', field.name, 'is_excluded'],
    form
  )

  return (
    <Div className={'supehuman_intent_config_wrapper'}>
      <Divider
        plain
        style={{
          margin: '16px 0',
          backgroundColor: 'rgba(91, 102, 234, 0.1)',
        }}
      />
      <MyDefaultRow
        leftSide={
          <MyDefaultTagBlock
            title={intentName?.label || ''}
            disableMargin
            fontSize={14}
            backgroundColor={'var(--greenLight)'}
            titleColor={'var(--green)'}
          />
        }
        rightSide={
          <ConfirmPopup
            placement={'topRight'}
            title={getText('MSG_REMOVE_INTENT_ITEM')}
            onConfirm={() => {
              onRemove && onRemove()
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={
              <SVGMyDefaultDeleteIcon
                style={{
                  cursor: 'pointer',
                }}
              />
            }
          />
        }
      />
      <MyDefaultSelect
        isForm
        name={[field.name, 'tagging_configuration', 'users_to_tag']}
        label={''}
        showSearch={true}
        mode='multiple'
        onSearch={onSearch}
        placeholder={getText('LABEL_SELECT_THE_USERS_TO_TAG')}
        options={usersList}
        customizedColorsObj={{
          assigned_users: {
            backgroundColor: 'var(--mainColor)',
            textColor: 'var(--white)',
          },
        }}
        loading={loading}
        required={Boolean(excludeFollowingIntents)}
        onLoadMore={onLoadMore}
        onBlur={onBlur}
        message={getText('TEXT_ADD_AT_LEAST_ONE_USER_WHO_WILL_TAGGING')}
      />
      <MyDefaultSwitch
        isForm
        noText
        isFirst
        titleStyle={{ fontSize: 14 }}
        name={[field.name, 'is_excluded']}
        title={getText('LABEL_EXCLUDE_THIS_INTENT_FROM_BEING_AUTOREPLIED')}
        onChange={() => {
          if (excludeFollowingIntents) {
            form.validateFields([
              [field.name, 'tagging_configuration', 'users_to_tag'],
            ])
          }
        }}
      />
      {!excludeFollowingIntents && (
        <React.Fragment>
          <MyDefaultSwitch
            isForm
            noText
            isFirst
            titleStyle={{ fontSize: 14 }}
            name={[
              field.name,
              'tagging_configuration',
              'tag_only_when_handoff_needed',
            ]}
            title={getText('LABEL_TAG_ONLY_WHEN_USER_INTERVENTION_IS_NEEDED')}
          />
          <MyDefaultTextarea
            isForm
            isLast
            name={[field.name, 'instructions']}
            label={''}
            placeholder={getText(
              'TEXT_PROVIDE_INSTRUCTIONS_CLARIFY_HOW_YOU_WANT_RESPONSES_INTENT_GENERATED'
            )}
          />
        </React.Fragment>
      )}
    </Div>
  )
}

export default SuperhumanIntentConfiguration
