import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import questionsActions from '../../../../../store/modules/questionsActions'
import greetingsActions from '../../../../../store/modules/greetingsActions'
import { getText } from '../../../../../lang'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import MatForm from '../../../../../components/Form/MatForm'
import MatRow from '../../../../../components/MatRow'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormNumberSecondary from '../../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import UrlConditionManager from '../settings/UrlConditionManager'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import ThemePreview from '../preview/ThemePreview'
import GreetingsDragable from './GreetingsDragable'

const GreetingEdit = () => {
  const { locationObj, settings } = useLocationLayout()

  const location = useLocation()
  const navigate = useNavigate()
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)

  const locationId = locationObj._id
  const languages = organization?.languages || []
  const defaultLanguage = organization?.defaultLanguage || 'en'
  const greetingData = location.state && location.state.greetingData
  const [urls, setUrls] = useState(greetingData ? greetingData.conditions.url : [])

  const [listQuestions, setListQuestions] = useState([])
  const [form] = Form.useForm()
  const isNewGreeting = !greetingData

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    refreshListQuestions()
  }, [])

  const refreshListQuestions = async () => {
    const options = { _location_id: locationId }
    const result = await questionsActions.getPageList(0, 100, '', options)
    if (result.success) {
      const newListQuestions = result.data
        .map((questionItem, index) => {
          let selected = false
          let weight = index + 100
          if (greetingData) {
            const selectedQuestion = greetingData.questions.find(
              (question) => question._question_id === questionItem._id
            )
            if (selectedQuestion) {
              selected = true
              weight = selectedQuestion.weight
            }
          }
          questionItem.weight = weight
          questionItem.selected = selected
          return questionItem
        })
        .sort((a, b) => a.weight - b.weight)

      setListQuestions(newListQuestions)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    const payload = {
      name: allValues.name,
      time_on_page: allValues.time_on_page,
      enabled: greetingData ? greetingData.enabled : false,
      questions: listQuestions
        .filter((question) => question.selected)
        .map((questionSelected) => ({
          _question_id: questionSelected._id,
          weight: questionSelected.weight,
        })),
      conditions: {
        url: urls,
      },
      ...(isNewGreeting && { _location_id: locationId }),
    }

    const result = isNewGreeting
      ? await greetingsActions.saveNew(payload)
      : await greetingsActions.saveUpdate(payload, greetingData._id)

    if (result.success) {
      notifySuccess(getText('SUCCESS_TRIGGER_SAVED'))
      navigate(`/location/settings/${locationId}/livechat-greetings`)
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  return (
    <MatadorConnectLayout className='livechat-greeting-edit'>
      <MatadorConnectListTitle
        title={
          isNewGreeting
            ? getText('TITLE_CREATE_NEW_GREETING')
            : getText('TITLE_EDIT_GREETING')
        }
      />
      <div className='form-chatboxPreview-wrapper'>
        <MatForm
          onFinish={onFinish}
          form={form}
          initialValues={{
            name: (greetingData && greetingData.name) || '',
            conditions: (greetingData && greetingData.conditions) || [],
            time_on_page: (greetingData && greetingData.time_on_page) || 2,
          }}
        >
          <InputFormTextSecondary
            name='name'
            label={getText('WORD_NAME')}
            required
            errorMessage={getText('ERR_VALIDATION_REQUIRED')}
          />
          <UrlConditionManager urls={urls} setUrls={setUrls} hideAction />
          <MatBlockWithLabel
            label={getText('WORD_CURRENT_PAGE_TIME')}
            className='time-on-page-wrapper'
            black
          >
            <MatRow>
              <div>{getText('TEXT_GREETING_TIME_TRIGGER_START')}</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputFormNumberSecondary
                  name='time_on_page'
                  required
                  errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                />
                <div>{getText('TEXT_GREETING_TIME_TRIGGER_END')}</div>
              </div>
            </MatRow>
          </MatBlockWithLabel>
          <MatBlockWithLabel black label={getText('WORD_SELECT_QUESTIONS')}>
            {listQuestions.length ? (
              <GreetingsDragable
                listQuestions={listQuestions}
                defaultLanguage={defaultLanguage || languages[0]}
                onChange={(list) => {
                  const newList = list.map((question, index) => {
                    question.weight = index + 1
                    return question
                  })
                  setListQuestions(newList)
                }}
              />
            ) : (
              <div>
                <p style={{ margin: '0 10px' }}>{getText('TEXT_NO_QUESTIONS')}</p>
                <MatButton
                  onClick={() =>
                    navigate(`/location/settings/${locationId}/livechat-questions`)
                  }
                  buttonText={getText('ADD_QUESTION')}
                  size='middle'
                />
              </div>
            )}
          </MatBlockWithLabel>
        </MatForm>
        <div className='matador-connect-settings-chat-wrapper'>
          <ThemePreview
            settings={{ ...settings, _location_id: settings._token }}
            language={languages[0]}
            locationId={settings._token}
            theme={settings.theme}
          />
        </div>
      </div>
    </MatadorConnectLayout>
  )
}

export default GreetingEdit
