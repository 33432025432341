import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { locationSelector } from '../../../store/selectors/selectors'
import statActions from '../../../store/modules/statActions'
import { getText } from '../../../lang'
import { MONTH_ORDER } from '../../../utils'
import SVGDashReviewInvitesCompletedIcon from '../../../icons/SVG/SVGDashReviewInvitesCompletedIcon'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import Div from '../../../components/Div/Div'
import DashboardMetricBlock from '../DashboardComponents/DashboardMetricBlock/DashboardMetricBlock'
import { notifyError } from '../../../utils/Notify'
import { CUSTOM, ORG } from '../../../devOptions'
import { DashboardContext } from '../Dashboard'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ReputationPulseReviewInvitesSource from './ReputationPulseReviewInvitesSource/ReputationPulseReviewInvitesSource'
import ReputationPulseStatistic from './ReputationPulseStatistic/ReputationPulseStatistic'
import ReputationPulseCSIScore from './ReputationPulseCSIScore/ReputationPulseCSIScore'
// import ReputationPulseUsersReviews from './ReputationPulseUsersReviews/ReputationPulseUsersReviews'
import './DashboardReputationPulse.scss'

const DashboardReputationPulse = () => {
  const { selectedRange, selectedDataType, selectCustomRange } =
    useContext(DashboardContext)
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const location = useSelector(locationSelector)

  const [loading, setLoading] = useState(true)
  const [reputationPulseData, setReputationPulseData] = useState({})

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location?._id
    const result = await statActions.getReputationPulseStatsDashboard(
      selectedRange,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date
    )
    if (result.success) {
      setReputationPulseData(result.data)
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [location?._id, selectedRange, selectedDataType, selectCustomRange])

  useEffect(() => {
    fill()
  }, [fill])

  const convertReputationPulseChartData = (obj) => {
    if (!Boolean(obj)) return []
    let currentMonthIndex = new Date().getMonth()
    const result = [
      ...MONTH_ORDER.slice(currentMonthIndex - 5),
      ...MONTH_ORDER.slice(0, currentMonthIndex + 1),
    ].map((month) => ({
      name: isTabletPortraitOrMobile
        ? getText(capitalize(month.substring(0, 3)))
        : getText(capitalize(month)),
      reviewSent: obj[month]?.total_count,
      reviewCompleted: obj[month]?.voted_count,
      percent: obj[month]?.voted_count_percentage,
    }))
    return result
  }

  const convertedReputationPulseChart = convertReputationPulseChartData(
    reputationPulseData?.reputation_pulse_chart
  )

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Div className={'dashboard_reputation_pulse'}>
      <ReputationPulseCSIScore csi_score={reputationPulseData?.csi_score} />
      <DashboardMetricBlock
        hideTooltip={false}
        icon={<SVGDashReviewInvitesCompletedIcon />}
        title={getText('WORD_REVIEW_INVITES_COMPLETED')}
        secondaryLabel={getText('WORD_PERCENT_REVIEW_INVITES_COMPLETED')}
        number={reputationPulseData?.voted_count}
        customContent={reputationPulseData?.voted_count_percentage + '%'}
        percent={reputationPulseData?.voted_count_percentage}
      />
      <ReputationPulseReviewInvitesSource
        voted_count_by_source={reputationPulseData?.voted_count_by_source}
        total_voted_count_by_sources={
          reputationPulseData?.total_voted_count_by_sources
        }
      />
      <ReputationPulseStatistic
        leftNumber={reputationPulseData?.total_six_months?.total_count}
        rightNumber={reputationPulseData?.total_six_months?.voted_count}
        chartData={convertedReputationPulseChart}
        responsiveChartHeight={385}
      />
      {/* <ReputationPulseUsersReviews /> */}
    </Div>
  )
}

export default DashboardReputationPulse
