import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { getText } from '../../../../../lang'
import {
  checkFormatPhoneNumber,
  formatDateTime,
  getLongName,
  MainColor,
} from '../../../../../utils'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'
import { AI_BOT } from '../../../utils/constants'
import SVGDrawerSequence from '../../../../../icons/SVG/SVGDrawerSequence'
import ProfileLetters from '../../../../../components/ProfileLetters'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import CenterMessageReviewInfoBlock from './CenterMessageReviewAction'
import './CenterMessageReview.scss'

const getTwilioLatestStatus = (status) => {
  let returnStatus = '-'
  if (status) {
    if (status === 'delivered') {
      returnStatus = getText('WORD_DELIVERED')
    } else if (status === 'sent') {
      returnStatus = getText('WORD_SENT')
    } else if (status === 'queued') {
      returnStatus = getText('WORD_QUEUED')
    } else if (status === 'failed') {
      returnStatus = getText('WORD_FAILED')
    } else {
      returnStatus = status.charAt(0).toUpperCase() + status.slice(1)
    }
  }

  return returnStatus
}

const CenterMessageReview = (props) => {
  const { conversation, title, inviteReviewBlock, message, infoValueList } = props

  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const receiver = conversation.receiver || {}
  const twilioLatestStatus = getTwilioLatestStatus(message?.twilio_latest_status)
  const initiatorPhone = checkFormatPhoneNumber(conversation.initiator_phone)

  return (
    <motion.div
      className='center_message_more_items_wrapper'
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <div className='center_message_more_items_wrapper--inner'>
        <div className='post_info'>
          <span className='phone'>{initiatorPhone}</span>
          <span className='author'>
            {Boolean(message.initiating_user_name === AI_BOT)
              ? getText('WORD_SEQUENCE')
              : message.initiating_user_name || ''}
          </span>
          {Boolean(message.initiating_user_name === AI_BOT) && (
            <SVGDrawerSequence
              color={MainColor}
              height={16}
              width={16}
              style={{ marginLeft: 10 }}
            />
          )}
        </div>
        <div className='center_message_more_items_wrapper--inner-block'>
          <div className='center_message_more_items_wrapper--inner-block-title'>
            {title}
          </div>
          {inviteReviewBlock && (
            <div className='center_message_more_items_wrapper--inner-block--information'>
              <div className='information_items'>
                <div className='information_items-title'>
                  {getText('WORD_LANGUGE')}
                </div>
                <div className='information_items-desc'>
                  {getLongName(message.language)}
                </div>
              </div>
              <div className='separator' />
              <div className='information_items'>
                <div className='information_items-title'>{getText('WORD_NAME')}</div>
                <div className='information_items-desc'>
                  {receiver.fullName || '-'}
                </div>
              </div>
              <div className='separator' />
              <div className='information_items'>
                <div className='information_items-title'>
                  {getText('WORD_STATUS')}
                </div>
                <div className='information_items-desc'>{twilioLatestStatus}</div>
              </div>
            </div>
          )}
          <div className='center_message_more_items_wrapper--inner-block-body'>
            {infoValueList.map((item, index) => {
              return (
                <CenterMessageReviewInfoBlock
                  key={index}
                  icon={item.icon}
                  infoViewTitle={item.infoViewTitle}
                  bodyIcon={item.bodyIcon}
                  count={item.count}
                  noIcon={item.noIcon}
                  active={item.active}
                  style={item.style}
                  onClick={item.onClick}
                />
              )
            })}
          </div>
        </div>
        <div className='date'>{formatDateTime(message.createdAt)}</div>
      </div>
      {!isTabletPortraitOrMobile && (
        <ProfileLetters
          withoutIcon
          className='medium'
          name={
            message.initiating_user_name ||
            message._initiating_user_name ||
            initiatorPhone ||
            '+'
          }
          customeName={message.initiating_user_name === AI_BOT ? 'SQ' : false}
        />
      )}
    </motion.div>
  )
}

export default memo(CenterMessageReview)
