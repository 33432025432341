import React from 'react'
import { getText } from '../../lang'
import MatModal from '../MatModal'
import AIChatDetails from './AIChatDetails'

const AIChatDetailsModal = ({
  visible,
  setVisible,
  item,
  isCallerInfoClickable,
}) => {
  const customerName =
    item?.customerInfo?.fullName ||
    item?.nameSpecifiedByCustomer ||
    item?.customerInfo?.phone ||
    ''

  return (
    <MatModal
      noTrigger
      hideFooter
      visible={visible}
      onCancel={() => setVisible(false)}
      title={`${getText('CHAT_SESSION_WITH')} ${customerName}`}
      destroyOnClose={true}
      width={'100%'}
      rootClassName={'ai_chat_list_modal'}
    >
      <AIChatDetails item={item} isCallerInfoClickable={isCallerInfoClickable} />
    </MatModal>
  )
}

export default AIChatDetailsModal
