import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12914_442713)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_12914_442713)'>
          <circle cx='18' cy='17' r='13' fill='#2AD1B3' />
        </g>
        <path
          d='M14.9863 22.2333L17.053 23.8333C17.3197 24.1 17.9197 24.2333 18.3197 24.2333H20.853C21.653 24.2333 22.5197 23.6333 22.7197 22.8333L24.3197 17.9667C24.653 17.0333 24.053 16.2333 23.053 16.2333H20.3863C19.9863 16.2333 19.653 15.9 19.7197 15.4333L20.053 13.3C20.1863 12.7 19.7863 12.0333 19.1863 11.8333C18.653 11.6333 17.9863 11.9 17.7197 12.3L14.9863 16.3667'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M11.5859 22.2333V15.7C11.5859 14.7667 11.9859 14.4333 12.9193 14.4333H13.5859C14.5193 14.4333 14.9193 14.7667 14.9193 15.7V22.2333C14.9193 23.1667 14.5193 23.5 13.5859 23.5H12.9193C11.9859 23.5 11.5859 23.1667 11.5859 22.2333Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12914_442713)'
      />
      <defs>
        <filter
          id='filter0_f_12914_442713'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_12914_442713'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12914_442713'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_12914_442713'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashReviewInvitesCompletedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashReviewInvitesCompletedIcon
