import React, { memo } from 'react'
import { Card, Divider, Flex } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import { getText } from '../../../../lang'
import MyDefaultRow from '../../../../components/MyDefaultRow/MyDefaultRow'
import SVGDashCSIScore from '../../../../icons/SVG/SVGDashCSIScore'
import utilNumber from '../../../../utils/utilNumber'
import SVGNpsScoreInvite from '../../../../icons/SVG/SVGNpsScoreInvite'
import ReputationPulseCSIScoreChart from './ReputationPulseCSIScoreChart'
import './ReputationPulseCSIScore.scss'

const ReputationPulseCSIScore = ({ csi_score }) => {
  return (
    <Card className='dashboard_block'>
      <Div className={'dashboard_block--blurred-part pink'}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashCSIScore />
        <Div className='dashboard_block--header--right'>
          <Flex vertical>
            <span>{getText('WORD_CSI_SCORE')}</span>
            <span style={{ fontSize: 14 }}>
              {getText('WORD_REPUTATION_MANAGEMENT')}
            </span>
          </Flex>
        </Div>
      </Flex>
      <Flex align='center' gap={15}>
        <ReputationPulseCSIScoreChart csi_score={csi_score} />
        <Div className='reputation_pulse_pie_chart_data'>
          <MyDefaultRow
            leftSide={getText('WORD_PROMOTERS')}
            rightSide={csi_score?.promoter_percentage + '%'}
          />
          <MyDefaultRow
            leftSide={getText('WORD_PASSIVE')}
            rightSide={csi_score?.passive_percentage + '%'}
          />
          <MyDefaultRow
            leftSide={getText('WORD_DETRACTORS')}
            rightSide={csi_score?.detractor_percentage + '%'}
          />
          <Divider
            style={{
              borderBlockStart: '1px solid #38334D',
              width: '100%',
              margin: '16px 0 24px 0',
            }}
          />
          <MyDefaultRow
            leftSide={
              <Flex gap={5} align='center'>
                <SVGNpsScoreInvite style={{ display: 'flex' }} />
                {getText('TEXT_INVITES_SENT')}
              </Flex>
            }
            rightSide={utilNumber.numberFractions(csi_score?.total_count)}
          />
        </Div>
      </Flex>
    </Card>
  )
}

export default memo(ReputationPulseCSIScore)
