import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.99968 0.333496C3.31768 0.333496 0.333008 3.31816 0.333008 7.00016C0.333008 10.6822 3.31768 13.6668 6.99968 13.6668C10.6817 13.6668 13.6664 10.6822 13.6664 7.00016C13.6664 3.31816 10.6817 0.333496 6.99968 0.333496ZM9.66636 5.00016C9.83702 5.00016 10.015 5.0575 10.1457 5.1875C10.4057 5.44817 10.4057 5.88549 10.1457 6.14616L7.64568 8.62549C6.87835 9.39215 5.70569 9.2775 5.10369 8.37484L4.43702 7.37484C4.23302 7.06884 4.31834 6.64217 4.62501 6.4375C4.93101 6.2335 5.35768 6.31882 5.56235 6.62549L6.22901 7.62549C6.36368 7.82749 6.53636 7.8595 6.70769 7.6875L9.18703 5.1875C9.31769 5.0575 9.49569 5.00016 9.66636 5.00016Z'
      fill='#48AF80'
    />
  </svg>
)

const SVGCheckedSmallIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCheckedSmallIcon
