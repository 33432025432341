import React, { useState } from 'react'
import { Flex } from 'antd'
import { getText } from '../../../../../../lang'
import MatCheckbox from '../../../../../../components/MatCheckbox'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import MyDefaultButton from '../../../../../../components/MyDefaultButton/MyDefaultButton'

const Conditions = React.memo((props) => {
  const { onStateChange, onChangeStep, accepted } = props

  const [loading, setLoading] = useState(false)

  return (
    <BroadcastCampaignPage title={getText('TEXT_TERMS_AND_CONDITIONS')}>
      <div className='create-campaign-form-wrapper ant-form-vertical'>
        <MatCheckbox
          label={getText('TEXT_TITLE_OF_TERMS_AND_CONDITIONS')}
          onChange={(e) => {
            onStateChange('accepted', e.target.checked)
          }}
          value={accepted}
        />
        {
          <Flex style={{ marginTop: '32px' }} justify='center' gap={12}>
            <MyDefaultButton
              buttonText={getText('WORD_PREVIOUS')}
              htmlType={''}
              onClick={() => {
                onChangeStep(-1)
              }}
              typeButton={'cancel'}
              fullWidth
              maxWidth={200}
            />
            <MyDefaultButton
              loading={loading}
              buttonText={getText('ACTION_SCHEDULE')}
              htmlType={''}
              onClick={() => {
                setLoading(true)
                onChangeStep(1)
              }}
              disabled={!accepted}
              fullWidth
              maxWidth={200}
            />
          </Flex>
        }
      </div>
    </BroadcastCampaignPage>
  )
})

export default Conditions
