import React, { memo, useState } from 'react'
import { motion } from 'framer-motion'
import { Flex } from 'antd'
import { formatDateTime } from '../../../../../utils'
import { getText, getTextServerError } from '../../../../../lang'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import Div from '../../../../../components/Div/Div'
import { notifyError } from '../../../../../utils/Notify'
import './CenterAIBChatBlock.scss'
import SVGArrowUpRightIcon from '../../../../../icons/SVG/SVGArrowUpRightIcon'
import AIChatDetailsModal from '../../../../../components/AIChatDetails/AIChatDetailsModal'
import aiChatActions from '../../../../../store/modules/aiChatActions'

const LONG_TEXT_LENGTH = 150
const TEXT_LENGTH_AFTER_CUT = 100

const CenterAIBChatBlock = (props) => {
  const { message, index, icon, conversation } = props

  const messageBody = message?.body || ''

  const [isTextCut, setisTextCut] = useState(messageBody.length > LONG_TEXT_LENGTH)
  const [visible, setvisible] = useState(false)
  const [chatSessionDetails, setChatSessionDetails] = useState({})

  const fill = async () => {
    if (message?.additionalInfo?.chatSessionId) {
      const result = await aiChatActions.getChatSessionById(
        message?.additionalInfo?.chatSessionId
      )

      if (result.success) {
        setChatSessionDetails(result?.data?.response)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  return (
    <motion.div
      key={index}
      className='center_message_item_wrapper'
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <MyDefaultBlock>
        <Div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: 328,
            position: 'relative',
          }}
        >
          <Div className='icon-wrapper'>{icon}</Div>
          <Div className='center_message_item_block'>
            <div className='center_message_item_block--body-text'>
              <Flex justify='center' align='center' gap={8}>
                {getText('CHAT_CONVERSATION')}
              </Flex>
              <i
                dangerouslySetInnerHTML={{
                  __html: isTextCut
                    ? `${messageBody.slice(0, TEXT_LENGTH_AFTER_CUT).trimEnd()}...`
                    : messageBody,
                }}
              />
              {Boolean(messageBody?.length > LONG_TEXT_LENGTH) && (
                <span
                  style={{
                    color: 'var(--mainColor)',
                    textDecoration: 'underline',
                    fontSize: 12,
                    fontWeight: 700,
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    setisTextCut((ov) => !ov)
                  }}
                >
                  {getText(isTextCut ? 'TEXT_MORE_DETAILS' : 'TEXT_LESS_DETAILS')}
                </span>
              )}
            </div>
          </Div>
          <Div className='date'>{formatDateTime(message?.createdAt)}</Div>
          <SVGArrowUpRightIcon
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={() => {
              fill()
              setvisible(true)
            }}
          />
        </Div>
        {chatSessionDetails && (
          <AIChatDetailsModal
            visible={visible}
            setVisible={setvisible}
            item={{
              ...chatSessionDetails,
              customerInfo: {
                fullName: conversation?.receiver?.fullName,
                phone: conversation?.receiver?.phone,
              },
            }}
          />
        )}
      </MyDefaultBlock>
    </motion.div>
  )
}

export default memo(CenterAIBChatBlock)
