import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../../../lang'
import SVGAiBotInstalcon from '../../../../icons/SVG/SVGAiBotInstalcon'
import SVGAiBotFacebooklcon from '../../../../icons/SVG/SVGAiBotFacebooklcon'
import SVGAiBotEmailcon from '../../../../icons/SVG/SVGAiBotEmailcon'
import SVGSuperhumanSmsIcon from '../../../../icons/SVG/SVGSuperhumanSmsIcon'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import Div from '../../../../components/Div/Div'

const SuperhumanCommunicationChannels = () => {
  return (
    <Div className='superhuman_communication_channels_wrapper'>
      <MyDefaultBlockSubtitle
        subtitle={getText('TEXT_COMMUNICATION_CHANNELS')}
        color={'var(--black)'}
      />
      <Flex gap={16}>
        <MyDefaultSwitch
          isForm
          name={['channels', 'sms', 'enabled']}
          leftIcon
          icon={<SVGSuperhumanSmsIcon width={16} height={16} />}
          title={getText('WORD_SMS_UC')}
          noText
          noBorder
          titleStyle={{ fontSize: 14 }}
        />
        <MyDefaultSwitch
          isForm
          name={['channels', 'email', 'enabled']}
          leftIcon
          icon={<SVGAiBotEmailcon color={'#5B66EA'} width={16} height={16} />}
          title={getText('WORD_EMAIL')}
          noText
          noBorder
          titleStyle={{ fontSize: 14 }}
        />
      </Flex>
      <Flex gap={16}>
        <MyDefaultSwitch
          isForm
          name={['channels', 'facebook', 'enabled']}
          leftIcon
          icon={<SVGAiBotFacebooklcon width={16} height={16} />}
          title={getText('WORD_FB_MESSENGER')}
          noText
          noBorder
          titleStyle={{ fontSize: 14 }}
        />
        <MyDefaultSwitch
          isForm
          name={['channels', 'instagram', 'enabled']}
          leftIcon
          icon={<SVGAiBotInstalcon width={16} height={16} />}
          title={getText('WORD_IG_MESSENGER')}
          noText
          noBorder
          titleStyle={{ fontSize: 14 }}
        />
      </Flex>
    </Div>
  )
}

export default SuperhumanCommunicationChannels
