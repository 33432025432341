import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Light/Chat'>
      <g id='vuesax/linear/data'>
        <g id='data'>
          <path
            id='Vector'
            d='M18.3333 12.9327C19.4011 12.9327 20.2667 12.0671 20.2667 10.9993C20.2667 9.9316 19.4011 9.06602 18.3333 9.06602C17.2656 9.06602 16.4 9.9316 16.4 10.9993C16.4 12.0671 17.2656 12.9327 18.3333 12.9327Z'
            fill='#0E0E0E'
            stroke='#0E0E0E'
            strokeWidth='0.2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_2'
            d='M18.3333 5.50065C19.3459 5.50065 20.1667 4.67984 20.1667 3.66732C20.1667 2.6548 19.3459 1.83398 18.3333 1.83398C17.3208 1.83398 16.5 2.6548 16.5 3.66732C16.5 4.67984 17.3208 5.50065 18.3333 5.50065Z'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_3'
            d='M18.3333 20.1667C19.3459 20.1667 20.1667 19.3459 20.1667 18.3333C20.1667 17.3208 19.3459 16.5 18.3333 16.5C17.3208 16.5 16.5 17.3208 16.5 18.3333C16.5 19.3459 17.3208 20.1667 18.3333 20.1667Z'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M3.66683 12.8327C4.67935 12.8327 5.50016 12.0119 5.50016 10.9993C5.50016 9.98683 4.67935 9.16602 3.66683 9.16602C2.65431 9.16602 1.8335 9.98683 1.8335 10.9993C1.8335 12.0119 2.65431 12.8327 3.66683 12.8327Z'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_5'
            d='M5.5 11H16.5'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_6'
            d='M16.5002 3.66602H12.8335C11.0002 3.66602 10.0835 4.58268 10.0835 6.41602V15.5827C10.0835 17.416 11.0002 18.3327 12.8335 18.3327H16.5002'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGAccessControlIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAccessControlIcon
