import React, { memo } from 'react'
import { Card, Flex, Typography } from 'antd'
import { getText } from '../../../../../lang'
import Div from '../../../../../components/MatDiv/Div'
import SVGDashWalkBySuperhumanIcon from '../../../../../icons/SVG/SVGDashWalkBySuperhumanIcon'
import SVGDashWalkBySuperhumanCalendarIcon from '../../../../../icons/SVG/SVGDashWalkBySuperhumanCalendarIcon'
import DashboardInfoTooltip from '../../../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'
import utilNumber from '../../../../../utils/utilNumber'

const DashboardWalkBySuperhuman = ({ count, onClick }) => {
  return (
    <Card
      className={`dashboard_block dashboard_assisted_appointment ${count ? 'underline_animation' : ''}`}
      onClick={
        count
          ? () => {
              onClick && onClick()
            }
          : null
      }
    >
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashWalkBySuperhumanIcon />
        <Div className='dashboard_block--header--right'>
          <span className='underline_animation--text'>
            {getText('TEXT_INFLUENCED_VISITS_BY_SUPERHUMAN')}
            <DashboardInfoTooltip
              color={'white'}
              title={getText('TOOLTIP_SUPERHUMAN_AI_INFLUENCED_WALK_INS')}
            />
          </span>
        </Div>
      </Flex>
      <Typography>{utilNumber.numberFractions(count)}</Typography>
      <SVGDashWalkBySuperhumanCalendarIcon className={'calendar_icon'} />
    </Card>
  )
}

export default memo(DashboardWalkBySuperhuman)
