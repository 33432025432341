import React, { memo, useEffect, useRef, useState } from 'react'
import { Col, Divider, Flex, Row, Typography } from 'antd'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getText } from '../../../../lang'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import { MONTH_MAP } from '../../../../utils'
import SVGDashMessageTotalSentIcon from '../../../../icons/SVG/SVGDashMessageTotalSentIcon'
import './ReputationPulseStatistic.scss'

const ReputationPulseStatistic = ({
  leftNumber,
  chartData,
  className,
  rightNumber,
  responsiveChartHeight,
}) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const tooltipRef = useRef(null)
  const [cursorPositionX, setcursorPositionX] = useState(0)
  const [tooltipWidth, setTooltipWidth] = useState(0)
  const [firstLastPoint, setFirstLastPoint] = useState({
    isFirst: false,
    isLast: false,
  })

  const CustomTooltip = ({ active, payload, coordinate }) => {
    useEffect(() => {
      setcursorPositionX(coordinate.x)
    }, [coordinate.x])
    useEffect(() => {
      if (tooltipRef?.current) {
        setTooltipWidth(tooltipRef.current.offsetWidth)
      }
    }, [payload])

    if (active && payload && payload?.length) {
      const name = payload[0].payload.name
      return (
        <Div
          ref={tooltipRef}
          className={`chart_custom_tooltip ${isTabletPortraitOrMobile ? 'isTabletPortraitOrMobile' : ''}`}
        >
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='desc label'>
                {name?.length === 3 ? MONTH_MAP[name] : name}
              </p>
              {payload.map((item, index) => {
                return (
                  <Flex gap={4} align='center' key={index}>
                    <p className='desc label'>{item.name}:</p>
                    <p className='intro'>
                      {item.value}{' '}
                      {item.dataKey === 'reviewCompleted' && item.payload.percent
                        ? `(${item.payload.percent}%)`
                        : null}
                    </p>
                  </Flex>
                )
              })}
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  const barsData = [
    {
      name: getText('WORD_REVIEW_INVITES_SENT'),
      dataKey: 'reviewSent',
      fill: 'url(#reviewSent)',
      radius: [10, 10, 0, 0],
      condition: 'reviewSent',
    },
    {
      name: getText('WORD_REVIEW_INVITES_COMPLETED'),
      dataKey: 'reviewCompleted',
      fill: 'url(#reviewCompleted)',
      radius: [10, 10, 0, 0],
      condition: 'reviewCompleted',
    },
  ]

  return (
    <Div ref={tooltipRef} className={`dashboard_sent_statistics ${className || ''}`}>
      <Div className={'dashboard_sent_statistics-bg purple'}></Div>
      <Row className='dashboard_sent_statistics_headers'>
        <Col>
          <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
            <SVGDashMessageTotalSentIcon />
            <span>{getText('WORD_REVIEW_INVITES_SENT')}</span>
          </Flex>
          <Flex align='center' className='dashboard_sent_statistics--information'>
            <Typography level={1}>
              {utilNumber.numberFractions(leftNumber)}
            </Typography>
          </Flex>
        </Col>
        <Divider
          type='vertical'
          style={{
            borderInlineStart: '1px solid #38334D',
          }}
        />
        <Col>
          <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
            <SVGDashMessageTotalSentIcon />
            <span>{getText('WORD_REVIEW_INVITES_COMPLETED')}</span>
          </Flex>
          <Flex align='center' className='dashboard_sent_statistics--information'>
            <Typography level={1}>
              {utilNumber.numberFractions(rightNumber)}
            </Typography>
          </Flex>
        </Col>
      </Row>
      <ResponsiveContainer height={responsiveChartHeight}>
        <Flex
          className='dashboard_sent_statistics-chart-info'
          style={{ marginBottom: '-10px' }}
          justify='flex-end'
        >
          {getText('TEXT_LAST_SIX_MONTH')}
        </Flex>
        <BarChart
          width={500}
          height={400}
          data={chartData}
          margin={{
            top: 20,
          }}
          onMouseMove={(e) => {
            if (e?.activeCoordinate && chartData.length) {
              const isLast = e.activeLabel === chartData[chartData.length - 1].name
              const isFirst = e.activeLabel === chartData[0].name
              setFirstLastPoint({ isFirst, isLast })
            }
          }}
        >
          <defs>
            <linearGradient id='reviewSent' x1='0' y1='0' x2='0' y2='1'>
              <stop stopColor='#A9B0FF' />
              <stop offset='1' stopColor='#542BFA' />
            </linearGradient>
            <linearGradient id='reviewCompleted' x1='0' y1='1' x2='0' y2='0'>
              <stop stopColor='#433D7A' stopOpacity={0.8} />
              <stop offset='1' stopColor='#433D7A' stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <XAxis dataKey='name' tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray='' opacity={0.2} vertical={false} />
          <Tooltip
            radius={[10, 10, 0, 0]}
            cursor={{
              stroke: 'var(--secondaryTextColor)',
              opacity: 0.5,
              strokeWidth: 1,
              fill: 'transparent',
              radius: [10, 10, 0, 0],
            }}
            position={{
              y: -120,
              x:
                firstLastPoint.isLast && isTabletPortraitOrMobile
                  ? cursorPositionX - tooltipWidth / 2 - 50
                  : cursorPositionX - tooltipWidth / 2,
            }}
            content={<CustomTooltip />}
          />
          <Legend iconSize={8} iconType='circle' />
          {barsData.map((bar, index) => {
            return (
              <Bar
                key={index}
                name={bar.name}
                width={32}
                dataKey={bar.dataKey}
                fill={bar.fill}
                stackId='a'
                barSize={70}
                shape={({
                  dataKey,
                  radius,
                  reviewSent,
                  reviewCompleted,
                  ...props
                }) => {
                  const conditionsMet =
                    (reviewSent !== 0 && dataKey === 'reviewCompleted') ||
                    (reviewCompleted === 0 && dataKey === 'reviewSent') ||
                    (reviewSent === 0 && dataKey === 'reviewCompleted')

                  radius = conditionsMet ? [10, 10, 0, 0] : [0, 0, 0, 0]

                  return <Rectangle dataKey={dataKey} radius={radius} {...props} />
                }}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default memo(ReputationPulseStatistic)
