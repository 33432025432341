import React, { memo, useMemo, useState } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import messageActions from '../../../../store/modules/messageActions'
import { getText, getTextServerError } from '../../../../lang'
import { checkAustralianPhoneNumber } from '../../../../utils'
import MatForm from '../../../../components/Form/MatForm'
import MatModal from '../../../../components/MatModal'
import { notifyError } from '../../../../utils/Notify'
import InputFormPhone from '../../../../components/Form/InputFormPhone'
import {
  menuOpenSelector,
  organizationSelector,
} from '../../../../store/selectors/selectors'
import './MessageNewCustomer.scss'
import utilNumber from '../../../../utils/utilNumber'

const NewCustommerMessageModal = (props) => {
  const { trigger } = props

  let organization = useSelector(organizationSelector)
  const menuOpen = useSelector(menuOpenSelector)

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)

  const onFinish = async (values) => {
    setSaving(true)
    const result = await messageActions.apply(values.phone)
    setSaving(false)
    if (result.success) {
      result.data.conversation.isNew = result.data.isNew
      props.onSendSuccess && props.onSendSuccess(result.data.conversation)
      setVisible(false)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const CustomerModalContent = useMemo(() => {
    return (
      <MatModal
        title={getText('ACTION_NEW_MESSAGE')}
        trigger={trigger}
        confirmLoading={saving}
        formName='formSendPayment'
        visible={visible}
        onTrrigerClick={() => {
          setVisible(true)
          form.resetFields()
        }}
        onCancel={() => setVisible(false)}
        okText={getText('ACTION_SEND')}
      >
        <MatForm
          form={form}
          name='formSendPayment'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            phone: checkAustralianPhoneNumber(organization?.twilio_number),
          }}
        >
          <InputFormPhone
            formStyle={{ margin: 0 }}
            initialCountry={utilNumber.myParsePhoneNumber(
              organization?.twilio_number
            )}
            name='phone'
            label={getText('WORD_PHONE')}
            required
            onChange={(value) => {
              form.setFieldsValue({ phone: value })
            }}
          />
        </MatForm>
      </MatModal>
    )
  }, [visible, menuOpen])

  return CustomerModalContent
}

export default memo(NewCustommerMessageModal)
