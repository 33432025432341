import React from 'react'
import SVGMessagesAIReplyIcon from '../../../icons/SVG/SVGMessagesAIReplyIcon'
import MyDefaultButton from '../MyDefaultButton'
import { AI_REPLY_BUTTON } from '../../../devOptions'
import Div from '../../Div/Div'
import './MyDefaultAIReplyButton.scss'

const MyDefaultAIReplyButton = ({
  buttonText,
  onClick,
  disabled,
  style,
  tooltip,
  tooltipTitle,
  loading,
}) => {
  return (
    <Div className={'my_default_button_ai_reply_button_wrapper'}>
      <Div className={'my_default_button_ai_reply_button_wrapper--background'} />
      <MyDefaultButton
        className={'my_default_button_ai_reply_button'}
        style={style}
        typeButton={AI_REPLY_BUTTON}
        buttonText={buttonText}
        loading={loading}
        disabled={disabled}
        tooltip={tooltip}
        tooltipTitle={tooltipTitle}
        onClick={onClick}
        size={'small'}
        icon={
          <SVGMessagesAIReplyIcon
            color={disabled ? 'var(--black)' : 'var(--secondaryTextColor)'}
          />
        }
      />
    </Div>
  )
}

export default MyDefaultAIReplyButton
