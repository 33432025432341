import React, { memo, useMemo, useRef, useState } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Flex } from 'antd'
import Div from '../../../../components/Div/Div'
import DashboardInfoTooltip from '../../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'
import { getText } from '../../../../lang'
import SVGDashMarketingPerformance from '../../../../icons/SVG/SVGDashMarketingPerformance'
import { MONTH_MAP } from '../../../../utils'
import './DashboardMarketingStatistic.scss'

const Circle = ({ color }) => {
  return (
    <span
      style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color }}
    />
  )
}

const DashboardMarketingStatistic = ({
  tooltipTitle,
  responsiveChartHeight,
  data,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [isLastPoint, setIsLastPoint] = useState(false)

  const tooltipRef = useRef(null)

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <Div ref={tooltipRef} className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='title'>{getText('WORD_CURRENT_MONTH')}</p>
              {payload.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p
                      className='intro'
                      style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <Circle color={item.color} /> {item.value}
                    </p>
                  </React.Fragment>
                )
              })}
              <p className='desc label'>
                {label?.length === 3 ? MONTH_MAP[label] : label}
              </p>
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  const sumOfData = useMemo(() => {
    const obj = {
      influenced_visits: 0,
      leads: 0,
    }
    for (const item of data) {
      obj.influenced_visits += item.influenced_visits
      obj.leads += item.leads
    }
    return obj
  }, [data])

  const CustomizedLegend = useMemo(() => {
    return ({ payload }) => {
      if (payload?.length) {
        return (
          <Flex
            gap={12}
            align='center'
            justify='start'
            className='chart_custom_legend'
          >
            {payload.map((item, index) => {
              return (
                <Flex
                  key={index}
                  align='center'
                  gap={8}
                  className={`chart_custom_legend--items ${item.dataKey}`}
                >
                  <Circle color={item.color} />
                  <span>
                    {item.value} - {sumOfData?.[item.dataKey]}
                  </span>
                </Flex>
              )
            })}
          </Flex>
        )
      }
    }
  }, [sumOfData])

  return (
    <Div className={'dashboard_sent_statistics'}>
      <Div className={'dashboard_sent_statistics-bg electric_blue'}></Div>
      <Flex
        align='center'
        justify='space-between'
        gap={8}
        className={'dashboard_sent_statistics--header'}
      >
        <Flex align='center' gap={8}>
          <SVGDashMarketingPerformance />
          <span>
            {getText('WORD_MARKETING_PERFORMANCE')}
            {tooltipTitle && <DashboardInfoTooltip title={tooltipTitle} />}
          </span>
        </Flex>
      </Flex>
      <ResponsiveContainer height={responsiveChartHeight}>
        <Flex className='dashboard_sent_statistics-chart-info' justify='flex-end'>
          {getText('TEXT_LAST_SIX_MONTH')}
        </Flex>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          onMouseMove={(e) => {
            if (e?.activeCoordinate) {
              const isLast = e.activeLabel === data[data.length - 1].name
              setIsLastPoint(isLast)
              setTooltipPosition({
                x: e?.activeCoordinate.x,
                y: e?.activeCoordinate.y,
              })
            }
          }}
        >
          <defs>
            <linearGradient id='leadsColor' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#3F8CFF' stopOpacity={0.4} />
              <stop offset='95%' stopColor='#3F7AFF' stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey='name' tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray='' opacity={0.2} vertical={false} />
          <Tooltip
            content={<CustomTooltip />}
            position={{
              x: tooltipPosition.x - (isLastPoint ? 100 : 50),
              y: tooltipPosition.y - 100,
            }}
          />
          <Legend content={<CustomizedLegend />} />
          <Area
            name={getText('WORD_LEADS')}
            type='monotone'
            dataKey='leads'
            stroke='#F9BB06'
            fillOpacity={0}
            strokeWidth={2}
            dot={false}
          />
          <Area
            name={getText('WORD_INFLUENCED_VISITS')}
            type='monotone'
            dataKey='influenced_visits'
            stroke='#3F8CFF'
            fillOpacity={1}
            fill='url(#leadsColor)'
            strokeWidth={2}
            dot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default memo(DashboardMarketingStatistic)
