import React, { useEffect, useState, useRef, Suspense, lazy, useMemo } from 'react'
import querystring from 'querystring'
import { Drawer } from 'antd'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import messageActions from '../../../store/modules/messageActions'
import authActions from '../../../store/modules/authActions'
import {
  organizationSelector,
  userSelector,
  hasManyOrganizationsSelector,
  isSuperAdminSelector,
} from '../../../store/selectors/selectors'
import customerActions from '../../../store/modules/customerActions'
import locationActions from '../../../store/modules/locationActions'
import userActions from '../../../store/modules/userActions'
import { getText } from '../../../lang'
import { getInitialCountry, moveToLoginPage } from '../../../utils'
import SVGSearch from '../../../icons/SVG/SVGSearch'
import SVGDrawerManageLocationsPurple from '../../../icons/SVG/SVGDrawerManageLocationsPurple'
import { notifyError } from '../../../utils/Notify'
import SelectLocations from '../../../components/api/SelectLocations'
import SelectOrganization from '../../../components/api/SelectOrganization'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MatSelect from '../../../components/MatSelect'
import { useSocket } from '../../../utils/socket'
import { DEALER_SOCKET, ELEADS, VIN_SOLUTION } from '../../../config/constants'
import {
  ARCHIVED,
  MY_MESSAGES,
  PHONE,
  UNASSIGNED,
} from '../../messages/utils/constants'
import ListLoadMore from '../../../components/ListLoadMore'
import MessageLeftConversations from '../../messages/left/MessageConversations/MessageLeftConversations'
import Div from '../../../components/Div/Div'
import MyDefaultButton from '../../../components/MyDefaultButton/MyDefaultButton'
import MismatchBlock from './MismatchBlock/MismatchBlock'
import './ExtensionProfileInfo.scss'

const MessageRight = lazy(
  () => import('../../messages/right/MessageRightMain/MessageRight')
)

const MessageCenter = lazy(
  () => import('../../messages/center/MessageCenterMain/MessageCenter')
)

const extractSearchParams = (string) => {
  return querystring.parse(string.substr(1))
}

const ExtensionProfileInfo = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const user = useSelector(userSelector)
  const organization = useSelector(organizationSelector)
  const hasManyOrganizations = useSelector(hasManyOrganizationsSelector)
  const isSuperAdmin = useSelector(isSuperAdminSelector)

  const [loading, setloading] = useState(false)
  const [conversation, setConversation] = useState({})
  const [locationData, setLocationData] = useState({})
  const [msgChangeEmailSMSType, setmsgChangeEmailSMSType] = useState('')
  const [rightBoxVisible, setRightBoxVisible] = useState(false)
  const [listConversations, setlistConversations] = useState([])
  const [mismatchArray, setmismatchArray] = useState([])
  const [isCreateNewButtonActive, setisCreateNewButtonActive] = useState(false)

  const query = extractSearchParams(location.search)

  const organizationID = useMemo(
    () => (organization ? organization?._id : ''),
    [organization]
  )

  let MessageCenterRef = useRef()
  // let listRef = useRef()

  const handleSetMismatchFields = async (item) => {
    const arr = []
    if (
      (query?.first_name &&
        (!item?.receiver?.firstName ||
          item?.receiver?.firstName?.toUpperCase() !==
            query.first_name.toUpperCase())) ||
      (query?.last_name &&
        (!item?.receiver?.lastName ||
          item?.receiver?.lastName?.toUpperCase() !== query.last_name.toUpperCase()))
    ) {
      arr.push({
        type: 'fullName',
        name: getText('WORD_NAME'),
        oldValue: `${item?.receiver?.firstName || ''} ${item?.receiver?.lastName || ''}`,
        newValue: `${query?.first_name || ''} ${query?.last_name || ''}`,
        isAutoUpdate: !item?.receiver?.firstName || !item?.receiver?.lastName,
      })
    }

    if (
      query?.email &&
      (!item?.receiver?.email || item?.receiver?.email !== query.email)
    ) {
      arr.push({
        type: 'email',
        name: getText('WORD_EMAIL'),
        oldValue: item?.receiver?.email || '',
        newValue: query?.email || '',
        isAutoUpdate: !item?.receiver?.email,
      })
    }

    if (!item?.receiver?.phone && query?.phone_number) {
      arr.push({
        type: 'phone',
        name: getText('WORD_PHONE_NUMBER'),
        oldValue: '',
        newValue: query?.phone_number || '',
        isAutoUpdate: true,
      })
    }

    const obj = {}

    for (const field of arr) {
      if (field.isAutoUpdate) {
        obj[field.type] = field.newValue
      }
    }

    if (obj['fullName']) {
      obj['firstName'] = query?.first_name || ''
      obj['lastName'] = query?.last_name || ''
      delete obj['fullName']
    }

    let conv = item

    if (Object.keys(obj).length > 0) {
      const result = await customerActions.saveUpdate(obj, item._receiver_id)
      if (result.success) {
        conv = {
          ...item,
          receiver: {
            ...item.receiver,
            ...result.data,
          },
        }
      }
    }
    setConversation(conv)

    setmismatchArray(arr.filter((field) => !field.isAutoUpdate))
  }

  useEffect(() => {
    setloading(true)
    const { phone_number, email } = query
    if (phone_number || email) {
      const handleCreateNewConversationFromURL = async (phone_number) => {
        if (_.isEmpty(user)) {
          moveToLoginPage(navigate, false, {
            state: { goBack: true },
          })
        } else {
          let newPhone = phone_number
            ? phone_number.replace(/\s/g, '').replace(/[()]/g, '')
            : ''

          if (newPhone) {
            const orgCountryCode = getInitialCountry()

            if (
              !newPhone.startsWith('+1') &&
              (orgCountryCode === 'us' || orgCountryCode === 'ca')
            ) {
              newPhone = `+1${newPhone}`
            }
            newPhone = newPhone.replace('+', '')

            if (
              orgCountryCode === 'au' &&
              !newPhone.startsWith('61') &&
              !newPhone.startsWith('04')
            ) {
              newPhone = `04${newPhone}`
            }
            query.phone_number = newPhone
          }
          const result = await messageActions.filterByExtensionData({
            email: email,
            phone: newPhone,
            firstName: query?.first_name || '',
            lastName: query?.last_name || '',
          })

          if (result.success) {
            if (
              result?.data?.conversations?.length === 1 &&
              (query?.phone_number ||
                result.data.isNew ||
                !result.data.conversations[0]?.receiver?.phone)
            ) {
              // await messageActions.sendExtensionCRM(result.data.conversation._id)
              result.data.conversations[0].isNew = result.data.isNew

              const resultLocation = await locationActions.getSingle(
                user._location_id
              )
              if (resultLocation.success) {
                setLocationData(resultLocation.data)
              }
              if (result.data.isNew) {
                if (query.first_name) {
                  result.data.conversations[0].receiver.firstName = query.first_name
                  result.data.conversations[0].receiver.fullName = query.first_name
                }

                if (query.last_name) {
                  result.data.conversations[0].receiver.lastName = query.last_name
                  result.data.conversations[0].receiver.fullName +=
                    ' ' + query.last_name
                }
                if (query.source) {
                  result.data.conversations[0].receiver.source =
                    (query.source.toLowerCase() === DEALER_SOCKET.toLowerCase() &&
                      DEALER_SOCKET) ||
                    (query.source.toLowerCase() === ELEADS.toLowerCase() &&
                      ELEADS) ||
                    (query.source.toLowerCase() === VIN_SOLUTION.toLowerCase() &&
                      VIN_SOLUTION) ||
                    'other'
                }

                if (query.website) {
                  result.data.conversations[0].receiver.website = query.website
                }
              }

              if (result.data.isNew) {
                setConversation(result.data.conversations[0])
              } else {
                handleSetMismatchFields(result.data.conversations[0])
              }
            } else {
              if (query?.email && !query?.phone_number) {
                let shouldAllowCreateNewConv = true
                for (const conv of result.data.conversations) {
                  if (!conv?.receiver?.phone) {
                    shouldAllowCreateNewConv = false
                  }
                }
                setisCreateNewButtonActive(shouldAllowCreateNewConv)
              }
              setConversation({})
              setlistConversations(result.data.conversations)
            }
          }
          setloading(false)
        }
      }
      handleCreateNewConversationFromURL(phone_number)
    }
  }, [organization, user])

  const onReciveMessage = (event, data) => {
    if (user._organization_id !== data._organization_id) return
    let type_folder
    if (conversation.unassigned) {
      type_folder = MY_MESSAGES
    } else {
      type_folder = UNASSIGNED
    }
    let selected = conversation?._id === data.conversation._id

    if (selected) {
      switch (event) {
        case 'ASSIGN_CONVERSATION_USER':
          if (!data.conversation.users.includes(user?._id)) return
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(data.conversation.messages[0])
          }
          setConversation(data.conversation)
          return
        case 'UNASSIGN_CONVERSATION_USER':
        case 'ARCHIVE_CONVERSATION':
        case 'UPDATE_CONVERSATION':
        case 'UNARCHIVE_CONVERSATION':
          setConversation(data.conversation)
          return
        case 'SCHEDULED_MESSAGE_REMOVED':
          data.newInsertedMsg.isScheduled = true
          MessageCenterRef.current.removeScheduledMessage(data.newInsertedMsg)
          return
        case 'NEW_NOTE_APPEAR':
          data.conversation.notes[0]._conversation_id = data.conversation._id
          data.conversation.notes[0].type = 'note'
          data.conversation.notes[0].body = data.conversation.notes[0].text
          data.conversation.notes[0].whoSentThis_name =
            data.conversation.notes[0].user_name
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(data.conversation.notes[0])
          }
          setConversation(data.conversation)
          return
        case 'SCHEDULED_MESSAGE_CREATED':
          let newMessage = data.newInsertedMsg
          newMessage._conversation_id = conversation._id
          newMessage.isScheduled = true
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(newMessage)
          }
          return
        default:
      }
      if (event === 'MARK_READ_CONVERSATION') {
        return refreshConversation(data.conversation, false, false)
      }
      if (
        [MY_MESSAGES].includes(type_folder) &&
        !data.conversation.users.includes(user?._id)
      )
        return
    }
  }

  const onReciveMessageNew = (event, data) => {
    try {
      if (data && data.conversation) {
        let isToMe = false
        if (user._organization_id === data._organization_id) {
          isToMe = true
        }
        if (isToMe) {
          if (
            data.conversation._id === conversation._id ||
            data.conversation.receiver_phone === conversation.receiver_phone
          ) {
            if (event === 'NEW_INVITATION_AVAILABLE') {
              data.newInsertedInv.isInvitation = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.newInsertedInv)
              }
            } else if (event === 'VIDEO_MESSAGE_ADDED') {
              data.video._conversation_id = data.conversation._id
              data.video.video = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.video)
              }
            } else if (event === 'VIDEO_MESSAGE_CHANGED') {
              if (data.video.status === 'VIDEO_PROCESSING_FAILED') {
                notifyError(
                  getText(
                    'MSG_VIDEO_PROCESSING_FAILED_IN_ORDER_TO_RETRY_UPLOADING_IT'
                  )
                )
              }
              data.video._conversation_id = data.conversation._id
              data.video.video = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.video)
              }
            } else if (event === 'MESSAGE_STATUS_WAS_CHANGED') {
              MessageCenterRef.current.updateSmsStatus(
                data.newInsertedMsg.smsStatus,
                data.newInsertedMsg.id
              )
            } else if (event === 'NEW_MESSAGE_AVAILABLE') {
              let newMessage = data.newInsertedMsg
              newMessage._conversation_id = conversation._id
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(newMessage)
              }
              setConversation(data.conversation)
            } else if (event === 'NEXT_SCHEDULED_MESSAGES_UPDATED') {
              let list = []
              if (Array.isArray(data.nextScheduledMessages.dripSequence)) {
                list = list.concat(data.nextScheduledMessages.dripSequence)
              }
              if (data.nextScheduledMessages.superhuman) {
                list.push(data.nextScheduledMessages.superhuman)
              }
              if (data.nextScheduledMessages.followUp) {
                data.nextScheduledMessages.followUp.type =
                  data.nextScheduledMessages.followUp.status
                list.push(data.nextScheduledMessages.followUp)
              }
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNextScheduledMessage(list)
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    ['INVITATION_WAS_CHANGED'],
    onReciveMessageNew,
    organizationID &&
      (query.phone_number !== '' || query.email) &&
      !_.isEmpty(user) &&
      !!conversation._id
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['NEW_INVITATION_AVAILABLE', 'VIDEO_MESSAGE_ADDED', 'NEW_MESSAGE_AVAILABLE'],
    onReciveMessageNew,
    organizationID && (query.phone_number !== '' || query.email) && !_.isEmpty(user)
  )

  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    [
      'MESSAGE_STATUS_WAS_CHANGED',
      'VIDEO_MESSAGE_CHANGED',
      'NEW_CONVERSATION_SUMMARY',
      'NEXT_SCHEDULED_MESSAGES_UPDATED',
    ],
    onReciveMessageNew,
    organizationID &&
      conversation._id &&
      (query.phone_number !== '' || query.email) &&
      !_.isEmpty(user)
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['MARK_READ_CONVERSATION', 'ARCHIVE_CONVERSATION', 'UNARCHIVE_CONVERSATION'],
    onReciveMessage,
    organizationID
  )
  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    [
      'UPDATE_CUSTOMER',
      'CONSENT_CHANGED',
      'PHONE_STATUS_CHANGED',
      'UPDATE_CONVERSATION',
      'ASSIGN_CONVERSATION_USER',
      'UNASSIGN_CONVERSATION_USER',
      'NEW_NOTE_APPEAR',
      'SCHEDULED_MESSAGE_CREATED',
      'SCHEDULED_MESSAGE_UPDATED',
      'SCHEDULED_MESSAGE_REMOVED',
      'CONVERSATION_REMINDER_CREATED',
      'CONVERSATION_REMINDER_CANCELLED',
      'CONVERSATION_REMINDER_NUDGED',
      'CONVERSATION_INTENTS_UPDATED',
      'CUSTOMER_STATUS_WAS_CHANGED',
    ],
    onReciveMessage,
    !!organizationID && !!conversation._id
  )

  useSocket(
    `private-organization=${organizationID}`,
    ['APPOINTMENT_CREATED'],
    onReciveMessage,
    !!organizationID
  )

  const onChangeEmailSMSType = (type) => {
    setmsgChangeEmailSMSType(type)
  }

  const refreshReceiverOrTagsOnConversation = (prop, value) => {
    if (conversation) {
      const newConversation = _.cloneDeep(conversation)
      newConversation[prop] = value
      setConversation(newConversation)
    }
  }
  const refreshConversation = (conv) => {
    setConversation(conv)
  }

  if (query.phone_number === '') {
    return
  }

  return loading ? (
    <LoadingSpinner />
  ) : _.isEmpty(conversation) && !listConversations?.length ? (
    <LoadingSpinner />
  ) : (
    <Suspense fallback={<div>Loading</div>}>
      <div className='extension-profile-info-wrapper'>
        <div className='extension_location_wrapper'>
          <div className='extension_user_location'>
            <SelectOrganization
              primary
              showSearch={isSuperAdmin || hasManyOrganizations}
              disableOpen={!(isSuperAdmin || hasManyOrganizations)}
              disableSuffix={!(isSuperAdmin || hasManyOrganizations)}
            />
          </div>

          {userActions.getUserHasPermission(
            user,
            organization?.restrictions?.allowAccessToAllLocations
          ) && locationData ? (
            <div className='extension_user_location'>
              <SelectLocations
                primary
                dropdownClassName={`mat-header-dropdown-responsive-second`}
                location={locationData}
                onSelectItem={async (item) => {
                  setLocationData(item)
                  await authActions.setLocation(item)
                }}
              />
            </div>
          ) : (
            <div className='extension_user_location'>
              <MatSelect
                icon={<SVGDrawerManageLocationsPurple />}
                options={[
                  {
                    label: locationData.name,
                    value: locationData._id,
                  },
                ]}
                primary
                dropdownClassName={`mat-header-dropdown-responsive-second 
                          }`}
                value={locationData._id}
              />
            </div>
          )}
        </div>

        {Boolean(listConversations.length) && (
          <section
            className='messages_left_conversations_list'
            // ref={scrollConversation}
          >
            <Div className='extension_email_results_block'>
              <SVGSearch />
              <Div>
                We found {listConversations.length} matches:{' '}
                <span>{query?.email || query.phone_number || ''}</span>
              </Div>
            </Div>
            <ListLoadMore
              // loading={isLoading}
              data={listConversations}
              totalDataCount={listConversations.length}
              onLoadMore={() => {}}
              footer={
                Boolean(isCreateNewButtonActive) && (
                  <MyDefaultButton
                    fullWidth
                    buttonText={'Create a new conversation'}
                    onClick={() => {
                      const obj = {
                        isNew: true,
                        unsubscribed: false,
                        source: [],
                        users: [],
                        is_archived: false,
                        is_unread: false,
                        recent_tagged_users: [],
                        messages: [],
                        invitations: [],
                        tags: [],
                        notes: [],
                        receiver: {
                          status: {
                            state: 'yellow',
                            consentSent: false,
                            consentSentDate: null,
                            activatedAt: null,
                            email: {
                              state: 'yellow',
                              consentSent: false,
                              consentSentDate: null,
                              activatedAt: null,
                              unsubscribedAt: null,
                            },
                          },
                          firstName: query?.first_name || '',
                          lastName: query?.last_name || '',
                          phone: '',
                          email: query?.email || '',
                          _organization_id: organization?._id,
                          fullName: `${query?.first_name || ''} ${query?.last_name || ''}`,
                        },
                      }
                      setlistConversations([])
                      setConversation(obj)
                    }}
                  />
                )
              }
              renderItem={(item, index) => {
                return (
                  <MessageLeftConversations
                    key={`${item._id}_#_${index}`}
                    item={item}
                    showDetails
                    selectedConversation={conversation}
                    // selectMultipleConversationsType={selectMultipleConversationsType}
                    messageType={conversation?.is_archived ? ARCHIVED : MY_MESSAGES}
                    onClick={() => {
                      setlistConversations([])
                      handleSetMismatchFields(item)
                    }}
                  />
                )
              }}
            />
          </section>
        )}

        {Boolean(conversation) && !_.isEmpty(conversation) && (
          <React.Fragment>
            <div className='message_right_panel'>
              <Drawer
                placement='right'
                closable={false}
                onClose={() => setRightBoxVisible(false)}
                open={rightBoxVisible}
                width={312}
                rootClassName='message_right_panel-drawer message_right_panel-drawer-extension'
              >
                <section className='message_right_panel'>
                  <MessageRight
                    conversation={conversation}
                    onSaveReciver={(value) =>
                      refreshReceiverOrTagsOnConversation('receiver', value)
                    }
                    onSaveTags={(value) =>
                      refreshReceiverOrTagsOnConversation('tags_items', value)
                    }
                    refreshConversation={refreshConversation}
                    msgChangeEmailSMSType={msgChangeEmailSMSType}
                    onCloseDrawer={() => setRightBoxVisible(false)}
                  />
                </section>
              </Drawer>
            </div>
            <div className='message_center_panel'>
              <MessageCenter
                changeMessageType={PHONE}
                showRightBox={() => setRightBoxVisible(true)}
                messageType={conversation.is_archived ? ARCHIVED : MY_MESSAGES}
                conversation={conversation}
                disabled={false}
                refreshTags={(value) =>
                  refreshReceiverOrTagsOnConversation('tags_items', value)
                }
                extensionQuery={query}
                refreshConversation={refreshConversation}
                ref={MessageCenterRef}
                onChangeEmailSMSType={onChangeEmailSMSType}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                isBlueExtension={true}
                additionalHeader={
                  <MismatchBlock
                    mismatchArray={mismatchArray}
                    onClear={() => {
                      setmismatchArray([])
                    }}
                    query={query}
                    conversation={conversation}
                    onUpdateSuccess={(obj) => {
                      setmismatchArray([])
                      setConversation({
                        ...conversation,
                        receiver: {
                          ...conversation.receiver,
                          ...obj,
                        },
                      })
                    }}
                  />
                }
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </Suspense>
  )
}

export default ExtensionProfileInfo
