import React, { useState } from 'react'
import { Flex, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import customerActions from '../../../../store/modules/customerActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import SVGAiBotSmsIcon from '../../../../icons/SVG/SVGAiBotSmsIcon'
import SVGAiBotEmailcon from '../../../../icons/SVG/SVGAiBotEmailcon'
import SVGChecked from '../../../../icons/SVG/SVGChecked'
import SVGAiBotInstalcon from '../../../../icons/SVG/SVGAiBotInstalcon'
import SVGAiBotBusinesslcon from '../../../../icons/SVG/SVGAiBotBusinesslcon'
import SVGAiBotFacebooklcon from '../../../../icons/SVG/SVGAiBotFacebooklcon'
import SVGSuperhuman from '../../../../icons/SVG/SVGSuperhuman'
import SVGFollowUpIcon from '../../../../icons/SVG/SVGFollowUpIcon'
import SVGDrawerSequence from '../../../../icons/SVG/SVGDrawerSequence'
import { formatDateTime, MainColor } from '../../../../utils'
import userActions from '../../../../store/modules/userActions'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultTagBlock from '../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import { EMAIL, SUPERHUMAN } from '../../../../devOptions'
import messageActions from '../../../../store/modules/messageActions'
import Div from '../../../../components/Div/Div'
import { FOLLOW_UP } from '../../utils/constants'
import './MessageDripSequencePanel.scss'
import { userSelector } from '../../../../store/selectors/selectors'

const disabledList = [SUPERHUMAN, FOLLOW_UP]

const SequenceSwitchItem = ({
  enable_auto_reply,
  is_engaged,
  disabled,
  organization,
  handleOnChange,
  type,
  saving,
  showItem,
  icon,
  title,
  color,
  isLast,
  conversation,
  user,
}) => {
  const superhumanSnoozedEnabled =
    organization?.restrictions?.allowSuperhumanActionInConversation?.enabled ||
    user?.isAdmin ||
    user?.isSuperAdmin

  return (
    showItem && (
      <MyDefaultSwitch
        noText
        isLast={isLast}
        icon={
          <Flex
            align='center'
            justify='center'
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: color,
            }}
          >
            {icon}
          </Flex>
        }
        leftIcon
        innerStyle={{ gap: 6 }}
        titleStyle={{ fontSize: 14, fontWeight: 600 }}
        title={
          <Flex align='center'>
            {title}
            {is_engaged && (
              <MyDefaultTagBlock
                backgroundColor='var(--greenLight)'
                titleColor={'var(--green)'}
                title={getText('WORD_ENGAGED')}
              />
            )}
            {Boolean(
              conversation?.receiver?.superhumanConfig?.snoozedUntil &&
                new Date(conversation?.receiver?.superhumanConfig?.snoozedUntil) >
                  new Date()
            ) &&
              superhumanSnoozedEnabled &&
              type === SUPERHUMAN && (
                <Tooltip
                  title={
                    <Flex vertical justify='center' style={{ textAlign: 'center' }}>
                      {`${getText('TEXT_SUPERHUMAN_WILL_BE_REENGAGED_CONVERSATION_AT')} ${formatDateTime(conversation?.receiver?.superhumanConfig?.snoozedUntil)}`}
                      {Boolean(
                        userActions.getUserHasPermission(
                          user,
                          organization?.restrictions
                            ?.allowSuperhumanActionInConversation
                        )
                      ) && (
                        <span
                          style={{
                            color: 'var(--red)',
                            fontSize: 12,
                            fontWeight: 500,
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            const result =
                              await messageActions.snoozeSuperhumanAction(
                                conversation._id,
                                false
                              )
                            if (!result) {
                              notifyError(getTextServerError(result.errMsg))
                            }
                          }}
                        >
                          {getText('WORD_REVERT_DECISION')}
                        </span>
                      )}
                    </Flex>
                  }
                >
                  <MyDefaultTagBlock
                    backgroundColor='var(--orangeLight)'
                    titleColor={'var(--yellow)'}
                    title={getText('WORD_SNOOZED')}
                  />
                  <Div />
                </Tooltip>
              )}
          </Flex>
        }
        loading={saving}
        value={enable_auto_reply}
        onChangeSwitch={(value) => {
          handleOnChange(value, type)
        }}
        disabled={
          disabledList.includes(type)
            ? disabled
            : disabled ||
              (organization?.enable_auto_reply ? !enable_auto_reply : true)
        }
      />
    )
  )
}

const AiBotSwitchPanel = ({ conversation, onSaveReciver, disabled, isNylas }) => {
  const user = useSelector(userSelector)
  const organization = user?.organization

  const [saving, setsaving] = useState(false)
  const [savingsuperhuman, setsavingsuperhuman] = useState(false)
  const [followUpSaving, setFollowUpSaving] = useState(false)

  const receiver = conversation.receiver ? conversation.receiver : {}
  const receiver_id = receiver._id

  const disabledForUsersRoles =
    !user?.isAdmin &&
    !user?.isSuperAdmin &&
    userActions.getUserHasPermission(
      user,
      organization?.restrictions?.restrictDisableAIBot
    )

  const disabledForUsersSuperhuman =
    !user?.isAdmin &&
    !user?.isSuperAdmin &&
    userActions.getUserHasPermission(
      user,
      organization?.restrictions?.restrictEditSuperHuman
    )

  const enable_auto_reply_sms = receiver?.autoReply?.sms?.enabled
  const enable_auto_reply_email = receiver?.autoReply?.email?.enabled
  const enable_auto_reply_instagram = receiver?.autoReply?.instagram?.enabled
  const enable_auto_reply_gbm = receiver?.autoReply?.gbm?.enabled
  const enable_auto_reply_facebook = receiver?.autoReply?.facebook?.enabled
  const enable_auto_reply_superhuman = receiver?.superhumanConfig?.enabled
  const enable_follow_up = receiver?.followUpConfig?.enabled

  const is_engaged_sms = conversation?.isNew
    ? receiver?.engaged?.sms?.default
    : receiver?.engaged?.sms
  const is_engaged_email = conversation?.isNew
    ? receiver?.engaged?.email?.default
    : receiver?.engaged?.email
  const is_engaged_instagram = receiver?.engaged?.instagram
  const is_engaged_gbm = receiver?.engaged?.gbm
  const is_engaged_facebook = receiver?.engaged?.facebook

  const handleOnChangeSuperhuman = async (value) => {
    if (Boolean(receiver_id)) {
      setsavingsuperhuman(true)
      const result = await customerActions.setSuperhumanToggle(
        { enabled: value },
        receiver_id
      )
      if (result.success) {
        notifySuccess(
          getText(value ? 'TEXT_SUPERHUMAN_ENABLED' : 'TEXT_SUPERHUMAN_DISABLED')
        )
        onSaveReciver &&
          onSaveReciver(
            Object.assign({}, receiver, { superhumanConfig: { enabled: value } })
          )
      } else {
        notifyError(result.errMsg)
      }
    }
    setsavingsuperhuman(false)
  }

  const handleOnChangeFollowUp = async (value) => {
    if (Boolean(receiver_id)) {
      setFollowUpSaving(true)
      const result = await customerActions.setFollowUpUpdate(
        { enabled: value },
        receiver_id
      )
      if (result.success) {
        notifySuccess(
          getText(value ? 'TEXT_FOLLOW_UP_ENABLED' : 'TEXT_FOLLOW_UP_DISABLED')
        )
        onSaveReciver &&
          onSaveReciver(
            Object.assign({}, receiver, { followUpConfig: { enabled: value } })
          )
      } else {
        notifyError(result.errMsg)
      }
    }
    setFollowUpSaving(false)
  }

  const handleOnChange = async (value, type) => {
    if (Boolean(receiver_id)) {
      if (!value) {
        const sms = type === 'sms' ? !enable_auto_reply_sms : enable_auto_reply_sms
        const email =
          type === 'email' ? !enable_auto_reply_email : enable_auto_reply_email
        const instagram =
          type === 'instagram'
            ? !enable_auto_reply_instagram
            : enable_auto_reply_instagram
        const facebook =
          type === 'facebook'
            ? !enable_auto_reply_facebook
            : enable_auto_reply_facebook
        const obj = {
          sms: { enabled: sms },
          email: { enabled: email },
          instagram: { enabled: instagram },
          facebook: { enabled: facebook },
        }
        setsaving(true)
        const result = await customerActions.setAutoReply(
          { autoReply: obj },
          receiver_id
        )
        setsaving(false)
        if (result.success) {
          if (type === 'sms') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_SMS')} ${sms ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'email') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_EMAIL')} ${email ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'instagram') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_INSTAGRAM')} ${instagram ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'facebook') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_FACEBOOK')} ${facebook ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          }
          onSaveReciver && onSaveReciver(result.data)
        } else {
          notifyError(result.errMsg)
        }
      }
    } else {
      notifyError(getText('ERR_GENERAL'))
    }
  }

  const superhumanChannel = organization?.superhumanConfig?.channels

  const getAiBotItem = () => {
    const obj = {
      icon: <SVGAiBotSmsIcon />,
      title: getText('WORD_DRIP_SEQUENCE_SMS'),
      color: 'var(--greenLight)',
      enable_auto_reply: enable_auto_reply_sms,
      is_engaged: is_engaged_sms,
      type: 'sms',
    }
    if (conversation.last_source === 'IG messenger') {
      obj.icon = <SVGAiBotInstalcon />
      obj.title = getText('WORD_IG_MESSENGER')
      obj.color = 'var(--orangeLight)'
      obj.enable_auto_reply = enable_auto_reply_instagram
      obj.is_engaged = is_engaged_instagram
      obj.type = 'instagram'
    } else if (conversation.kind === 'gbm') {
      obj.icon = <SVGAiBotBusinesslcon />
      obj.title = getText('WORD_GOOGLE_BUSINESS_UC')
      obj.color = 'var(--blueLighter)'
      obj.enable_auto_reply = enable_auto_reply_gbm
      obj.is_engaged = is_engaged_gbm
      obj.type = 'gbm'
    } else if (conversation.last_source === 'FB messenger') {
      obj.icon = <SVGAiBotFacebooklcon />
      obj.title = getText('WORD_FB_MESSENGER')
      obj.color = 'var(--blueLighter)'
      obj.enable_auto_reply = enable_auto_reply_facebook
      obj.is_engaged = is_engaged_facebook
      obj.type = 'facebook'
    }
    return obj
  }

  const isAiBotTriggeredEver =
    superhumanChannel &&
    (superhumanChannel?.email?.enabled ||
      superhumanChannel?.facebook?.enabled ||
      superhumanChannel?.gbm?.enabled ||
      superhumanChannel?.instagram?.enabled ||
      superhumanChannel?.sms?.enabled)

  const isAIReplyEnabledSuperHuman = organization?.superhumanConfig?.enabled

  const hasEmail = Boolean(receiver?.email && isNylas?.email_address)

  const hideSuperhumanToggle = () => {
    if (conversation?.kind === 'gbm' && !superhumanChannel?.gbm?.enabled) {
      return true
    } else if (
      conversation?.last_source === 'IG messenger' &&
      !superhumanChannel?.instagram?.enabled
    ) {
      return true
    } else if (
      conversation?.last_source === 'FB messenger' &&
      !superhumanChannel?.facebook?.enabled
    ) {
      return true
    } else if (conversation?.kind === 'sms') {
      if (!superhumanChannel?.email?.enabled && !superhumanChannel?.sms?.enabled) {
        return true
      }
      if (
        hasEmail &&
        !superhumanChannel?.email?.enabled &&
        !superhumanChannel?.sms?.enabled
      ) {
        return true
      }
      if (!superhumanChannel?.sms?.enabled && !hasEmail) {
        return true
      }
    }
    return false
  }

  const hideSuperhuman = hideSuperhumanToggle()
  const switchItemProps = getAiBotItem()

  return (
    <Div className='message_right_panel--top-side-item'>
      <React.Fragment>
        <Div className='block_right_and_left_sides'>
          <Div className='block_right_and_left_sides--left-side'>
            <Div className={'block_right_and_left_sides--left-side-icon'}>
              <SVGDrawerSequence color={MainColor} width={16} height={16} />
            </Div>
            <Div className='block_right_and_left_sides--left-side-label'>
              <span className={'not_active'}>{getText('WORD_SEQUENCES')}</span>
            </Div>
          </Div>
          {conversation && conversation.lastSequence && (
            <Tooltip placement='topRight' title={conversation.lastSequence.name}>
              <Div className='after_hour_sequence_ai_bot'>
                <SVGChecked />
                <span className='title'>{conversation.lastSequence.name}</span>
              </Div>
            </Tooltip>
          )}
        </Div>
        <SequenceSwitchItem
          enable_auto_reply={switchItemProps.enable_auto_reply}
          is_engaged={switchItemProps.is_engaged}
          disabled={disabled || disabledForUsersRoles}
          organization={organization}
          handleOnChange={handleOnChange}
          type={switchItemProps.type}
          saving={saving}
          showItem={true}
          isLast={!hasEmail && hideSuperhuman}
          icon={switchItemProps.icon}
          title={switchItemProps.title}
          color={switchItemProps.color}
        />
        <SequenceSwitchItem
          enable_auto_reply={enable_auto_reply_email}
          is_engaged={is_engaged_email}
          disabled={disabled || disabledForUsersRoles}
          organization={organization}
          handleOnChange={handleOnChange}
          type={EMAIL}
          saving={saving}
          showItem={hasEmail}
          isLast={hasEmail && hideSuperhuman}
          icon={<SVGAiBotEmailcon />}
          title={getText('WORD_DRIP_SEQUENCE_EMAIL')}
          color={'var(--orangeLight)'}
        />
      </React.Fragment>
      {isAIReplyEnabledSuperHuman && isAiBotTriggeredEver ? (
        <SequenceSwitchItem
          enable_auto_reply={enable_auto_reply_superhuman}
          disabled={
            disabled ||
            disabledForUsersSuperhuman ||
            !organization?.superhumanConfig?.enabled
          }
          organization={organization}
          handleOnChange={handleOnChangeSuperhuman}
          type={SUPERHUMAN}
          saving={savingsuperhuman}
          showItem={!hideSuperhuman}
          isLast={!Boolean(organization?.restrictions?.allowFollowUp)}
          icon={<SVGSuperhuman />}
          title={getText('TEXT_AI_BOT_SUPERHUMAN')}
          color={'var(--blueLighter)'}
          conversation={conversation}
          user={user}
        />
      ) : null}
      {Boolean(organization?.restrictions?.allowFollowUp) && (
        <SequenceSwitchItem
          enable_auto_reply={enable_follow_up}
          disabled={disabled}
          organization={organization}
          handleOnChange={handleOnChangeFollowUp}
          type={FOLLOW_UP}
          saving={followUpSaving}
          showItem={true}
          isLast={true}
          icon={<SVGFollowUpIcon />}
          title={getText('WORD_AI_FOLLOW_UP')}
          color={'var(--blueLighter)'}
          conversation={conversation}
          user={user}
        />
      )}
    </Div>
  )
}

export default AiBotSwitchPanel
