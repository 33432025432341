import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import SVGBroadcastingIcon from '../../../../../icons/SVG/SVGBroadcastingIcon'
import {
  checkFormatPhoneNumber,
  formatDateTime,
  getCustomerName,
  MainColor,
  myWebViewPost,
} from '../../../../../utils'
import SVGBot from '../../../../../icons/SVG/SVGBot'
import SVGSendMessageCheckedIcon from '../../../../../icons/SVG/SVGSendMessageCheckedIcon'
import { getText } from '../../../../../lang'
import CenterRenderImage from '../CenterRenderImage'
import MatButton from '../../../../../components/MatButton'
import {
  MESSAGE_BLOCK_ANIMATIONS,
  PROFILE_LETTERS_ANIMATION,
} from '../../../utils/messageUtils'
import {
  AI_BOT,
  AI_REPLY,
  BOT,
  CONSENT,
  FOLLOW_UP,
  RECEIVED,
  REMINDER_BOT,
  SENT,
  SEQUENCE,
  SUPERHUMAN_CHAT_RESPONSE,
  VOICE_AI_RESPONSE,
} from '../../../utils/constants'
import SVGDrawerSequence from '../../../../../icons/SVG/SVGDrawerSequence'
import MessageMarkAsReadButton from '../../centerMessageHeader/MessageMarkAsReadButton/MessageMarkAsReadButton'
import { isMobileAppSelector } from '../../../../../store/selectors/selectors'
import ProfileLetters from '../../../../../components/ProfileLetters'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

// const deviceSize = [
//   'mobile',
//   'tabletPortrait',
//   'tablet',
//   'tabletLandscape',
//   'messageRightDrawer',
// ]
const messageType = [
  SENT,
  CONSENT,
  BOT,
  AI_REPLY,
  REMINDER_BOT,
  FOLLOW_UP,
  VOICE_AI_RESPONSE,
  SUPERHUMAN_CHAT_RESPONSE,
]

const getPosterName = (type, whoSentThis, receiverName) => {
  let returnName = receiverName

  if (messageType.includes(type)) {
    if (whoSentThis === 'Consent' || (whoSentThis === AI_BOT && type === CONSENT)) {
      returnName = getText('WORD_CONSENT')
    } else if (
      (whoSentThis === AI_BOT || whoSentThis === SEQUENCE) &&
      type === 'bot'
    ) {
      returnName = getText('WORD_SEQUENCE')
    } else if (whoSentThis === 'Broadcast') {
      returnName = getText('WORD_BROADCAST_UPPER')
    } else if (type === FOLLOW_UP) {
      returnName = getText('WORD_AI_FOLLOW_UP')
    } else if (type === VOICE_AI_RESPONSE) {
      returnName = getText('WORD_VOICE_AI')
    } else if (type === SUPERHUMAN_CHAT_RESPONSE) {
      returnName = getText('SUPERHUMAN_CHAT')
    } else {
      returnName = whoSentThis
    }
  }

  return returnName
}

const getMessageBody = (message) => {
  if (message.is_deleted) {
    message.body = getText('TEXT_MESSAGE_WAS_DELETED_BY_USER')
  }
  return message.body
}

const CenterOther = (props) => {
  const {
    conversation,
    message,
    index,
    getIconSource,
    messageListType,
    lastNotSentMessage,
  } = props

  const isMobileApp = useSelector(isMobileAppSelector)
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const receiverName = getCustomerName(conversation)

  const posterPhone =
    message.from && message.from.startsWith('+')
      ? checkFormatPhoneNumber(message.from)
      : conversation.kind === 'messenger'
        ? getText('WORD_SOCIAL_FB')
        : ''

  const posterName = getPosterName(
    message.type,
    message.whoSentThis_name,
    receiverName
  )

  const getDeliveryStatus = (status) => {
    if (status === 'queued' || status === 'accepted') {
      return <SVGSendMessageCheckedIcon color='#00CB85' />
    } else if (status === 'delivered' || status === SENT) {
      return <SVGSendMessageCheckedIcon color='#00CB85' />
    } else if (status === 'failed' || status === 'undelivered') {
      return <SVGSendMessageCheckedIcon />
    }
  }

  const getLeadType = (source) => {
    const displayLocationName = () => {
      if (
        source === 'matador_connect' ||
        source === 'text us' ||
        source === 'smart promotions' ||
        source === 'Coupon'
      ) {
        return `${message.location_name} -`
      }
    }

    if (source.length > 33) {
      return (
        <Tooltip title={source}>
          <span className='incoming_lead_type'>
            {displayLocationName()} {source}
          </span>
        </Tooltip>
      )
    } else {
      return (
        <span className='incoming_lead_type'>
          {displayLocationName()} {source}
        </span>
      )
    }
  }

  const messageBody = getMessageBody(message)

  return (
    <div
      key={index}
      className={`message  ${message.type === RECEIVED ? ' left ' : ' right '}`}
    >
      <motion.div
        className='message--inner-item'
        {...MESSAGE_BLOCK_ANIMATIONS}
        style={{
          transformOrigin:
            message.type === RECEIVED ? 'left bottom' : 'right bottom',
        }}
        layout
      >
        <div className='post_info'>
          {(message.type === 'bot' ||
            (message.type === 'consent' && message.isAIBot)) && (
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
                marginBottom: '4px',
              }}
            >
              {Boolean(
                (message.whoSentThis_name === AI_BOT ||
                  message.whoSentThis_name === SEQUENCE) &&
                  message.type === BOT
              ) ? (
                <SVGDrawerSequence color={MainColor} height={16} width={16} />
              ) : (
                <SVGBot color={MainColor} />
              )}
            </div>
          )}
          {message.broadcast_id && (
            <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
              <SVGBroadcastingIcon color={MainColor} />
            </div>
          )}
          {posterName !== posterPhone ? (
            <>
              {posterName && <span className='author'>{posterName}</span>}
              {message.type === RECEIVED ? null : (
                <span className='phone'>{posterPhone}</span>
              )}
            </>
          ) : (
            <span className='author'>{posterName}</span>
          )}
          {message.source_name && getLeadType(message.source_name)}
        </div>
        {message.media.length ? (
          <div className='message--inner-item--images'>
            <CenterRenderImage
              images={message.media}
              dataLength={message.media.length}
            />
          </div>
        ) : null}
        <div className='message--inner-item--source'>
          {getIconSource(message.source_type)}
          {message.body && (
            <div className='post'>
              <div className='text matador_live_chat_123789_lead_page'>
                {messageBody}
                {message.link && (
                  <>
                    <hr className='matador_live_chat_123789_hr' />
                    <MatButton
                      style={{ fontSize: '12px', width: '200px' }}
                      onClick={() => {
                        if (isMobileApp) {
                          myWebViewPost(message.link)
                        } else {
                          window.open(message.link, '_blank')
                        }
                      }}
                      buttonText={getText('TEXT_VIEW_THIS_PAGE')}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='date'>
          <div className='date_with_status'>
            {formatDateTime(message.createdAt)}{' '}
            {messageType.includes(message.type) &&
              getDeliveryStatus(message.smsStatus)}
          </div>
        </div>
        <div className='message--inner-item--buttons'>
          {lastNotSentMessage?._id === message._id && conversation.is_unread && (
            <MessageMarkAsReadButton
              conversation={conversation}
              messageType={messageListType}
            />
          )}
        </div>
      </motion.div>
      {!isTabletPortraitOrMobile && (
        <motion.div
          {...PROFILE_LETTERS_ANIMATION}
          layout
          style={{ transformOrigin: 'right bottom' }}
        >
          <ProfileLetters
            withoutIcon
            className='medium'
            name={posterName || posterPhone}
            customeName={
              posterName === getText('WORD_SEQUENCE')
                ? 'SQ'
                : message.type === AI_REPLY
                  ? 'A.I'
                  : false
            }
          />
        </motion.div>
      )}
    </div>
  )
}

export default memo(CenterOther)
