import React from 'react'
import { Flex } from 'antd'
import MyDefaultEllipsisText from '../../../../../components/MyDefaultEllipsisText/MyDefaultEllipsisText'
import SVGMarketingSourceIcon from '../../../../../icons/SVG/SVGMarketingSourceIcon'
import './DashboardMarketingTouchpoint.scss'

const convertedSourcesObj = {
  matador_connect: 'Matador Chat',
  'Mobile CTA:': 'Mobile CTA',
  'text us': 'Embedded CTA',
  'Subscriber Growth': 'Popup',
  Coupon: 'Mobile Smart CTA',
  'smart promotions': 'Desktop Smart CTA',
  inbound: 'Inbound SMS',
}

const DashboardMarketingTouchpoint = ({ source }) => {
  return (
    <Flex
      align='center'
      gap={6}
      className={`dashboard_marketing_touchpoint ${convertedSourcesObj[source] ? 'dark' : ''}  `}
    >
      {convertedSourcesObj[source] && <SVGMarketingSourceIcon />}
      <MyDefaultEllipsisText
        text={convertedSourcesObj[source] || source}
        hasTooltip
        className={'dashboard_marketing_touchpoint--inner'}
      />
    </Flex>
  )
}

export default DashboardMarketingTouchpoint
