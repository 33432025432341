import React from 'react'
import { Flex, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getText } from '../../lang'
import Div from '../Div/Div'
import MatSwitch from '../MatSwitch'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import MyDefaultInputNumber from '../MyDefaultInputNumber/MyDefaultInputNumber'
import './MyDefaultSwitch.scss'

const MyDefaultSwitch = ({
  title,
  disabled,
  value,
  isDefault,
  onSetAsDefaultClick,
  onChangeSwitch,
  className,
  noText,
  icon,
  leftIcon,
  style,
  tooltip,
  tooltipText,
  isLast,
  titleStyle,
  isFirst,
  hasAdditionalDetails,
  isForm,
  name,
  hidden,
  disableMargins = true,
  titleTooltip,
  isMobile,
  additionalNumberInputTitle,
  additionalNumberInput,
  textAfterSwitch,
  justify,
  onChangeInput,
  inputValue,
  inputMaxLength,
  noBorder,
  innerStyle,
  disablePadding,
}) => {
  return (
    <Flex
      align='center'
      justify={justify || 'space-between'}
      style={style}
      className={`my_default_switch ${className || ''} ${isLast ? 'last' : ''} ${isFirst ? 'first' : ''} ${hasAdditionalDetails ? 'hasAdditionalDetails' : ''} ${noBorder ? 'no_border' : ''} ${disablePadding ? 'disable_padding' : ''}`}
    >
      {(title || icon) && (
        <Div
          className={`my_default_switch--inner ${leftIcon ? 'left_icon' : ''}`}
          style={innerStyle}
        >
          {title && (
            <span className={'title'} style={titleStyle}>
              {title}
              {titleTooltip && (
                <Tooltip title={titleTooltip}>
                  <InfoCircleOutlined
                    style={{
                      marginLeft: 6,
                      marginTop: isMobile ? 0 : 5,
                    }}
                  />
                </Tooltip>
              )}
              {additionalNumberInput && (
                <React.Fragment>
                  <MyDefaultInputNumber
                    onChange={onChangeInput}
                    value={inputValue}
                    maxLength={inputMaxLength}
                  />
                  {additionalNumberInputTitle}
                </React.Fragment>
              )}
            </span>
          )}
          {icon && <span className={'icon'}>{icon}</span>}
        </Div>
      )}
      <Div className='my_default_switch--inner switch_left_side'>
        {!noText && (
          <>
            {!isDefault ? (
              <span className={'title'} onClick={onSetAsDefaultClick}>
                {getText('TEXT_SET_AS_DEFAULT')}
              </span>
            ) : (
              <small>{getText('WORD_DEFAULT')}</small>
            )}
          </>
        )}
        {tooltip ? (
          <Tooltip placement='topRight' title={tooltipText}>
            <Div />
            {isForm ? (
              <MyDefaultFormItem
                name={name}
                hidden={hidden}
                disableMargins={disableMargins}
              >
                <MatSwitch
                  disabled={disabled}
                  value={value}
                  onChange={onChangeSwitch}
                  switchText={textAfterSwitch}
                />
              </MyDefaultFormItem>
            ) : (
              <MatSwitch
                disabled={disabled}
                value={value}
                onChange={onChangeSwitch}
                switchText={textAfterSwitch}
              />
            )}
          </Tooltip>
        ) : isForm ? (
          <MyDefaultFormItem
            name={name}
            hidden={hidden}
            disableMargins={disableMargins}
          >
            <MatSwitch
              disabled={disabled}
              value={value}
              onChange={onChangeSwitch}
              switchText={textAfterSwitch}
            />
          </MyDefaultFormItem>
        ) : (
          <MatSwitch
            disabled={disabled}
            value={value}
            onChange={onChangeSwitch}
            switchText={textAfterSwitch}
          />
        )}
      </Div>
    </Flex>
  )
}

export default MyDefaultSwitch
