import React from 'react'
import './MyDefaultInsidePageTabs.scss'
import { Tabs } from 'antd'

const MyDefaultInsidePageTabs = ({ items, onChange, isFirst }) => {
  return (
    <Tabs
      items={items}
      onChange={onChange}
      className={`my_default_inside_page_tabs ${isFirst ? 'is_first' : ''}`}
    />
  )
}

export default MyDefaultInsidePageTabs
