import moment from 'moment'
import { RANGE_PARAMS } from '../../utils'
import { store } from '../index'
import { fetchFromUrlGETAsync, fetchFromUrlPOSTAsync } from '../../utils/UrlHelper'
import { TABLE_DEFAULT_ROW_HEIGHT } from '../../devOptions'

const urlStatDashboard = '/dashboard/statistics'
const urlStatDashboardReporting = '/dashboard/reporting'
const urlStatDashboardChart = '/dashboard/chart'
const urlStatLeaderboard = '/dashboard/location_leaders'
const urlUserStatDashboard = '/dashboard/user-stats'
const urlStatDashboardConv = '/dashboard/conversation-stats'
const urlStatDashboardQusetion = '/dashboard/questions-stats'
const urlStatGreetingChart = '/dashboard/greeting-chart'
const urlStatDashboardFingerprint = '/dashboard/fingerprint'
const urlStatDashboardSmartReply = '/dashboard/impact_statistics'
const urlVideoStatDashboard = '/dashboard/video-stats'
const urlOverviewStatDashboard = '/dashboard/overview'
const urlOverviewLeadsStatDashboard = '/dashboard/overview-messages'
const urlVoiceAIStatDashboard = '/dashboard/voice-ai-stats'
const urlVoiceAIListStatDashboard = '/voice-ai-calls'
const urlInfluencedStateDashboard = '/dashboard/report-request'
const urlMarketingStatDashboard = '/dashboard/marketing'
const urlReputationPulseStatDashboard = '/dashboard/reputation-pulse'

const fs = {
  getDashboardReporting: async (
    range,
    _location_id,
    org_loc_index,
    startEndDate = {}
  ) => {
    const urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (org_loc_index === 'loc') {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    if (Object.values(startEndDate).length > 0) {
      urlParams.push({ key: 'start_date', value: startEndDate.start_date })
      urlParams.push({ key: 'end_date', value: startEndDate.end_date })
    }

    if (RANGE_PARAMS.includes(range)) {
      urlParams.push({ key: 'range', value: range }) // all_time | last_month| mtd | today
    }

    const result = await fetchFromUrlGETAsync(urlStatDashboardReporting, urlParams)
    return result
  },

  getUserDashboard: async (
    range,
    page = 0,
    perPage = 5,
    _location_id = '',
    start_date,
    end_date,
    search,
    filter,
    sorting
  ) => {
    let urlParams = [
      { key: 'offset', value: new Date().getTimezoneOffset() },
      { key: 'page', value: page + 1 },
      { key: 'perPage', value: perPage },
    ]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range }) // all_time | last_month| mtd | today
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    if (search) {
      urlParams.push({ key: 'search', value: search })
    }

    if (filter) {
      urlParams.push({ key: filter, value: true })
    }

    if (Boolean(sorting) && sorting?.sort && sorting?.order) {
      urlParams.push({ key: 'sortOrder', value: sorting.order })
      urlParams.push({ key: 'sortColumn', value: sorting.sort })
    }

    const result = await fetchFromUrlGETAsync(urlUserStatDashboard, urlParams)
    return result
  },

  getDashboardChart1: async (range, type, org_loc_index, locId) => {
    // const user = store.getState().authReducer.user;
    // const loc = await authActions.getLocation();
    const urlParams = [
      { key: 'range', value: range },
      { key: 'offset', value: new Date().getTimezoneOffset() },
      { key: 'type', value: type }, // messages_received | messages_sent | appointment_set | invites_sent
    ]
    if (org_loc_index === 'loc') {
      urlParams.push({ key: '_location_id', value: locId })
    }

    const result = await fetchFromUrlGETAsync(urlStatDashboardChart, urlParams)
    return result
  },

  getDashboardStats: async () => {
    const user = store.getState().authReducer.user
    const urlParams = [
      { key: '_location_id', value: user._location_id },
      { key: 'offset', value: new Date().getTimezoneOffset() },
    ]
    const result = await fetchFromUrlGETAsync(urlStatDashboard, urlParams)
    return result
  },

  getLeaderboardStats: async (
    start,
    range,
    searchKeyword = '',
    paginationOptions = {}
  ) => {
    const user = store.getState().authReducer.user
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
      { key: '_location_id', value: user._location_id },
    ]

    // if (paginationOptions.month && paginationOptions.year) {
    if (paginationOptions.year > -1) {
      urlParams.push({ key: 'month', value: paginationOptions.month })
      urlParams.push({ key: 'year', value: paginationOptions.year })
    }

    const result = await fetchFromUrlGETAsync(urlStatLeaderboard, urlParams)

    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.leaders
    }
    return result
  },

  getGreetingStatsList: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    urlParams.push({ key: 'offset', value: new Date().getTimezoneOffset() })
    const result = await fetchFromUrlGETAsync(urlStatDashboardConv, urlParams)
    return result
  },

  getquestionsStatsList: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    urlParams.push({ key: 'offset', value: new Date().getTimezoneOffset() })
    const result = await fetchFromUrlGETAsync(urlStatDashboardQusetion, urlParams)
    return result
  },

  getGreetingChart: async (obj) => {
    const urlParams = []
    // const urlParams = [{ key: "offset", value: new Date().getTimezoneOffset() }];
    if (obj) {
      urlParams.push({ key: '_location_id', value: obj })
    }

    const result = await fetchFromUrlGETAsync(urlStatGreetingChart, urlParams)
    return result
  },

  // getTheBrainStats: async (start_date, end_date) => {
  //   const urlParams = [
  //     { key: 'start_date', value: start_date },
  //     { key: 'end_date', value: end_date },
  //   ]
  //   const result = await fetchFromUrlGETAsync(urlStatTheBrain, urlParams)
  //   return result
  // },

  getFingerprintCount: async (range, _location_id, startEndDate) => {
    const urlParams = [{ key: '_location_id', value: _location_id }]

    if (Object.values(startEndDate).length > 0) {
      urlParams.push({ key: 'range', value: 'all_time' })
    }

    if (RANGE_PARAMS.includes(range)) {
      urlParams.push({ key: 'range', value: range }) // all_time | last_month| mtd | today
    }

    const result = await fetchFromUrlGETAsync(urlStatDashboardFingerprint, urlParams)
    return result
  },

  getTheSmartReplyStats: async (
    start_date,
    end_date,
    _location_id,
    startEndDate = {}
  ) => {
    const urlParams = []
    if (_location_id) {
      urlParams.push({
        key: '_location_id',
        value: _location_id,
      })
    }
    if (Object.values(startEndDate).length > 0) {
      urlParams.push({
        key: 'start_date',
        value: moment(startEndDate.start_date).format('MM/DD/YYYY'),
      })
      urlParams.push({
        key: 'end_date',
        value: moment(startEndDate.end_date)
          .subtract(1, 'days')
          .format('MM/DD/YYYY'),
      })
    } else {
      urlParams.push({ key: 'start_date', value: start_date })
      urlParams.push({ key: 'end_date', value: end_date })
    }

    const result = await fetchFromUrlGETAsync(urlStatDashboardSmartReply, urlParams)
    return result
  },
  getVideoStatsDashboard: async (range, _location_id = '', start_date, end_date) => {
    let urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range }) // all_time | last_month| mtd | today
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    const result = await fetchFromUrlGETAsync(urlVideoStatDashboard, urlParams)
    return result
  },
  getOverviewStatsDashboard: async (
    range,
    _location_id = '',
    start_date,
    end_date
  ) => {
    let urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range })
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    const result = await fetchFromUrlGETAsync(urlOverviewStatDashboard, urlParams)
    return result
  },
  getOverviewLeadsStatsDashboard: async (
    range,
    _location_id = '',
    start_date,
    end_date,
    senderType
  ) => {
    let urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range })
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    if (senderType !== 'total_message_sent') {
      urlParams.push({ key: 'senderType', value: senderType })
    }

    const result = await fetchFromUrlGETAsync(
      urlOverviewLeadsStatDashboard,
      urlParams
    )
    return result
  },
  getVoiceAIStatsDashboard: async (
    range,
    _location_id = '',
    start_date,
    end_date
  ) => {
    let urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range })
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    const result = await fetchFromUrlGETAsync(urlVoiceAIStatDashboard, urlParams)
    return result
  },
  getVoiceAIListStatsDashboard: async (
    range,
    _location_id = '',
    start_date,
    end_date,
    page = 0,
    perPage = TABLE_DEFAULT_ROW_HEIGHT,
    _organization_id
  ) => {
    let urlParams = [
      { key: 'offset', value: new Date().getTimezoneOffset() },
      { key: 'page', value: page + 1 },
      { key: 'perPage', value: perPage },
      { key: '_organization_id', value: _organization_id },
    ]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range })
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    const result = await fetchFromUrlGETAsync(urlVoiceAIListStatDashboard, urlParams)
    return result
  },
  sendRequestReport: async () => {
    const result = await fetchFromUrlPOSTAsync(urlInfluencedStateDashboard, [])
    return result
  },
  getMarketingStatsDashboard: async (
    range,
    page = 0,
    perPage = 5,
    _location_id = '',
    start_date,
    end_date,
    sorting
  ) => {
    let urlParams = [
      { key: 'offset', value: new Date().getTimezoneOffset() },
      { key: 'page', value: page + 1 },
      { key: 'perPage', value: perPage },
    ]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range }) // all_time | last_month| mtd | today
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    if (Boolean(sorting) && sorting?.sort && sorting?.order) {
      urlParams.push({ key: 'sortOrder', value: sorting.order })
      urlParams.push({ key: 'sortColumn', value: sorting.sort })
    }

    const result = await fetchFromUrlGETAsync(urlMarketingStatDashboard, urlParams)
    return result
  },
  getReputationPulseStatsDashboard: async (
    range,
    _location_id = '',
    start_date,
    end_date
  ) => {
    let urlParams = [{ key: 'offset', value: new Date().getTimezoneOffset() }]

    if (range === 'custom') {
      urlParams.unshift(
        { key: 'start_date', value: start_date || Date.now() },
        { key: 'end_date', value: end_date || Date.now() }
      )
    } else {
      urlParams.unshift({ key: 'range', value: range })
    }

    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }

    const result = await fetchFromUrlGETAsync(
      urlReputationPulseStatDashboard,
      urlParams
    )
    return result
  },
}

const loc = {}

const statActions = Object.assign(fs, loc)

export default statActions
