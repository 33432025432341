import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { BUTTON, EMAIL, TABLE_DEFAULT_ROW_HEIGHT } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTableTitle from '../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableList from '../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import MyDefaultTableHeader from '../../../../components/MyDefaultTable/MyDefaultTableHeader/MyDefaultTableHeader'
import SVGMyDefaultEditIcon from '../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultTableText from '../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../store/modules/authActions'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'

export const TEMPLATE_TYPES = {
  SMS: 'sms',
  EMAIL: 'email',
}

const Templates = ({ templateType }) => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setPageTitle } = useLayout()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })
  const layoutRef = useRef(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    setPageTitle(
      getText(templateType === EMAIL ? 'WORD_EMAIL_TEMPLATES' : 'WORD_SMS_TEMPLATES')
    )
  }, [templateType])

  const fill = useCallback(
    async (templateTypeSend) => {
      if (!organization?._id) return

      setLoading(true)
      let result = await organizationActions.getPageListTemplets(
        page,
        TABLE_DEFAULT_ROW_HEIGHT,
        organization?._id,
        '',
        templateTypeSend || templateType
      )
      if (result.success) {
        const newData = page === 0 ? result.data : [...data.list, ...result.data]
        setData({
          list: newData,
          total: result.max,
        })
        setLoading(false)
      }
    },
    [organization, page]
  )

  useEffect(() => {
    fill()
  }, [fill])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (page === 0) {
      fill(templateType)
    } else {
      setPage(0)
    }
  }, [templateType])

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: BUTTON,
            title: getText(
              templateType === TEMPLATE_TYPES.EMAIL
                ? 'WORD_ADD_EMAIL_TEMPLATE'
                : 'WORD_ADD_SMS_TEMPLATE'
            ),
            onClick: () => {
              navigate(
                `/organization/settings/${organization?._id}/template/${templateType}/create`
              )
            },
          },
        ],
      }}
    >
      <MyDefaultTableHeader
        title={getText(
          templateType === EMAIL
            ? 'TEXT_TOTAL_EMAIL_TEMPLATES'
            : 'TEXT_TOTAL_SMS_TEMPLATES'
        )}
        disableRight
        totalCount={data?.total}
      />
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && data?.list?.length < data?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data?.total || 0}
        columns={[
          {
            title: getText('LABEL_TEMPLATE_NAME'),
            dataIndex: 'name',
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },
          {
            title: getText('WORD_CREATED_AT'),
            dataIndex: 'createdAt',
            render: (_, { createdAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(createdAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: getText('WORD_UPDATED_AT'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20}>
                  <SVGMyDefaultEditIcon
                    onClick={() => {
                      navigate(
                        `/organization/settings/${organization?._id}/template/${templateType}/edit`,
                        { state: item }
                      )
                    }}
                  />
                  <ConfirmPopup
                    title={getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE'
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      let result = await organizationActions.deleteTemplet(item._id)
                      if (result.success) {
                        notifySuccess(
                          getText('TEXT_TEMPLATE_WAS_DELETED_SUCCESSFULLY')
                        )

                        setData((ov) => {
                          const newData = ov.list.filter(
                            (temp) => item._id !== temp._id
                          )

                          return {
                            total: ov.total,
                            list: [...newData],
                          }
                        })
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default Templates
