import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_24083_303816)'>
      <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
      <g opacity='0.2' filter='url(#filter0_f_24083_303816)'>
        <circle cx='18' cy='17' r='13' fill='#5B66EA' />
      </g>
      <path
        d='M11.334 24.6667H24.6673'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 12.6666V24.6666H19.5V12.6666C19.5 11.9333 19.2 11.3333 18.3 11.3333H17.7C16.8 11.3333 16.5 11.9333 16.5 12.6666Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16.6666V24.6666H14.6667V16.6666C14.6667 15.9333 14.4 15.3333 13.6 15.3333H13.0667C12.2667 15.3333 12 15.9333 12 16.6666Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.334 20.0001V24.6667H24.0007V20.0001C24.0007 19.2667 23.734 18.6667 22.934 18.6667H22.4007C21.6007 18.6667 21.334 19.2667 21.334 20.0001Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='7.5'
      stroke='url(#paint0_linear_24083_303816)'
    />
    <defs>
      <filter
        id='filter0_f_24083_303816'
        x='-11'
        y='-12'
        width='58'
        height='58'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='BackgroundImageFix'
          result='shape'
        />
        <feGaussianBlur
          stdDeviation='8'
          result='effect1_foregroundBlur_24083_303816'
        />
      </filter>
      <linearGradient
        id='paint0_linear_24083_303816'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
      <clipPath id='clip0_24083_303816'>
        <rect width='36' height='36' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGDashMarketingPerformance = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashMarketingPerformance
