import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import appearanceAction from '../../../../../store/modules/appearanceAction'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { getText } from '../../../../../lang'
import SVGChatNoImage from '../../../../../icons/SVG/SVGChatNoImage'
import SVGWidgetPositionRight from '../../../../../icons/SVG/SVGWidgetPositionRight'
import SVGWidgetPositionLeft from '../../../../../icons/SVG/SVGWidgetPositionLeft'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import RadioFormButtonCircle from '../../../../../components/Form/RadioFormButton/RadioFormButtonCircle'
import MatColorPicker from '../../../../../components/MatColorPicker'
import MatForm from '../../../../../components/Form/MatForm'
import MatRadioButtonSecondary from '../../../../../components/MatRadioButton/MatRadioButtonSecondary'
import UploadFileForm from '../../../../../components/Form/UploadFileForm'
import ThemePreview from '../preview/ThemePreview'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import SVGChatEditImage from '../../../../../icons/SVG/SVGChatEditImage'
import SVGChatEditColorPicker from '../../../../../icons/SVG/SVGChatEditColorPicker'
import InputFormNumberSecondary from '../../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import SwtichFormSecondary from '../../../../../components/Form/SwitchForm/SwtichFormSecondary'
import SVGTextPositionTop from '../../../../../icons/SVG/SVGTextPositionTop'
import SVGTextPositionCenter from '../../../../../icons/SVG/SVGTextPositionCenter'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import './Appearance.scss'

const reducerWidget = (state, action) => ({ ...state, ...action })

const Appearance = () => {
  const { locationObj, settings, setSettings } = useLocationLayout()

  const organization = useSelector(organizationSelector)
  const { setFooterButtons, setLoading } = useLayout()

  const [widgetState, dispatch] = useReducer(reducerWidget, {
    color: settings.color,
    headerTextColor: settings.headerTextColor,
    marginBottom: settings.marginBottom,
    marginRight: settings.marginRight,
    height: settings.height,
    theme: settings.theme,
    position: settings.position,
    texts: settings.texts,
    image_url: settings.image_url,
    mobileHeight: settings.mobileHeight,
    mobileWidth: settings.mobileWidth,
    removeQuestionInput: settings.removeQuestionInput,
    textPosition: settings.textPosition,
    bold: settings.bold,
    secondaryColor: settings.secondaryColor,
  })
  const [form] = Form.useForm()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  const onFinish = async () => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)
    const payload = {
      theme: widgetState.theme,
      color: allValues.color,
      position: widgetState.position,
      marginBottom: allValues.marginBottom,
      marginRight: allValues.marginRight,
      mobileHeight: allValues.mobileHeight,
      mobileWidth: allValues.mobileWidth,
      removeQuestionInput: allValues.removeQuestionInput,
      textPosition: widgetState.textPosition,
      bold: allValues.bold,
      secondaryColor: allValues.secondaryColor,
    }
    if (allValues.image_url) {
      payload.image_url = allValues.image_url
    }
    if (widgetState.theme === 'default') {
      payload.height = allValues.height
    }
    if (widgetState.theme !== 'default') {
      payload.headerTextColor = allValues.headerTextColor
    }

    let result = await appearanceAction.save(payload, locationObj._id)

    if (result.success) {
      notifySuccess(getText('SUCCESS_APPEARANCE_SAVED'))
      setSettings(result.data.settings)
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  const radioOptions = [
    { label: getText('WORD_DEFAULT'), value: 'default' },
    { label: getText('WORD_LIGHT'), value: 'light' },
  ]

  const radioColorOptions = [
    { label: 'green', value: '#39AA5D' },
    { label: 'red', value: '#F16565' },
    { label: 'blue', value: '#658CF1' },
    { label: 'yellow', value: '#F1D265' },
    { label: 'purple', value: '#9A65F1' },
  ]

  const radioSecondaryColorOptions = [
    { label: 'white', value: '#ffffff' },
    { label: 'black', value: '#222222' },
  ]

  return (
    <MatadorConnectLayout className='livechat-appearance-wrapper'>
      <MatadorConnectListTitle title={getText('WORD_APPEARANCE')} />
      <div className='form-chatboxPreview-wrapper'>
        <MatForm
          form={form}
          onFinish={onFinish}
          initialValues={{
            image_url: settings.image_url || '',
            theme: settings.theme || 'default',
            position: settings.position || 'right',
            color: settings.color || '#421DFE',
            headerTextColor: settings.headerTextColor || '#D2D1C3',
            height: settings.height || 0,
            marginRight: settings.marginRight || 0,
            marginBottom: settings.marginBottom || 0,
            mobileHeight: settings.mobileHeight || '100%',
            mobileWidth: settings.mobileWidth || '100%',
            removeQuestionInput: settings.removeQuestionInput || false,
            textPosition: settings.textPosition || 'top',
            bold: settings.bold || false,
            secondaryColor: settings.secondaryColor || '#ffffff',
          }}
        >
          <div className='upload-image-wrapper'>
            <MatBlockWithLabel black label={getText('WORD_AVATAR')}>
              <UploadFileForm
                mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                beforeUploadProps
                errMsg={getText('ERROR_MESSAGE_ONLY_IMAGE_ALLOWED')}
                maxCount={1}
                onUpload={(file) => {
                  form.setFieldsValue({ image_url: file.url })
                  dispatch({ image_url: file.url })
                }}
                showUploadList={false}
              >
                {widgetState.image_url ? (
                  <img
                    src={widgetState.image_url}
                    className='uploaded-image'
                    alt='image_url'
                  />
                ) : (
                  <div className='uploaded-image'>
                    <SVGChatNoImage />
                  </div>
                )}
                <div className='upload-image-edit'>
                  <SVGChatEditImage />
                </div>
              </UploadFileForm>
            </MatBlockWithLabel>
            <div className='image-info'>
              {widgetState.image_url && (
                <button
                  onClick={() => dispatch({ image_url: '' })}
                  className='remove-image-button'
                >
                  {getText('ACTION_REMOVE_IMAGE')}
                </button>
              )}
              <h6>{getText('TEXT_PROFILE_PICTURE_FOR_CLIENTS')}</h6>
            </div>
          </div>
          <div className='radio-appearance-wrapper'>
            <MatRadioButtonSecondary
              radioOptions={radioOptions}
              name='theme'
              value={widgetState.theme}
              onChange={(e) => dispatch({ theme: e.target.value })}
            />
          </div>
          <MatBlockWithLabel className='color-appearance-wrapper'>
            <RadioFormButtonCircle
              black
              label={getText('CONNECT_BACKGROUND_COLOR')}
              radioOptions={radioColorOptions}
              size='medium'
              name='color'
              onChange={(e) => {
                dispatch({ color: e.target.value })
                form.setFieldsValue({
                  color: e.target.value,
                })
              }}
              colorClassName
            />
            <div className='color-picker-item-wrapper'>
              <MatColorPicker
                trigger={
                  <div className='appearance-edit-color-wrapper'>
                    {getText('TEXT_OPEN_EDITOR')}
                    <div className='conic-gradient'>
                      <SVGChatEditColorPicker />
                    </div>
                  </div>
                }
                color={widgetState.color}
                className='color-pick-item'
                onChangeColor={(newColor) => {
                  dispatch({ color: newColor })
                  form.setFieldsValue({
                    color: newColor,
                  })
                }}
              />
              {widgetState.theme === 'light' && (
                <MatColorPicker
                  trigger={
                    <div className='appearance-edit-color-wrapper'>
                      {getText('TEXT_OPEN_EDITOR_LIGHT')}
                      <div className='conic-gradient'>
                        <SVGChatEditColorPicker />
                      </div>
                    </div>
                  }
                  color={widgetState.headerTextColor}
                  className='color-pick-item'
                  onChangeColor={(newColor) => {
                    dispatch({ headerTextColor: newColor })
                    form.setFieldsValue({
                      headerTextColor: newColor,
                    })
                  }}
                />
              )}
            </div>
          </MatBlockWithLabel>

          <MatBlockWithLabel className='color-appearance-wrapper'>
            <RadioFormButtonCircle
              black
              label={getText('FOREGROUND_COLOR')}
              radioOptions={radioSecondaryColorOptions}
              size='medium'
              name='secondaryColor'
              onChange={(e) => {
                dispatch({ color: e.target.value })
                form.setFieldsValue({
                  secondaryColor: e.target.value,
                })
              }}
              colorClassName
            />
            <div className='color-picker-item-wrapper'>
              <MatColorPicker
                trigger={
                  <div className='appearance-edit-color-wrapper'>
                    {getText('TEXT_OPEN_EDITOR')}
                    <div className='conic-gradient'>
                      <SVGChatEditColorPicker />
                    </div>
                  </div>
                }
                color={widgetState.secondaryColor}
                className='color-pick-item'
                onChangeColor={(newColor) => {
                  dispatch({ secondaryColor: newColor })
                  form.setFieldsValue({
                    secondaryColor: newColor,
                  })
                }}
              />
            </div>
          </MatBlockWithLabel>

          <div className='inputs-appearance-wrapper'>
            <InputFormNumberSecondary
              label={getText('WORD_BUTTON_MARGIN')}
              name='marginBottom'
              formClassName='input-item'
              onChange={(value) => {
                dispatch({ marginBottom: value })
              }}
            />
            <InputFormNumberSecondary
              label={getText('WORD_MARGIN_RIGHT')}
              name='marginRight'
              formClassName='input-item'
              onChange={(value) => dispatch({ marginRight: value })}
            />
            {widgetState.theme === 'default' && (
              <InputFormNumberSecondary
                label={getText('LABEL_HEIGHT')}
                name='height'
                formClassName='input-item'
                onChange={(value) => dispatch({ height: value })}
              />
            )}
          </div>
          {widgetState.theme === 'default' && (
            <MatBlockWithLabel black label={getText('MOBILE_DIMENSIONS')}>
              <div className='inputs-appearance-wrapper'>
                <InputFormNumberSecondary
                  label={`${getText('LABEL_HEIGHT')} %`}
                  name='mobileHeight'
                  formClassName='input-item'
                  onChange={(value) => dispatch({ mobileHeight: value })}
                  max={100}
                />
                <InputFormNumberSecondary
                  label={`${getText('LABEL_WIDTH')} %`}
                  name='mobileWidth'
                  formClassName='input-item'
                  onChange={(value) => dispatch({ mobileWidth: value })}
                  max={100}
                />
              </div>
            </MatBlockWithLabel>
          )}

          <MatBlockWithLabel black label={getText('WORD_WIDGET_POSITION')}>
            <div className='widget-position-wrapper mb-4'>
              <div
                className={`widget-position ${
                  widgetState.position === 'right' ? ' active' : ''
                } `}
                onClick={() => dispatch({ position: 'right' })}
              >
                <SVGWidgetPositionRight />
              </div>
              <div
                className={`widget-position ${
                  widgetState.position === 'left' ? ' active' : ''
                }`}
                onClick={() => dispatch({ position: 'left' })}
              >
                <SVGWidgetPositionLeft />
              </div>
            </div>
          </MatBlockWithLabel>

          <MatBlockWithLabel black label={getText('POSITION_TEXT')}>
            <div className='widget-position-wrapper'>
              <div
                className={`widget-position ${
                  widgetState.textPosition === 'top' ? ' active2' : ''
                } `}
                onClick={() => dispatch({ textPosition: 'top' })}
              >
                <SVGTextPositionTop />
              </div>
              <div
                className={`widget-position ${
                  widgetState.textPosition === 'middle' ? ' active2' : ''
                }`}
                onClick={() => dispatch({ textPosition: 'middle' })}
              >
                <SVGTextPositionCenter />
              </div>
            </div>
          </MatBlockWithLabel>

          <div className='mt-4'>
            <SwtichFormSecondary
              name='removeQuestionInput'
              text={getText('DISABLE_INPUT')}
            />
          </div>

          <div className='mt-4'>
            <SwtichFormSecondary name='bold' text={getText('ENABLE_BOLD_TEXT')} />
          </div>
        </MatForm>
        <div className='matador-connect-settings-chat-wrapper'>
          <ThemePreview
            settings={{ ...widgetState, _location_id: settings._token }}
            language={organization?.languages?.[0] || 'en'}
            locationId={settings._token}
            theme={widgetState.theme}
          />
        </div>
      </div>
    </MatadorConnectLayout>
  )
}

export default Appearance
