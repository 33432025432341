import React from 'react'
import { Avatar, Card, Flex, Slider, Tooltip } from 'antd'
import Div from '../../../../components/Div/Div'
import { getText } from '../../../../lang'
import SVGDashReviewInvitesCompletedBySourceIcon from '../../../../icons/SVG/SVGDashReviewInvitesCompletedBySourceIcon'
import SVGDashReviewInvitesCompletedBySourceGoogleIcon from '../../../../icons/SVG/SVGDashReviewInvitesCompletedBySourceGoogleIcon'
import SVGDashReviewInvitesCompletedBySourceFacebookIcon from '../../../../icons/SVG/SVGDashReviewInvitesCompletedBySourceFacebookIcon'
import utilNumber from '../../../../utils/utilNumber'
import './ReputationPulseReviewInvitesSource.scss'

const showSourceIcon = (source) => {
  switch (source) {
    case 'Google':
      return {
        icon: (
          <Tooltip title={source} className='source_tooltip' destroyTooltipOnHide>
            <Div />
            <SVGDashReviewInvitesCompletedBySourceGoogleIcon />
          </Tooltip>
        ),
        className: 'slider_blue_green_gradient',
      }
    case 'Facebook':
      return {
        icon: (
          <Tooltip title={source} className='source_tooltip' destroyTooltipOnHide>
            <Div />
            <SVGDashReviewInvitesCompletedBySourceFacebookIcon />
          </Tooltip>
        ),
        className: 'slider_purple_blue_gradient',
      }
    default:
      return {
        icon: (
          <Tooltip title={source} destroyTooltipOnHide>
            <Avatar
              style={{
                minWidth: 35,
                borderRadius: 8,
                fontSize: 20,
                background: 'rgba(255, 255, 255, 0.05)',
              }}
              shape='square'
              size={35}
            >
              {source[0]}
            </Avatar>
          </Tooltip>
        ),
        className: 'slider_yellow_green_gradient',
      }
  }
}

const ReputationPulseReviewInvitesSource = ({
  voted_count_by_source,
  total_voted_count_by_sources,
}) => {
  return (
    <Card className='dashboard_block dashboard_block_review_invites_source'>
      <Div className={`dashboard_block--blurred-part yellow`}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashReviewInvitesCompletedBySourceIcon />
        <Div className='dashboard_block--header--right'>
          <span>{getText('WORD_REVIEW_INVITES_COMPLETED_BY_SOURCE')}</span>
          <span>{utilNumber.numberFractionsKM(total_voted_count_by_sources)}</span>
        </Div>
      </Flex>
      <Flex gap={20} vertical style={{ height: '100%' }}>
        {voted_count_by_source?.map((item, index) => {
          const { icon, className } = showSourceIcon(item.source)
          if (!item.total_count) return null
          return (
            <Flex
              key={index}
              gap={12}
              align='center'
              className='dashboard_block--review_source'
            >
              {icon}
              <Tooltip
                className='slider_with_tooltip'
                title={item.voted_count_percentage + '%'}
                destroyTooltipOnHide
              >
                <Div />
                <Slider
                  className={className}
                  defaultValue={item.voted_count_percentage}
                  disabled={true}
                  tooltip={{
                    open: false,
                  }}
                />
              </Tooltip>
              <Flex align='center'>
                <Div className='dashboard_block--review_source-text'>
                  {utilNumber.numberFractionsKM(item.total_count)}
                </Div>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Card>
  )
}

export default ReputationPulseReviewInvitesSource
