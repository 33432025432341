import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Form } from 'antd'
import { getText } from '../../../../../../lang'
import MatForm from '../../../../../../components/Form/MatForm'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import { notifyError } from '../../../../../../utils/Notify'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import FormButtons from '../FormButtons/FormButtons'
import userActions from '../../../../../../store/modules/userActions'
import {
  hasManyOrganizationsSelector,
  userSelector,
} from '../../../../../../store/selectors/selectors'
import MyDefaultInputText from '../../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultSelect from '../../../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultBlockTitle from '../../../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultSwitch from '../../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import { isAustralianOrg } from '../../../../../../utils'

const Campaign = React.memo((props) => {
  const {
    onStateChange,
    consentValue,
    getLocationList,
    handleLoadMoreLocations,
    onChangeStep,
    name,
    _location_id,
    skipStopMessage,
    uploadExcel,
  } = props

  const user = useSelector(userSelector)
  const organization = user?.organization
  const location = user?.location
  const hasManyOrganizations = useSelector(hasManyOrganizationsSelector)

  const [form] = Form.useForm()
  const [initialState, setInitialState] = useState()

  useEffect(() => {
    let stateObj = { name: name || '' }
    if (!!_location_id) {
      stateObj._location_id = _location_id
    }
    setInitialState(stateObj)
  }, [])

  const onFinish = () => onChangeStep(1)

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <BroadcastCampaignPage title={getText('TEXT_START_YOUR_CAMPAIGN')}>
      {!initialState ? (
        <LoadingSpinner />
      ) : (
        <div className='create-campaign-form-wrapper ant-form-vertical'>
          <MatForm
            form={form}
            name='formSaveCustomerComparing'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialState}
            validateTrigger={['onChange']}
          >
            <MyDefaultInputText
              isForm
              name={'name'}
              required
              maxLength={100}
              label={getText('TEXT_NAME_OF_BROADCAST')}
              onChange={(e) => {
                onStateChange('name', e.target.value)
              }}
              allowClear={() => {
                onStateChange('name', '')
              }}
            />
            <MyDefaultSelect
              isForm
              name={'_location_id'}
              label={getText('TEXT_LOCATION_DO_THESE_USERS_BELONG_TO')}
              options={
                userActions.getUserHasPermission(
                  user,
                  organization?.restrictions?.allowAccessToAllLocations
                ) || hasManyOrganizations
                  ? getLocationList.locations
                  : [{ label: location.name, value: location?._id }]
              }
              onChange={(items) => {
                onStateChange('_location_id', items)
              }}
              placeholder={getText('WORD_SELECT_ITEM')}
              onLoadMore={handleLoadMoreLocations}
              message={getText(
                'ERROR_PLEASE_SELECT_LOCATION_IN_THE_FIELD_WHICH_LOCATION'
              )}
              getPopupContainer={() => document.querySelector('.steps-wrapper')}
              required
            />
            <MyDefaultBlockTitle title={getText('WORD_CONSENT')} />
            <Divider style={{ margin: '-4px 0 12px 0' }} />
            <MyDefaultSwitch
              noText
              isFirst
              isLast={!isAustralianOrg() || !uploadExcel}
              value={consentValue}
              onChangeSwitch={(item) => onStateChange('send_consent', item)}
              title={getText('TEXT_CONSENT_DESCRIPTION')}
              titleStyle={{ fontSize: 14 }}
            />
            {isAustralianOrg() && uploadExcel && (
              <MyDefaultSwitch
                noText
                isLast
                disabled={!consentValue}
                titleStyle={{ fontSize: 14 }}
                value={skipStopMessage}
                onChangeSwitch={(item) => onStateChange('skipStopMessage', item)}
                title={getText('TEXT_INCLUDE_OPT_OUT_LANGUAGE_IN_MARKETING_MESSAGE')}
              />
            )}
          </MatForm>
          <FormButtons
            prevAction={onChangeStep}
            currentFormName='formSaveCustomerComparing'
            submitFormBtn={getText('WORD_NEXT')}
            cancelFormBtn={getText('WORD_PREVIOUS')}
          />
        </div>
      )}
    </BroadcastCampaignPage>
  )
})

export default Campaign
